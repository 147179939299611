import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { Badge, Pagination } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from '../Loding/Sppinner';
import noImage from '../../../images/avatar/No_Image_Available.jpg';
///Images


const GuestDetail = () => {
	const [data, setData] = useState(
		document.querySelectorAll("#history_wrapper tbody tr")
	);
	const [assets, setAssets] = useState([])
	const [itemsPerPage, setItemsPerPage] = useState(5)
	const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0)
	const [currentPage, setCurrentPage] = useState(1);
	const [isLoading, setIsLoading] = useState(true)
	const [IsConfirm,setIsConfirm]=useState(false)
	const auth = useSelector(state => state.auth)
	const { id } = useParams();
	const [category, setCategory] = useState({})
	const history = useHistory();

	const getSingleCategoryDetail = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/category/single/${id}`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			console.log(res.data);
			setCategory(res.data.category)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	// const handleDelete = async () => {
	// 	axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/category/delete/${id}`, {
	// 		headers: {
	// 			Authorization: `Bearer ${auth.auth.token}`,
	// 		}

	// 	}).then((res) => {
	// 		showToastMessage(`${res?.data?.message}`)
	// 		history.push("/category-list")
	// 	}).catch((err) => {
	// 		swal(`${err?.response?.data?.message}`)
	// 		if(err?.response?.status===401){
	// 			history.push("/login")
	// 			window.location.reload();
	// 		}
	// 	})

	// }
	const handleDeleteConfirm = async () => {
		console.log(IsConfirm,"conn");
		console.log("called");
		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/category/delete/${id}`,{isConfirm:true}, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
			}
		}).then((res) => {
			// console.log(auth.auth.token);
			console.log(res?.data,"confir");
			if(res?.data?.isConfirm===true){
				swal(`${res?.data?.message}`)
				history.push("/category-list")
			}else if(res?.data?.isConfirm===false){
				swal({
					title: `Are you sure?`,
					text: `${res?.data?.message} You won't be able to revert this!`,
					icon: "warning",
					buttons: ["Cancel", "Confirm"],
					dangerMode: true,
				  })
				  .then((result) => {
					console.log(result);
					if(result===null){
						setIsConfirm(false)
					//   getAllCategories()
					}
					else if (result===true) {
						console.log("gererere");
						setIsConfirm(true)
					   handleDeleteConfirm(id)
					  
					} 
				  });
			}
			
			
		}).catch((err) => {
			console.log(err?.response?.data);
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})

	}
	const handleDelete = async () => {
		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/category/delete/${id}`,{isConfirm:IsConfirm}, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
			}
		}).then((res) => {
			if(res?.data?.isConfirm===true){
				swal(`${res?.data?.message}`)
				history.push("/category-list")
			}else if(res?.data?.isConfirm===false){
				swal({
					title: `Are you sure?`,
					text: `${res?.data?.message} You won't be able to revert this!`,
					icon: "warning",
					buttons: ["Cancel", "Confirm"],
					dangerMode: true,
				  })
				  .then((result) => {
					console.log(result);
					if(result===null){
						setIsConfirm(false)
					//   getAllCategories()
					}
					else if (result===true) {
						console.log("gererere");
						setIsConfirm(true)
					   handleDeleteConfirm(id)
					  
					} 
				
				  });
			}
			
			
		}).catch((err) => {
			console.log("here");
			console.log(err?.response?.data);
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})

	}

	const getAllAssets = async () => {

		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/all/specific/${id}`, { headers: { Authorization: `Bearer ${auth.auth.token}` }, params: { page: currentPage } }).then((res) => {
			setAssets(res?.data?.assets)
			setTotalNumberOfRecords(res?.data?.totalCount)
			setIsLoading(false)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	function dateFormat(date) {
		const month = date.getMonth();
		const day = date.getDate();
		const monthString = month >= 10 ? month : `0${month}`;
		const dayString = day >= 10 ? day : `0${day}`;
		return `${date.getFullYear()}-${monthString}-${dayString}`;
	}
	useEffect(() => {
		getSingleCategoryDetail()
		getAllAssets()
	}, [])

	useEffect(() => {
		getAllAssets()
	}, [currentPage, setCurrentPage])
	const showToastMessage = (msg) => {
		toast.success(`${msg}`, {
			position: toast.POSITION.BOTTOM_LEFT,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			closeButton: <i className="fa fa-times" aria-hidden="true"></i>,
			style: {
				background: "white",
				borderRadius: "5px",
				borderLeft: "10px solid green",
				color: "black",
				boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
			},
		});
	};
	const checkMoveable = (page) => {

		const max = (Math.ceil(totalNumberOfRecords / 5))
		if ((page + 1) <= max) {
			return true
		} else {
			return false
		}

	}
	const checkBackward = (page) => {
		console.log(page);
		if (page > 1) {
			return true
		} else {
			return false
		}
	}
	let paggination = Array(Math.ceil(totalNumberOfRecords / itemsPerPage))
		.fill()
		.map((_, i) => i + 1);
	const lowerBound = (currentPage - 1) * itemsPerPage + 1;
	const upperBound = Math.min(currentPage * itemsPerPage, totalNumberOfRecords);

	return (
		<>
			<ToastContainer
				autoClose={3000}
			/>
			{isLoading ? (<Spinner />) : (<div className="row">
				{/* <div className="col-xl-8 col-xxl-8">
					<div className="card">	
						<div className="card-header border-0 pb-0">
							<h4 className="card-title">Currently Checked Out Asset</h4>
						</div>
						<div className="card-body">
							<BookingSlider />
							<div className="d-flex mt-4 flex-wrap">
								<h4 className="card-title me-auto">Room Facilities</h4>
								<h5 className="card-title">AC, Shower, Double Bed, Towel, Bathup, Coffee Set, LED TV, Wifi</h5>
							</div>
						</div>
						<div className="card-body d-flex pt-0 align-items-center flex-wrap">
							<div className="d-flex align-items-center me-5 pe-4 mb-xxl-0 mb-2">
								<span className="key-icon me-3">
									<svg width="32" height="16" viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M16.1585 6.41671C16.8932 2.80354 20.0899 0.0833735 23.9168 0.0833737C28.2868 0.0833739 31.8335 3.63004 31.8335 8.00004C31.8335 12.3685 28.2868 15.9167 23.9168 15.9167C20.0899 15.9167 16.8932 13.195 16.1585 9.58337L9.66683 9.58337L9.66683 12.75C9.66683 13.6225 8.9575 14.3334 8.0835 14.3334C7.2095 14.3334 6.50016 13.6225 6.50016 12.75L6.50016 9.58337L3.3335 9.58337L3.3335 12.75C3.3335 13.6225 2.62416 14.3334 1.75016 14.3334C0.876161 14.3334 0.166828 13.6225 0.166828 12.75L0.166828 8.00004C0.166828 7.12446 0.876162 6.41671 1.75016 6.41671L16.1585 6.41671ZM28.6668 8.00004C28.6668 10.6205 26.5388 12.75 23.9168 12.75C21.2948 12.75 19.1668 10.6205 19.1668 8.00004C19.1668 5.37804 21.2948 3.25004 23.9168 3.25004C26.5388 3.25004 28.6668 5.37804 28.6668 8.00004Z" fill="white"/>
									</svg>
								</span>
								<div>
									<h5 className="text-primary">Booking ID #0052466623</h5>
									<h4 className="card-title mb-0">King Deluxe B-23</h4>
								</div>
							</div>
							<div className="d-sm-flex d-block align-items-center">
								<div className="me-5 mb-sm-0 mb-3">
									<p className="mb-2"><i className="far fa-user scale3 me-3"></i>Room Capacity</p>
									<h4 className="mb-0 card-title">3-5 Person</h4>
								</div>
								<div className="me-5 mb-sm-0 mb-3">
									<p className="mb-2"><i className="fas fa-bed scale3 me-3"></i>Bed Type</p>
									<h4 className="mb-0 card-title">Double</h4>
								</div>
								<div>
									<p className="mb-2"><i className="far fa-calendar-minus scale3 me-3"></i>Booking Date</p>
									<h4 className="mb-0 card-title">Oct 25th - 28th, 2020</h4>
								</div>
							</div>
						</div>
					</div>
				</div> */}
				<div className="col-xl-9 col-xxl-9">
					<div className="card profile-card">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className='col-8' style={{ display: "flex" }}>
									<div className='col-3'>
										<img src={(category?.image)?`${process.env.REACT_APP_BASE_URL}/${category?.image}`:noImage} alt="" className="rounded profile-img me-4 mb-3" />
									</div>
									<div className='col-8' style={{ display: "flex", flexDirection: "column" }}>
										<h4 className="mb-0" style={{ fontSize: "28px" }}>{`${category?.categoryName}`}</h4>
										<span style={{ marginTop: "8px" }}>{`+92${category?.description}`}</span>
									</div>
								</div>
								{console.log(category?.description?.length)}
								<div className='col-4'>
									<div className="row mt-4 pt-3">
										<div className="col-6">
											<Link to={`/category-edit/${id}`} className="btn btn-dark light btn-block">Edit</Link>
										</div>
										<div className="col-4">
											<Link to={"#"} className="btn btn-danger btn-block" onClick={handleDelete}><i class="bi bi-trash"></i></Link>
										</div>
									</div>
								</div>

							</div>

							{/* <ul className="user-info-list">
								<li>
									<i className="far fa-envelope mt-5"></i>
									<span className='mt-5'>{`+92${category?.description}`}</span>
								</li>
							</ul> */}
						</div>
					</div>
				</div>
				<div className="col-xl-12">
					<div className="d-flex align-items-center rooms flex-wrap">
						<h4 className="me-auto mb-3">Category Assets</h4>

					</div>
					<div className="table-responsive">
						<div id="history_wrapper" className="dataTables_wrapper no-footer">
							<table
								id="example2"
								className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer"
							>
								<thead>
									<tr role="row">
										{/* <th className="sorting_asc bg-none" >
														<div className="form-check  style-1">
															<input type="checkbox" onClick={() => chackboxFun("all")} className="form-check-input" id="checkAll" required="" />
														</div>
													</th> */}
										<th className="sorting" style={{ textAlign: "center" }}>Asset</th>
										<th className="sorting" style={{ textAlign: "center" }}>Category</th>
										<th className="sorting" style={{ textAlign: "center" }}>Make</th>
										<th className="sorting" style={{ textAlign: "center" }}>Model</th>
										<th className="sorting" style={{ textAlign: "center" }}>Location</th>

										<th className="sorting" style={{ textAlign: "center" }}>Status</th>


									</tr>
								</thead>
								<tbody>
									{assets.map((asset) => {
										return (
											<>
												<tr role="row" className="odd" key={asset._id}>
													{/* <td className="sorting_1">
																	<div className="form-check  style-1">
																		<input type="checkbox" onClick={() => chackboxFun()} className="form-check-input"
																			id="customCheckBox2" required=""
																		/>
																	</div>
																</td> */}
													<td>
														<div className="media-bx" >
															<Link to={`/asset-detail/${asset._id}`} className="text-black" style={{ display: "flex" }}>
																<img className="me-3 rounded" src={`${process.env.REACT_APP_BASE_URL}/${asset?.images[0]?.url}`} alt="" />
																<div>

																	<h4 className="mb-0 mt-1">
																		{`${asset?.assetName}`}
																	</h4>
																	<span className="text-primary">{asset.assetCode}</span>
																</div>
															</Link>
														</div>
													</td>
													<td>
														<Link to={`/category-detail/${asset?.category?._id}`}>
															<div style={{ textAlign: "center" }}>
																<h5>{asset?.category?.categoryName}</h5>
															</div>
														</Link>
													</td>
													<td>
														<div style={{ textAlign: "center" }}>
															<h5>{asset?.make}</h5>

														</div>
													</td>
													<td>
														<div style={{ textAlign: "center" }}>
															<h5>{asset?.model}</h5>

														</div>
													</td>
													<td>
														<div style={{ textAlign: "center" }}>
															<h5>{asset?.location}</h5>

														</div>
													</td>

													<td style={{ textAlign: "center" }}>
														<Badge variant={`${asset?.status === "checked-out" ? "danger" : ((asset?.status === "available") ? "success" : "warning")}`}>{asset?.status}</Badge>
													</td>
													{/* <td>

														<div style={{ textAlign: "center" }}>
															<h5>{`${dateFormat(new Date(asset?.dateOfPurchase))}`}</h5>
															<span className="fs-14">{`${new Date(asset?.dateOfPurchase).toLocaleTimeString()}`}</span>



														</div>
													</td> */}

												</tr>
											</>
										)
									})}


								</tbody>
							</table>
							<div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
								<div className="dataTables_info">
									Showing {lowerBound} to{" "}
									{upperBound} {" "}
									of {totalNumberOfRecords} entries
								</div>
								<div
									className="dataTables_paginate paging_simple_numbers mb-0"
									id="example2_paginate"
								>
									<Link
										className={`paginate_button previous ${(currentPage === 1) && "disabled"}`}
										onClick={() => {
											if (checkBackward(currentPage)) {
												setCurrentPage((pre) => pre - 1)
											}
										}}

									>
										<i className="fa fa-angle-double-left"></i> Previous
									</Link>
									<span>

										{paggination.map((number, i) => (

											<Link
												key={i}

												className={`paginate_button  ${currentPage === i + 1 && "current"} `}

											>
												{number}
											</Link>
										))}
									</span>
									<Link
										className={`paginate_button next ${(currentPage === ((Math.ceil(totalNumberOfRecords / 5)))) && "disabled"}`}

										onClick={() => {
											if (checkMoveable(currentPage)) {
												setCurrentPage((pre) => pre + 1)
											}
										}
										}
									>
										Next <i className="fa fa-angle-double-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>)}
		</>
	)
}
export default GuestDetail;