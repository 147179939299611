import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';

import { useForm } from "react-hook-form";
import swal from "sweetalert";
import axios from "axios";
import Spinner from "../components/Loding/Sppinner";
import BgSpinner from "../components/BackgroundSpinner/BgSpinner";
// import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
// image
//import logo from "../../images/logo-full.png";

function Register(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
	const auth = useSelector(state => state.auth)
  const history = useHistory()
  const [email, setEmail] = useState('');
  const [file, setFile] = useState("");
  const [companyTypes,setCompanyTypes]=useState([])
  const [isLoading, setIsLoading] = useState(false)
  let errorsObj = { email: '', password: '' };
  const [showPassword, setShowPassword] = useState(false);
  //const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const togglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };
	const getAllCompanyTypes = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/companyType/all`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			
			setCompanyTypes(res?.data?.types)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
useEffect(()=>{
  getAllCompanyTypes()
},[])
  const onSubmit = (data) => {
    let formData = new FormData()
    setIsLoading(true)
    if(file){
      formData.append("image", file);
    }

  
    formData.append("companyName", data.companyName);
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("email", data.email);
    formData.append("companyType", data.companyType);
    formData.append("businessAddress", data.businessAddress);
    formData.append("number", data.number);
    formData.append("password", data.password);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("zip", data.zip);

   


    axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/company/register`, formData).then((res) => {
      
      setIsLoading(false)
      history.push(`/verify-otp/${res?.data?.company?._id}`)
    }).catch((err) => {
      setIsLoading(false)
      swal(`${err?.response?.data?.message}`)
    })

  };
  return (
    <>

      <div className="authincation  p-meddle" >
        <div className="container h-100">
          <div className="row justify-content-center  align-items-center" style={{ marginTop: "390px" }}>
            <div className="col-md-6" style={{ width: "80%" }}>
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-3">
                        {/* <Link to="/login">
							<img src={logo} alt="" />
						</Link> */}
                      </div >
                      <h3 className=" mb-4 text-black">Signup</h3>
                      {props.errorMessage && (
                        <div className=''>
                          {props.errorMessage}
                        </div>
                      )}
                      {props.successMessage && (
                        <div className=''>
                          {props.successMessage}
                        </div>
                      )}
                      <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data" >

                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                          <div className="form-group mb-0" style={{ borderRadius: "50%", width: "140px", position: "relative", display: "flex", height: "140px", justifyContent: "center", alignItems: "center" }}>
                            {file ? (
                              <img
                                src={URL.createObjectURL(file)}
                                alt=""
                                className="profile-img"
                                style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "50%" }}
                              />
                            ) : (
                              <img
                                src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                                alt=""
                                className="profile-img"
                                style={{ width: "100%", borderRadius: "50%" }}
                              />
                            )}
                          </div>
                          {/* <div className="form-group mb-3" style={{ width: "45%" }}>
                            <label className="mb-1 ">
                              <strong>Profile Image</strong>
                            </label>
                            <input
                              type="file"
                              id="file"

                              className="form-control"
                              {...register("image", { required: true })}
                              onChange={(e) => setFile(e.target.files[0])}
                            />
                            {errors.image?.type === "required" && <div style={{ color: "red", marginTop: "3px" }}>This field is required</div>}
                          </div> */}
                          <div className="form-group mb-3" style={{ width: "45%", position: "relative" }}>

                            <div style={{ position: "absolute", top: "-45px", left: "110px" }}>
                              <input
                                type="file"
                                id="file"
                                className="form-control d-none"
                                {...register("image")}
                                onChange={(e) => setFile(e.target.files[0])}
                              />
                              <label htmlFor="file" style={{ cursor: "pointer" }}
                              >
                                {/* <BsCloudUpload /> */}
                                <i class="bi bi-plus-circle-fill text-primary" style={{ fontSize: "25px" }}></i>

                              </label>
                            </div>
                            {/* {errors.image?.type === "required" && <div style={{ color: "red", marginTop: "3px" }}>This field is required</div>} */}
                          </div>

                          {/* <div className="form-group mb-3" style={{ width: "45%" }}>
                          <label className="mb-1 ">
                            <strong>Company Name</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("companyName", { required: true })}
                          />
                          {errors.companyName?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                        </div> */}

                        </div>
                        <h4 className="breadcrumb-item active text-primary" style={{ margin: "28px 0px" }}>General Info</h4>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className="form-group mb-3" style={{ width: "47%" }}>
                            <label className="mb-1 ">
                              <strong>First Name</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"

                              placeholder="John"
                              {...register("firstName", { required: true })}
                            />
                            {errors.firstName?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                          </div>


                          <div className="form-group mb-3" style={{ width: "47%" }}>
                            <label className="mb-1 ">
                              <strong>Last Name</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Smith"
                              {...register("lastName", { required: true })}
                            />
                            {errors.lastName?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                          </div>

                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className="form-group mb-3" style={{ width: "47%" }}>
                            <label className="mb-1 ">
                              <strong>Email</strong>
                            </label>
                            <input
                              type="email"
                              placeholder="johnsmith@example.com "
                              className="form-control"
                              {...register("email", { required: true })}
                            />
                            {errors.email?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                          </div>


                          {/* <div className="form-group mb-3 " style={{ width: "47%" }}>
                            <label className="mb-1 ">
                              <strong>Password</strong>
                            </label>
                            <input
                              className="form-control"
                              {...register("password", { required: true })}
                              placeholder="Please choose a strong and unique password"
                            />
                            {errors.password?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                          </div> */}

                          <div className="form-group mb-3" style={{ width: "47%" }}>
                            <label className="mb-1">
                              <strong>Password</strong>
                            </label>
                            <div style={{position:"relative"}}>
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                {...register("password", { required: true })}
                                placeholder="Must have atleast 8 characters"
                              />



                              <span style={{position:"absolute",top:"10px",right:"20px"}} onClick={togglePassword}>{showPassword ?<i class="bi bi-eye" style={{fontSize:"24px"}}></i>  : <i class="bi bi-eye-slash" style={{fontSize:"24px"}}></i>}</span>

                            </div>
                            {errors.password?.type === "required" && (
                              <div style={{ color: "red" }}>This field is required</div>
                            )}
                          </div>

                        </div>
                        <h4 className="breadcrumb-item active text-primary" style={{ margin: "28px 0px" }}>Business Info</h4>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className="form-group mb-3" style={{ width: "47%" }}>
                            <label className="mb-1 ">
                              <strong>Company Name</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="XYZ Corporation"
                              {...register("companyName", { required: true })}
                            />
                            {errors.companyName?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                          </div>

                          {/* <div className="form-group mb-3 " style={{ width: "47%" }}>
                            <label className="mb-1 ">
                              <strong>Company Type</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              {...register("companyType", { required: true })}
                            />
                            {errors.companyType?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                          </div> */}

                          <div className="form-group mb-3" style={{ width: "47%" }}>
                            <label className="mb-1"><strong>Company Type</strong></label>
                            <select
                              className="form-select"
                              id="inputState"
                              {...register("companyType", { required: true })}
                              defaultValue=""
                              // style={{
                              //   color: "#a4a5a5 !important", /* set placeholder color */
                              // }}
                              // onChange={(e) => {
                              //   e.target.style.color = e.target.value !== "" ? "black" : "#a4a5a5 !important";
                              // }}
                            >
                              <option value=""  >Select the type of company</option>

                              {companyTypes?.map((company) => {
                                return (<>
                                  <option value={`${company?._id}`}>{company?.companyType}</option>
                                </>)
                              })}
                            </select>
                            {errors.companyType?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                          </div>



                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>

                          <div className="form-group mb-3 " style={{ width: "47%" }}>
                            <label className="mb-1 ">
                              <strong>Phone Number</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="(555) 555-5555"
                              {...register("number", { required: true })}
                            />
                            {errors.number?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                          </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className="form-group mb-3 " style={{ width: "100%" }}>
                            <label className="mb-1 ">
                              <strong>Address</strong>
                            </label>
                            <input
                              type="text"
                              placeholder="123 Main St"
                              className="form-control"
                              {...register("businessAddress", { required: true })}
                            />
                            {errors.businessAddress?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                          </div>

                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className="form-group mb-3 " style={{ width: "37%" }}>
                            <label className="mb-1 ">
                              <strong>City</strong>
                            </label>
                            <input type="text"  {...register("city",{required:true})} className="form-control" placeholder="New York" />
                            {errors.city?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                          </div>
                          <div className="form-group mb-3 " style={{ width: "27%" }}>
                            <label className="mb-1 ">
                              <strong>State</strong>
                            </label>
                            <input type="text"  {...register("state",{required:true})} placeholder="NY" className="form-control" />
                            {errors.state?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                          </div>
                          <div className="form-group mb-3 " style={{ width: "27%" }}>
                            <label className="mb-1 ">
                              <strong>Zip</strong>
                            </label>
                            <input type="text"  {...register("zip",{required:true})} className="form-control" placeholder="12345" />
                            {errors.zip?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                          </div>

                        </div>
                        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className="form-group mb-3 " style={{ width: "100%" }}>
                            <label className="mb-1 ">
                              <strong>Address</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("businessAddress", { required: true })}
                            />
                            {errors.businessAddress?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
                          </div>

                        </div> */}
                        <div className="text-center mt-4" style={{ display: "flex", justifyContent: "center" }}>
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            style={{ width: "50%" }}
                          >
                            SIGNUP
                          </button>
                         
                        </div>
                        {isLoading && <BgSpinner />}
                      </form>
                      <div className="new-account mt-3">
                        <p className="" style={{ textAlign: "center" }}>
                          Already have an account?{" "}
                          <Link className="text-primary" to="/login">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);

