import React, { Fragment, useEffect, useState } from "react";
// BS
import { Dropdown, Nav, Tab } from "react-bootstrap";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import ImageSliderAsset from './auditDetails/ImageSliderAsset';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import swal from 'sweetalert';
import { Col, Badge, Card, Row } from "react-bootstrap";
import axios from 'axios';
import { DynamicStar } from 'react-dynamic-star';
// Page titie
import PageTitle from "../../layouts/PageTitle";
import { ToastContainer } from "react-toastify";
import Spinner from "../Loding/Sppinner";

const AuditDetail = () => {
    const history = useHistory()
    const auth = useSelector(state => state.auth)
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [asset, setAssets] = useState({})
    const getSingleUserDetail = async () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/audit/single/${id}`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

            setAssets(res.data.asset)
            setIsLoading(false)
        }).catch((err) => {
            swal(`${err?.response?.data?.message}`)
            if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
        })
    }
    useEffect(() => { getSingleUserDetail() }, [])

    return (
       <>
        <ToastContainer
                autoClose={3000}
            />
       {isLoading?(<Spinner/>):( <Fragment>
            {/* <Ext /> */}
           
            <table
                id="example2"
                className="table card-table default-table display mb-4 dataTablesCard no-footer"
            >
                <thead>
                    <tr role="row">

                        <th className="sorting" style={{ textAlign: "center" }}>Asset</th>
                        <th className="sorting" style={{ textAlign: "center" }}>Submitted By</th>

                        <th className="sorting" style={{ textAlign: "center" }}>Qr Code Available</th>
                        <th className="sorting" style={{ textAlign: "center" }}>Rating</th>
                        <th className="sorting" style={{ textAlign: "center" }}>Milage/Hours</th>
                        <th className="sorting" style={{ textAlign: "center" }}>Submited At</th>


                    </tr>
                </thead>
                <tbody>

                    <tr role="row" >

                        <td>
                            <div className="media-bx" >
                                <Link to={`/asset-detail/${asset?.asset?._id}`} className="text-black" style={{ display: "flex", alignItems: "center" }}>
                                    <img className="me-3 rounded" src={`${process.env.REACT_APP_BASE_URL}/${asset?.asset?.images[0]?.url}`} alt="" />
                                    <div>

                                        <h4 className="mb-0 mt-1">
                                            {`${asset?.asset?.assetName}`}
                                        </h4>
                                        <span className="text-primary">{asset?.asset?.assetCode}</span>

                                    </div>
                                </Link>
                            </div>
                        </td>
                        <td>
                            <div className="media-bx" style={{ display: "flex", justifyContent: "center" }}>
                                <img className="me-3 rounded" src={`${process.env.REACT_APP_BASE_URL}/${asset?.user?.profile}`} alt="" />
                                <div>
                                    <h4 className="mb-0 mt-1">
                                        <Link to={`/user-detail/${asset?.user?._id}`} className="text-black">{`${asset?.user?.name?.firstName?.toString().toLowerCase().replace(/(^|\s)\S/g, L => L.toUpperCase())} ${asset?.user?.name?.lastName?.toString().toLowerCase().replace(/(^|\s)\S/g, L => L.toUpperCase())}`}</Link>
                                    </h4>
                                </div>
                            </div>
                        </td>




                        <td>
                            <div style={{ textAlign: "center" }}>

                                <h5>{`${(asset?.isQrCode === true) ? "Yes" : "No"}`}</h5>
                            </div>
                        </td>
                        <td>
                            <div style={{ textAlign: "center" }}>

                                <DynamicStar
                                    width={20}
                                    height={20}
                                    rating={asset?.rating}
                                    emptyStarColor="transparent"
                                    totalStars={5}
                                    outlined={true}
                                />
                            </div>
                        </td>
                        <td>
                            <div style={{ textAlign: "center" }}>

                                <h5>{asset?.auditUnitValue}</h5>
                            </div>
                        </td>


                        <td>
                            <div style={{ textAlign: "center" }}>
                                <h5>{asset?.submittedDate}</h5>
                                <span className="fs-14">{asset?.submittedTime}</span>
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
            {/* <div className="row">
                <div className="col-xl-12 col-xxl-12 col-lg-12">
                    <div className="card border-0 pb-0">
                        <div className="card-header border-0 pb-0" style={{ display: "flex", justifyContent: "center" }}>
                            <h2 style={{ textAlign: "center" }}>Driver Vehicle Inspection Report</h2>
                        </div>
                        <h4 style={{ marginLeft: "30px" }}>Vehicle</h4>
                        <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>

                            <div className="col-lg-3 col-xl-3 col-xxl-3">
                                <ul className="timeline">




                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.airCompressor === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Air Compressor</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.airLines === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Air Lines</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.battery === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Battery</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.vehicleBody === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Body</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.brakeAccessories === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Brake Accessories</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.brakesParking === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Brakes, Parking</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.clutch === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Brakes, Services</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.clutch === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Clutch</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.couplingDevicesVehicle === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Coupling Devices</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.defrosterHeater === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Defroster Heater</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.driveLine === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Dive Line</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.engine === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Engine</label>
                                            </div>

                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-lg-3 col-xl-3 col-xxl-3">
                                <ul className="timeline">
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.exhaust === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Exhaust</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.fifthWheel === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Fifth Wheel</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.fluidLevel === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Fluid Wheels</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.frameAndAssembly === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Frame And Assembly</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.frontAxel === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Front Axle</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.fuelTanks === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Fuel Tanks</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.horn === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Horn</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.mirror === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Mirrors</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.muffler === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Mufflers</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.oilPressure === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Oil Pressure</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.radiator === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Radiator</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.rearEnd === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Rear End</label>
                                            </div>

                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-lg-3 col-xl-3 col-xxl-3">
                                <ul className="timeline">

                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.reflector === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Reflectors</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.starter === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Starter</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.steering === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Steering</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.suspensionSystem === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Suspension System</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.tireChains === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Tire Chains</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.tires === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Tires</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.transmission === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Transmission</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.tripRecorder === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Trip Recorder</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.wheelsAndRims === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Wheels And Rims</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.window === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Windows</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.vehicle?.windShieldWiper === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >WindShield Wipers</label>
                                            </div>

                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <h4 style={{ marginLeft: "30px" }}>Lights</h4>
                        <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>

                            <div className="col-lg-3 col-xl-3 col-xxl-3">
                                <ul className="timeline">

                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.lights?.headStop === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Head/Stop</label>
                                            </div>

                                        </div>
                                    </li>

                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.lights?.clearanceMarker === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Clearance Marker</label>
                                            </div>

                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-lg-3 col-xl-3 col-xxl-3">
                                <ul className="timeline">


                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.lights?.tailDash === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Tail/Dash</label>
                                            </div>

                                        </div>
                                    </li>


                                </ul>
                            </div>

                            <div className="col-lg-3 col-xl-3 col-xxl-3">
                                <ul className="timeline">
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.lights?.turnIndicators === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Turn Indicators</label>
                                            </div>

                                        </div>
                                    </li>


                                </ul>
                            </div>

                        </div>

                        <h4 style={{ marginLeft: "30px" }}>Safety Equipments</h4>
                        <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>

                            <div className="col-lg-3 col-xl-3 col-xxl-3">
                                <ul className="timeline">

                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.safetyEquipment?.fireExtinguisher === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Fire Extinguisher</label>
                                            </div>

                                        </div>
                                    </li>

                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.safetyEquipment?.spareBulbAndFuses === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Spare Bulbs and Fuses</label>
                                            </div>

                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-lg-3 col-xl-3 col-xxl-3">
                                <ul className="timeline">


                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.safetyEquipment?.flagsFlares === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Flags/Flare/Fuses</label>
                                            </div>

                                        </div>
                                    </li>

                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.safetyEquipment?.spareSealBeam === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Spare Seal Beam</label>
                                            </div>

                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-lg-3 col-xl-3 col-xxl-3">
                                <ul className="timeline">


                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.safetyEquipment?.reflectiveTriangles === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Reflective Triangles</label>
                                            </div>

                                        </div>
                                    </li>


                                </ul>
                            </div>

                        </div>
                        <h4 style={{ marginLeft: "30px" }}>Trailer Parts</h4>
                        <div className="card-body" style={{ display: "flex", justifyContent: "space-between" }}>

                            <div className="col-lg-3 col-xl-3 col-xxl-3">
                                <ul className="timeline">

                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.brakeConnections === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Brake Connections</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.brakes === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Brakes</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.couplingDevices === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Coupling Devices</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.couplingPin === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >{`Coupling (King) Pin`}</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.doors === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Doors</label>
                                            </div>

                                        </div>
                                    </li>



                                </ul>
                            </div>

                            <div className="col-lg-3 col-xl-3 col-xxl-3">
                                <ul className="timeline">





                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.hitch === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Hitch</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.lightsAll === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Landing Gears</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.reflectiveTape === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Lights All</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.landingGear === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Landing Gears</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.roof === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Roof</label>
                                            </div>

                                        </div>
                                    </li>


                                </ul>
                            </div>
                            <div className="col-lg-3 col-xl-3 col-xxl-3">
                                <ul className="timeline">
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.tires === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Tires</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.wheelsAndRims === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Wheels And Rims</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.suspensionSystem === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Suspension System</label>
                                            </div>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-panel">
                                            <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckBox2"
                                                    checked={asset?.trailerProperties?.tarapulin === true ? true : false}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheckBox2"
                                                >Tarapulin</label>
                                            </div>

                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <h2 style={{ textAlign: "center", marginTop: "20px" }}>{asset?.title?.toString().toLowerCase().replace(/(^|\s)\S/g, L => L.toUpperCase())}</h2>
            <div className="row">
                <h4 style={{ margin: "40px 0px 40px 0px" }}>Remarks</h4>
                <div className="card-body" style={{ padding: "0px" }}>

                    <PerfectScrollbar
                        style={{ height: "fitContent", width: "fitContent" }}
                        id="DZ_W_TimeLine"
                        className="widget-timeline dz-scroll ps ps--active-y"
                    >
                        <ul className="timeline">
                            <li style={{ width: "fit-content" }}>
                                <div className="timeline-badge primary"></div>
                                <Link
                                    className="timeline-panel text-muted"
                                    style={{ width: "fit-content" }}
                                >
                                    {/* <span>10 minutes ago</span> */}
                                    <h6 className="mb-0">
                                        {asset?.remarks}
                                    </h6>
                                </Link>
                            </li>
                        </ul>
                    </PerfectScrollbar>
                </div>
            </div>
            <div className="row">
                <h4 style={{ margin: "40px 0px 50px 0px" }}>Asset Images</h4>
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header border-0 pb-0">

                        </div>
                        <div className="card-body">
                            {(asset?.images?.length <= 2) ? <div style={{ display: "flex", gap: "10%" }}>
                                {asset?.images?.map((data, index) => {
                                    return (<>
                                        <div className="items px-2" key={index}>
                                            <img src={`${process.env.REACT_APP_BASE_URL}/${data?.url}`} alt="" style={{ width: "100px", height: "150px" }} />
                                        </div>
                                    </>)
                                })}
                            </div> : <ImageSliderAsset images={asset?.images} />}

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>)}
       </>
    );
};

export default AuditDetail;
