import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Dropdown, Modal, Tab, Nav, Badge } from "react-bootstrap";
import classnames from 'classnames';
import PerfectScrollbar from "react-perfect-scrollbar";
import swal from 'sweetalert';
import axios from 'axios';
import { saveAs } from 'file-saver'
import AssetHistory from './Guest/AssetHistory';
import PrintButton from '../PrintButton';
import product1 from "../../../images/product/1.jpg";
import product2 from "../../../images/product/2.jpg";
import product3 from "../../../images/product/3.jpg";
import product4 from "../../../images/product/4.jpg";
import tab1 from "../../../images/tab/1.jpg";
import tab2 from "../../../images/tab/2.jpg";
import tab3 from "../../../images/tab/3.jpg";
import tab4 from "../../../images/tab/4.jpg";

import { ToastContainer, toast } from "react-toastify";
import Spinner from '../Loding/Sppinner';
const GuestList = () => {
	const auth = useSelector(state => state.auth)
	const { id } = useParams();
	const [reviewToggle, setReviewToggle] = useState(false);
	const [activeTab, setActiveTab] = useState('0');
	const [isLoading, setIsLoading] = useState(true)
	const [IsConfirm,setIsConfirm]=useState(false)
	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	const [data, setData] = useState(
		document.querySelectorAll("#example2_wrapper tbody tr")
	);
	const sort = 10;
	const activePag = useRef(0);
	const [test, settest] = useState(0);

	// Active data
	const chageData = (frist, sec) => {
		for (var i = 0; i < data.length; ++i) {
			if (i >= frist && i < sec) {
				data[i].classList.remove("d-none");
			} else {
				data[i].classList.add("d-none");
			}
		}
	};
	// use effect
	useEffect(() => {
		setData(document.querySelectorAll("#example2_wrapper tbody tr"));
		//chackboxFun();
	}, [test]);


	// Active pagginarion
	activePag.current === 0 && chageData(0, sort);



	const chackbox = document.querySelectorAll(".sorting_1 input");
	const motherChackBox = document.querySelector(".sorting_asc input");

	const [asset, setAsset] = useState({})
	const [first, setFirst] = useState();
	const [qrCode, setQrCode] = useState();

	const history = useHistory();

	const getSingleAssetDetail = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/single/${id}`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

			setAsset(res.data.asset)
			setFirst(res?.data?.first)
			setQrCode(res?.data?.code)
			setIsLoading(false)
		}).catch((err) => {

			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})
	}
	// const handleDelete = async () => {
	// 	axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/delete/${id}`, {
	// 		headers: {
	// 			Authorization: `Bearer ${auth.auth.token}`,
	// 		}

	// 	}).then((res) => {
	// 		showToastMessage(`${res?.data?.message}`)
	// 		setIsLoading(false)
	// 		history.push("/asset-list")
	// 	}).catch((err) => {
	// 		swal(`${err?.response?.data?.message}`)
	// 		if (err?.response?.status === 401) {
	// 			history.push("/login")
	// 			window.location.reload();
	// 		}
	// 	})

	// }
	const handleDeleteConfirm = async () => {

		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/delete/${id}`,{isConfirm:true}, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
			}
		}).then((res) => {
			// console.log(auth.auth.token);
			// console.log(res?.data,"confir");
			if(res?.data?.isConfirm===true){
				swal(`${res?.data?.message}`)
				history.push("/asset-list")
			}else if(res?.data?.isConfirm===false){
				swal({
					title: `Are you sure?`,
					text: `${res?.data?.message} You won't be able to revert this!`,
					icon: "warning",
					buttons: ["Cancel", "Confirm"],
					dangerMode: true,
				  })
				  .then((result) => {
					console.log(result);
					if(result===null){
						setIsConfirm(false)
						
					}
					else if (result===true) {
						console.log("gererere");
						setIsConfirm(true)
					   handleDeleteConfirm(id)
					  
					} 
				  });
			}
			
			
		}).catch((err) => {
			console.log(err?.response?.data);
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})

	}
	const handleDelete = async () => {
		console.log("isConfirm",IsConfirm);
		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/delete/${id}`,{isConfirm:IsConfirm}, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
			}
		}).then((res) => {
			console.log(res?.data,"res");
			if(res?.data?.isConfirm===true){
				swal(`${res?.data?.message}`)
				history.push("/asset-list")
			}else if(res?.data?.isConfirm===false){
				swal({
					title: `Are you sure?`,
					text: `${res?.data?.message} You won't be able to revert this!`,
					icon: "warning",
					buttons: ["Cancel", "Confirm"],
					dangerMode: true,
				  })
				  .then((result) => {
					console.log(result);
					if(result===null){
						setIsConfirm(false)
						
					}
					else if (result===true) {
				
						setIsConfirm(true)
					   handleDeleteConfirm(id)
					  
					} 
				
				  });
			}
			
			
		}).catch((err) => {
			
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})

	}
	useEffect(() => { getSingleAssetDetail() }, [])
	const showToastMessage = (msg) => {
		toast.success(`${msg}`, {
			position: toast.POSITION.BOTTOM_LEFT,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			closeButton: <i className="fa fa-times" aria-hidden="true"></i>,
			style: {
				background: "white",
				borderRadius: "5px",
				borderLeft: "10px solid green",
				color: "black",
				boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
			},
		});
	};
	function dateFormat(date) {
		const month = date.getMonth();
		const day = date.getDate();
		const monthString = month >= 10 ? month : `0${month}`;
		const dayString = day >= 10 ? day : `0${day}`;
		return `${dayString}-${monthString}-${date.getFullYear()}`;
	}
	return (
		<>
			<ToastContainer
				autoClose={3000}
			/>
			{isLoading ? (<Spinner />) : (<Tab.Container defaultActiveKey="info">
				<div className="row">
					<div className="col-xl-12">
						<div className="d-flex mb-4 justify-content-between align-items-center flex-wrap">
							<div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4">
								<Nav as="ul" className="nav nav-tabs">
									<Nav.Item as="li" className="nav-item me-5">
										<Nav.Link className="nav-link" eventKey="info">Asset Info</Nav.Link>
									</Nav.Item>
									<Nav.Item as="li" className="nav-item">
										<Nav.Link className="nav-link" eventKey="history">Asset History</Nav.Link>
									</Nav.Item>
								</Nav>
							</div>
							<div className="table-search">
								<div className="input-group search-area mb-xxl-0 mb-4">
									<input type="text" className="form-control" placeholder="Search here" />
									<span className="input-group-text"><Link to={"#"}><i className="flaticon-381-search-2"></i></Link></span>
								</div>
							</div>

						</div>
						<Tab.Content>

							<Tab.Pane eventKey="info">
								<div className="row">
									<div className="col-xl-12 col-lg-12  col-md-12 col-xxl-12">
										<div className="card">
											<div className="card-body">
												<div className="row">
													<div className="col-xl-3 col-lg-4  col-md-4 col-xxl-4 ">
														<Tab.Container defaultActiveKey="first0">
															<Tab.Content>
																{asset?.images?.map((img, index) => {
																	return <>
																		<Tab.Pane eventKey={`first${index}`} style={{ border: "1px solid grey", padding: "10%" }}>
																			<img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${img.url}`} alt="" />
																		</Tab.Pane>
																	</>
																})}

																{/* <Tab.Pane eventKey="second">
																	<img className="img-fluid" src={product2} alt="" />
																</Tab.Pane>
																<Tab.Pane eventKey="third">
																	<img className="img-fluid" src={product3} alt="" />
																</Tab.Pane>
																<Tab.Pane eventKey="four">
																	<img className="img-fluid" src={product4} alt="" />
																</Tab.Pane> */}
															</Tab.Content>
															<div className="tab-slide-content new-arrival-product mb-4 mb-xl-0">

																<Nav as="ul" className="nav slide-item-list mt-3" role="tablist">
																	{asset?.images?.map((img, index) => {
																		return <>
																			<Nav.Item as="li">
																				<Nav.Link as="a" eventKey={`first${index}`} to={`#first${index}`}>
																					<img className="img-fluid" src={`${process.env.REACT_APP_BASE_URL}/${img.url}`} alt="" width={50} />
																				</Nav.Link>
																			</Nav.Item>
																		</>
																	})}

																	{/* <Nav.Item as="li">
																		<Nav.Link as="a" eventKey="second" to="#second">
																			<img className="img-fluid" src={tab2} alt="" width={50} />
																		</Nav.Link>
																	</Nav.Item>
																	<Nav.Item as="li">
																		<Nav.Link as="a" eventKey="third" to="#third">
																			<img className="img-fluid" src={tab3} alt="" width={50} />
																		</Nav.Link>
																	</Nav.Item>
																	<Nav.Item as="li">
																		<Nav.Link as="a" to="#for" eventKey="four">
																			<img className="img-fluid" src={tab4} alt="" width={50} />
																		</Nav.Link>
																	</Nav.Item> */}
																</Nav>
															</div>
														</Tab.Container>




														{/* Tab panes */}
														{/* <Tab.Container defaultActiveKey="first0">
															{console.log(first)}
															<Tab.Content>
																{asset?.images?.map((img,index) => {
																	
																	return (<>
																	{console.log("img",`first${index}`)}
																		<Tab.Pane eventKey={`first${index}`} style={{ border: "1px solid grey", padding: "10%" }}>
																			<img className="img-fluid" src={`http://localhost:5000/${img.url}`} alt="" style={{ width: "100%", height: "300px" }} />
																		</Tab.Pane>
																	</>)
																})}
																
															</Tab.Content>
															<div className="tab-slide-content new-arrival-product mb-4 mb-xl-0">
															
																<Nav as="ul" className="nav slide-item-list mt-3" role="tablist">
																	{asset?.images?.map((img) => {
																		return (<>
																			<Nav.Item as="li">
																				<Nav.Link as="a" eventKey={`${img._id}`} to={`#${img._id}`}>
																					<img className="img-fluid" src={`http://localhost:5000/${img.url}`} alt="" style={{ width: "100%", height: "100px" }} />
																				</Nav.Link>
																			</Nav.Item>
																		</>)
																	})}
																	
																</Nav>
															</div>
														</Tab.Container> */}
													</div>
													{/*Tab slider End*/}

													<div className="col-xl-6 col-lg-6  col-md-6 col-xxl-6 col-sm-12">
														<div className="product-detail-content">
															{/*Product details*/}
															<div className="new-arrival-content pr">
																<div style={{ display: "flex", width: "45%", justifyContent: "space-between", alignItems: "baseline" }}>
																	<h4 className="breadcrumb-item active text-primary" style={{ marginBottom: "15px", fontSize: "18px" }}>General Information</h4>

																	<Badge variant={`${asset?.status === "checked-out" ? "danger" : ((asset?.status === "available") ? "success" : "warning")}`}>{asset?.status}</Badge>

																</div>

																<div className="comment-review star-rating">
																	<div style={{ display: "flex", flexDirection: "column" }} className="col-xl-6 col-lg-6  col-md-6 col-xxl-6 col-sm-6">
																		<h4 style={{ marginBottom: "5px" }}>{`${asset?.assetName} (${asset.serialNumber})`}</h4>
																		{/* <span className="review-text">{asset.serialNumber}</span> */}
																		<p className="price float-left d-block " style={{ fontSize: "18px" }}><Link to={`/category-detail/${asset?.category?._id}`} className="text-primary">{asset?.category?.categoryName}</Link></p>

																	</div>

																</div>
																{/* <div className="d-table" style={{ marginTop: "1.2rem", marginBottom: "0.62rem" }}>
																	<div className="">
																		<PerfectScrollbar
																			style={{ height: "auto" }}
																			id="DZ_W_TimeLine1"
																			className="widget-timeline dz-scroll style-1 height370 ps ps--active-y"
																		>
																			<ul className="timeline">
																				<li>
																					<div className="timeline-badge primary"></div>
																					<Link
																						className="timeline-panel text-muted"

																					>
																						<span>Condition</span>
																						<h6 className="mb-0">
																							{asset?.condition?.assetConditionType}
																						</h6>
																					</Link>
																				</li>

																			</ul>
																		</PerfectScrollbar>
																	</div>


																</div> */}
																<div  style={{ marginTop: "1.2rem", marginBottom: "0.62rem" }}>
																	<div>
																		<span style={{ fontSize: "0.75rem", display: "block", marginBottom: "0.3125rem", opacity: "0.8", letterSpacing: "0.0625rem" }}>Condition</span>
																		<h6 className="mb-0">
																			{asset?.condition?.assetConditionType}
																		</h6>
																	</div>
																	
																</div>
																<div style={{ display: "flex", width: "45%", justifyContent: "space-between", marginBottom: "1rem" }}>
																	<div>
																		<span style={{ fontSize: "0.75rem", display: "block", marginBottom: "0.3125rem", opacity: "0.8", letterSpacing: "0.0625rem" }}>Unit</span>
																		<h6 className="mb-0">
																			{asset?.unit?.name}
																		</h6>
																	</div>
																	<div>
																		<span style={{ fontSize: "0.75rem", display: "block", marginBottom: "0.3125rem", opacity: "0.8", letterSpacing: "0.0625rem" }}>Current(Milage/Hours)</span>

																		<h6 className="mb-0">
																			{asset?.assetCurrentUnitValue}
																		</h6>
																	</div>
																</div>
																<div style={{ display: "flex", width: "45%", justifyContent: "space-between", marginBottom: "1rem" }}>
																	<div>
																		<span style={{ fontSize: "0.75rem", display: "block", marginBottom: "0.3125rem", opacity: "0.8", letterSpacing: "0.0625rem" }}>Year</span>
																		<h6 className="mb-0">
																			{asset?.year}
																		</h6>
																	</div>
																	<div>
																		<span style={{ fontSize: "0.75rem", display: "block", marginBottom: "0.3125rem", opacity: "0.8", letterSpacing: "0.0625rem" }}>Make</span>

																		<h6 className="mb-0">
																			{asset?.make}
																		</h6>
																	</div>
																	<div>
																		<span style={{ fontSize: "0.75rem", display: "block", marginBottom: "0.3125rem", opacity: "0.8", letterSpacing: "0.0625rem" }}>Model</span>

																		<h6 className="mb-0">
																			{asset?.model}
																		</h6>
																	</div>
																</div>
																<div style={{ display: "flex", width: "45%", justifyContent: "space-between", marginBottom: "1rem" }}>
																	<div>
																		<span style={{ fontSize: "0.75rem", display: "block", marginBottom: "0.3125rem", opacity: "0.8", letterSpacing: "0.0625rem" }}>Location</span>
																		<h6 className="mb-0">
																			{asset?.location}
																		</h6>
																	</div>
																	<div>
																		<span style={{ fontSize: "0.75rem", display: "block", marginBottom: "0.3125rem", opacity: "0.8", letterSpacing: "0.0625rem" }}>State</span>

																		<h6 className="mb-0">
																			{asset?.state}
																		</h6>
																	</div>
																	<div>
																		<span style={{ fontSize: "0.75rem", display: "block", marginBottom: "0.3125rem", opacity: "0.8", letterSpacing: "0.0625rem" }}>Division</span>

																		<h6 className="mb-0">
																			{asset?.division}
																		</h6>
																	</div>
																</div>

																<div style={{ display: "flex", width: "45%", justifyContent: "space-between", marginBottom: "1rem" }}>

																	<div className="form-check custom-checkbox mb-3">
																		<input
																			type="checkbox"
																			className="form-check-input"
																			id="customCheckBox1"

																			defaultChecked={asset?.isIncludedInDVIR}
																			disabled
																		/>
																		<label
																			className="form-check-label"
																			htmlFor="customCheckBox1"
																		>
																			DVIR
																		</label>
																	</div>
																	<div className="form-check custom-checkbox mb-3">
																		<input
																			type="checkbox"
																			className="form-check-input"
																			id="customCheckBox1"

																			defaultChecked={asset?.isIncludedInAudit}
																			disabled
																		/>
																		<label
																			className="form-check-label"
																			htmlFor="customCheckBox1"
																		>
																			AUDIT
																		</label>
																	</div>
																</div>

																<div className="card-body" style={{ padding: "0px" }}>

																	<PerfectScrollbar
																		style={{ height: "fitContent", width: "fitContent" }}
																		id="DZ_W_TimeLine"
																		className="widget-timeline dz-scroll ps ps--active-y"
																	>
																		<ul className="timeline">
																			<li style={{ width: "fit-content" }}>
																				<div className="timeline-badge primary"></div>
																				<Link
																					className="timeline-panel text-muted"
																					style={{ width: "fit-content" }}
																				>
																					{/* <span>10 minutes ago</span> */}
																					<h6 className="mb-0">
																						{asset?.generalNote}
																					</h6>
																				</Link>
																			</li>
																		</ul>
																	</PerfectScrollbar>
																</div>


																<div style={{ marginTop: "1rem" }}>
																	<h4 className="breadcrumb-item active text-primary" style={{ marginBottom: "16px", fontSize: "18px" }}>Financial Information</h4>
																	<div style={{ display: "flex", width: "50%", justifyContent: "space-between", marginBottom: "1rem" }}>
																		<div>
																			<span style={{ fontSize: "0.75rem", display: "block", marginBottom: "0.3125rem", opacity: "0.8", letterSpacing: "0.0625rem" }}>Purchasing Date</span>
																			<h6 style={{ marginBottom: "3px" }}>
																				{`${dateFormat(new Date(asset?.dateOfPurchase))}`}
																			</h6>
																			<h6 className="mb-0">
																				{new Date(asset?.dateOfPurchase).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
																			</h6>
																		</div>
																		<div>
																			<span style={{ fontSize: "0.75rem", display: "block", marginBottom: "0.3125rem", opacity: "0.8", letterSpacing: "0.0625rem" }}>Purchasing Price</span>

																			<h6 className="mb-0">
																				{asset?.price}
																			</h6>
																		</div>
																	</div>
																	<div style={{ display: "flex", width: "50%", justifyContent: "space-between", marginBottom: "1.5rem" }}>
																		<div>
																			<span style={{ fontSize: "0.75rem", display: "block", marginBottom: "0.3125rem", opacity: "0.8", letterSpacing: "0.0625rem" }}>Vendor</span>
																			<h6 className="mb-0">
																				{asset?.vendor}
																			</h6>
																		</div>
																		<div>
																			<span style={{ fontSize: "0.75rem", display: "block", marginBottom: "0.3125rem", opacity: "0.8", letterSpacing: "0.0625rem" }}>Current Value</span>

																			<h6 className="mb-0">
																				{asset?.currentValue}
																			</h6>
																		</div>

																	</div>

																	<div className="card-body" style={{ padding: "0px" }}>

																		<PerfectScrollbar
																			style={{ height: "fitContent", width: "fitContent" }}
																			id="DZ_W_TimeLine"
																			className="widget-timeline dz-scroll ps ps--active-y"
																		>
																			<ul className="timeline">
																				<li style={{ width: "fit-content" }}>
																					<div className="timeline-badge primary"></div>
																					<Link
																						className="timeline-panel text-muted"
																						style={{ width: "fit-content" }}
																					>
																						{/* <span>10 minutes ago</span> */}
																						<h6 className="mb-0">
																							{asset?.financialNote}
																						</h6>
																					</Link>
																				</li>
																			</ul>
																		</PerfectScrollbar>
																	</div>

																</div>

																{/*Quantity start*/}
																{/* <div className="col-2 px-0">
																	<input type="number" name="num" className="form-control input-btn input-number" defaultValue={1} />
																</div> */}
																{/*Quanatity End*/}
																<div className="row mt-4 pt-3">
																	<div className="col-3">
																		<Link to={`/asset-edit/${id}`} className="btn btn-dark light btn-block">Edit</Link>
																	</div>
																	<div className="col-3">
																		<Link to={"#"} className="btn btn-danger btn-block" onClick={handleDelete}><i class="bi bi-trash"></i></Link>
																	</div>
																</div>
															</div>
														</div>
													</div>

													{qrCode && <div className="col-xl-2 col-lg-2  col-md-2 col-xxl-2 ">
														<div style={{ display: "flex", flexDirection: "column", marginRight: "20px" }}>

															<img className="img-fluid" src={qrCode} alt="" style={{}} />
															{/* <img src={qrCode} alt="QR Code" style={{ width: '350px' }} /> */}
															<div style={{ display: "flex", gap: "3%" }} className="col-xl-12 col-lg-12  col-md-9 col-xxl-12">
																<a className="btn btn-primary btn-block" style={{ textAlign: "center", color: "white", fontSize: "16px", textDecoration: "none" }} onClick={(e) => {
																	saveAs(qrCode, `${asset?.assetName}-${asset?.serialNumber}`)
																	e.preventDefault()
																}}>download</a>

																<PrintButton imageUrl={qrCode} />
															</div>
														</div>
													</div>}
												</div>
											</div>
										</div>
									</div>
									{/* review */}
									{/* <Modal show={reviewToggle} onHide={setReviewToggle} className="modal fade" id="reviewModal">
										<>
											<div className="modal-content">
												<div className="modal-header">
													<h5 className="modal-title">Review</h5>
													<button
														type="button"
														onClick={() => setReviewToggle(false)}
														className="btn-close"
														data-dismiss="modal"
													>
													</button>
												</div>
												<div className="modal-body">
													<form
														onSubmit={(e) => {
															e.preventDefault();
															setReviewToggle(false);
														}}
													>
														<div className="text-center mb-4">
															<img
																className="img-fluid rounded"
																width={78}
																src={avater1}
																alt="DexignZone"
															/>
														</div>
														<div className="form-group">
															<div className="rating-widget mb-4 text-center">
															
																<div className="rating-stars">
																	<ul
																		id="stars"
																		className="d-flex justify-content-center align-items-center"
																	>

																	</ul>
																</div>
															</div>
														</div>
														<div className="form-group mb-3">
															<textarea
																className="form-control"
																placeholder="Comment"
																rows={5}
																defaultValue={""}
															/>
														</div>
														<button className="btn btn-success btn-block">RATE</button>
													</form>
												</div>
											</div>
										</>
									</Modal> */}
								</div>
							</Tab.Pane>
							<Tab.Pane eventKey="history">
								<AssetHistory id={id} />
							</Tab.Pane>


						</Tab.Content>
					</div>
				</div>
			</Tab.Container>)}
		</>
	)
}

export default GuestList;
