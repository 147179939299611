import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Tab, Nav } from "react-bootstrap";
import { useSelector } from 'react-redux';
///Import
import { Badge } from "react-bootstrap";
import axios from 'axios';
import swal from 'sweetalert';
import { ToastContainer } from 'react-toastify';
import Spinner from '../Loding/Sppinner';


const DropdownBlog = ({ id }) => {
	const auth = useSelector(state => state.auth)
	const history = useHistory();
	const [seed, setSeed] = useState("")

	const handleDelete = async () => {
		axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/user/company/user/${id}`, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
			}
		}).then((res) => {
			swal(`${res?.data?.message}`)
			setSeed("1")
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})

	}
	useEffect(() => { setSeed("2") }, [seed, setSeed, handleDelete])
	return (
		<>
			<Dropdown className="dropdown">
				<Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu">
					<Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/user-profile-edit/${id}`) }}>Edit</Dropdown.Item>
					<Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/user-detail/${id}`) }}>View</Dropdown.Item>
					<Dropdown.Item className="dropdown-item" onClick={handleDelete}>Delete</Dropdown.Item>

				</Dropdown.Menu>
			</Dropdown>
		</>
	)
}

const GuestList = () => {
	const auth = useSelector(state => state.auth)
	const [assets, setAssets] = useState([])
	const history = useHistory()
	const [itemsPerPage, setItemsPerPage] = useState(10)
	const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0)
	const [currentPage, setCurrentPage] = useState(1);
	const [isLoading, setIsLoading] = useState(true)
	const [IsConfirm,setIsConfirm]=useState(false)

	const getAllAssets = async () => {

		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/all`, { headers: { Authorization: `Bearer ${auth.auth.token}` }, params: { page: currentPage } }).then((res) => {
			setAssets(res?.data?.assets)
			setTotalNumberOfRecords(res?.data?.totalCount)
			setIsLoading(false)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	useEffect(() => {
		getAllAssets();
	}, [])
	// const handleDelete = async (id) => {
	// 	axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/delete/${id}`, {
	// 		headers: {
	// 			Authorization: `Bearer ${auth.auth.token}`,
	// 		}
	// 	}).then((res) => {
	// 		swal(`${res?.data?.message}`)
	// 		getAllAssets()
	// 	}).catch((err) => {
	// 		swal(`${err?.response?.data?.message}`)
	// 		if(err?.response?.status===401){
	// 			history.push("/login")
	// 			window.location.reload();
	// 		}
	// 	})

	// }

	const handleDeleteConfirm = async (id) => {

		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/delete/${id}`,{isConfirm:true}, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
			}
		}).then((res) => {
			// console.log(auth.auth.token);
			// console.log(res?.data,"confir");
			if(res?.data?.isConfirm===true){
				swal(`${res?.data?.message}`)
				getAllAssets()
			}else if(res?.data?.isConfirm===false){
				swal({
					title: `Are you sure?`,
					text: `${res?.data?.message} You won't be able to revert this!`,
					icon: "warning",
					buttons: ["Cancel", "Confirm"],
					dangerMode: true,
				  })
				  .then((result) => {
					console.log(result);
					if(result===null){
						setIsConfirm(false)
						getAllAssets()
					}
					else if (result===true) {
						console.log("gererere");
						setIsConfirm(true)
					   handleDeleteConfirm(id)
					  
					} 
				  });
			}
			
			
		}).catch((err) => {
			console.log(err?.response?.data);
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})

	}
	const handleDelete = async (id) => {
		console.log("isConfirm",IsConfirm);
		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/delete/${id}`,{isConfirm:IsConfirm}, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
			}
		}).then((res) => {
			console.log(res?.data,"res");
			if(res?.data?.isConfirm===true){
				swal(`${res?.data?.message}`)
				getAllAssets()
			}else if(res?.data?.isConfirm===false){
				swal({
					title: `Are you sure?`,
					text: `${res?.data?.message} You won't be able to revert this!`,
					icon: "warning",
					buttons: ["Cancel", "Confirm"],
					dangerMode: true,
				  })
				  .then((result) => {
					console.log(result);
					if(result===null){
						setIsConfirm(false)
						getAllAssets()
					}
					else if (result===true) {
				
						setIsConfirm(true)
					   handleDeleteConfirm(id)
					  
					} 
				
				  });
			}
			
			
		}).catch((err) => {
			
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})

	}
	function dateFormat(date) {
		const month = date.getMonth();
		const day = date.getDate();
		const monthString = month >= 10 ? month : `0${month}`;
		const dayString = day >= 10 ? day : `0${day}`;
		return `${date.getFullYear()}-${monthString}-${dayString}`;
	}
	useEffect(() => {
		getAllAssets()
	}, [currentPage, setCurrentPage])

	const checkMoveable = (page) => {

		const max = (Math.ceil(totalNumberOfRecords / 5))
		if ((page + 1) <= max) {
			return true
		} else {
			return false
		}

	}
	const checkBackward = (page) => {
		console.log(page);
		if (page > 1) {
			return true
		} else {
			return false
		}
	}
	let paggination = Array(Math.ceil(totalNumberOfRecords / itemsPerPage))
		.fill()
		.map((_, i) => i + 1);
	const lowerBound = (currentPage - 1) * itemsPerPage + 1;
	const upperBound = Math.min(currentPage * itemsPerPage, totalNumberOfRecords);

	return (
		<>
			<ToastContainer
				autoClose={3000}
			/>
			{isLoading ? (<Spinner />) : (<Tab.Container defaultActiveKey="All">
				<div className="row">
					<div className="col-xl-12">
						<div className="d-flex mb-4 justify-content-between align-items-center flex-wrap">
							<div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4">
								<Nav as="ul" className="nav nav-tabs">
									<Nav.Item as="li" className="nav-item">
										<Nav.Link className="nav-link" eventKey="All">All Assets</Nav.Link>
									</Nav.Item>
								</Nav>
							</div>
							<div className="table-search">
								<div className="input-group search-area mb-xxl-0 mb-4">
									<input type="text" className="form-control" placeholder="Search here" />
									<span className="input-group-text"><Link to={"#"}><i className="flaticon-381-search-2"></i></Link></span>
								</div>
							</div>
						</div>
						<div className="d-flex mb-4 justify-content-end align-items-center flex-wrap">
							<div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4">
								<Link to={"/add-asset"} className="btn btn-primary">Add New</Link>
							</div>
						</div>
						<Tab.Content>

							<Tab.Pane eventKey="All">
								<div className="table-responsive">
									<div id="example2_wrapper" className="dataTables_wrapper no-footer">
										<table
											id="example2"
											className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer"
										>
											<thead>
												<tr role="row">
													{/* <th className="sorting_asc bg-none" >
														<div className="form-check  style-1">
															<input type="checkbox" onClick={() => chackboxFun("all")} className="form-check-input" id="checkAll" required="" />
														</div>
													</th> */}
													<th className="sorting" style={{ textAlign: "center" }}>Asset</th>
													<th className="sorting" style={{ textAlign: "center" }}>Category</th>
													<th className="sorting" style={{ textAlign: "center" }}>Make</th>
													<th className="sorting" style={{ textAlign: "center" }}>Model</th>
													<th className="sorting" style={{ textAlign: "center" }}>Location</th>

													<th className="sorting" style={{ textAlign: "center" }}>Status</th>
													<th style={{ textAlign: "center" }}>Actions</th>

												</tr>
											</thead>
											<tbody>
												{assets.map((asset) => {
													return (
														<>
															<tr role="row" className="odd" key={asset._id}>
																{/* <td className="sorting_1">
																	<div className="form-check  style-1">
																		<input type="checkbox" onClick={() => chackboxFun()} className="form-check-input"
																			id="customCheckBox2" required=""
																		/>
																	</div>
																</td> */}
																<td>
																	<div className="media-bx" >
																		<Link to={`/asset-detail/${asset._id}`} className="text-black" style={{ display: "flex" }}>
																			<img className="me-3 rounded" src={`${process.env.REACT_APP_BASE_URL}/${asset?.images[0]?.url}`} alt="asset image" />
																			<div style={{display:"flex",alignItems:"center"}}>

																				<h4 className="mb-0 mt-1">
																					{`${asset?.assetName}`}
																				</h4>
																				{/* <span className="text-primary">{asset.assetCode}</span> */}
																			</div>
																		</Link>
																	</div>
																</td>
																<td>
																	<Link to={`/category-detail/${asset?.category?._id}`}>
																	<div style={{ textAlign: "center" }}>
																		<h5>{asset?.category?.categoryName}</h5>
																	</div>
																	</Link>
																</td>

																<td>
																	<div style={{ textAlign: "center" }}>
																		<h5>{asset?.make}</h5>

																	</div>
																</td>
																<td>
																	<div style={{ textAlign: "center" }}>
																		<h5>{asset?.model}</h5>

																	</div>
																</td>

																<td>
																	<div style={{ textAlign: "center" }}>
																		<h5>{asset?.location}</h5>

																	</div>
																</td>
																<td style={{ textAlign: "center" }}>
																	<Badge variant={`${asset?.status === "checked-out" ? "danger" : ((asset?.status === "available") ? "success" : "warning")}`}>{asset?.status}</Badge>
																</td>
																{/* <td>

																	<div style={{ textAlign: "center" }}>
																		<h5>{`${dateFormat(new Date(asset?.dateOfPurchase))}`}</h5>
																		<span className="fs-14">{`${new Date(asset?.dateOfPurchase).toLocaleTimeString()}`}</span>



																	</div>
																</td> */}
																<td>
																	<Dropdown className="dropdown" style={{ textAlign: "center" }}>
																		<Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
																			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																				<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
																				<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
																				<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
																			</svg>
																		</Dropdown.Toggle>
																		<Dropdown.Menu className="dropdown-menu">
																			<Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/asset-edit/${asset._id}`) }}>Edit</Dropdown.Item>
																			<Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/asset-detail/${asset._id}`) }}>View</Dropdown.Item>
																			<Dropdown.Item className="dropdown-item" onClick={() => handleDelete(asset._id)}>Delete</Dropdown.Item>

																		</Dropdown.Menu>
																	</Dropdown>
																</td>
															</tr>
														</>
													)
												})}


											</tbody>
										</table>
										<div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
											<div className="dataTables_info">
												Showing {lowerBound} to{" "}
												{upperBound} {" "}
												of {totalNumberOfRecords} entries
											</div>
											<div
												className="dataTables_paginate paging_simple_numbers mb-0"
												id="example2_paginate"
											>
												<Link
													className={`paginate_button previous ${(currentPage === 1) && "disabled"}`}
													onClick={() => {
														if (checkBackward(currentPage)) {
															setCurrentPage((pre) => pre - 1)
														}
													}}

												>
													<i className="fa fa-angle-double-left"></i> Previous
												</Link>
												<span>

													{paggination.map((number, i) => (

														<Link
															key={i}

															className={`paginate_button  ${currentPage === i + 1 ? "current" : ""
																} `}

														>
															{number}
														</Link>
													))}
												</span>
												<Link
													className={`paginate_button next ${(currentPage === ((Math.ceil(totalNumberOfRecords / 5)))) && "disabled"}`}

													onClick={() => {
														if (checkMoveable(currentPage)) {
															setCurrentPage((pre) => pre + 1)
														}
													}
													}
												>
													Next <i className="fa fa-angle-double-right"></i>
												</Link>
											</div>
										</div>
									</div>
								</div>
							</Tab.Pane>
							{/* <Tab.Pane eventKey="Pending">
								<PendingBlog />	
							</Tab.Pane>
							<Tab.Pane eventKey="Booked">
								<BookedBlog />
							</Tab.Pane>
							<Tab.Pane eventKey="Canceled">
								<CanceledBlog />
							</Tab.Pane>
							<Tab.Pane eventKey="Refund">
								<RefundBlog />
							</Tab.Pane> */}

						</Tab.Content>
					</div>
				</div>
			</Tab.Container>)}
		</>
	)
}
export { DropdownBlog };
export default GuestList;