import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import BgSpinner from '../BackgroundSpinner/BgSpinner';


const AppProfile = () => {
	const history = useHistory()
	const [activeToggle, setActiveToggle] = useState("setting");
	const auth = useSelector(state => state.auth)
	const [files, setFiles] = useState([]);
	const [units, setUnits] = useState([])
	const [categories, setCategories] = useState([])
	const [assetCondition, setAssetCondition] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	let showFiles = [];


	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();



	const handleUser = (data) => {
		let formData = new FormData()
		// console.log("data", data);
		setIsLoading(true)
		if (Object.keys(files).length === 0) {
			setIsLoading(false)
			return swal("please upload atleast one image")
		}
		if (Object.keys(files).length > 4) {
			setIsLoading(false);
			return swal("You only allowed to upload maximum 4 images")
		}
		files?.map((file) => {
			formData.append("image", file);
		})
		//data.image = file
		// console.log("newdata", data);

		formData.append("isIncludedInDVIR", data.isIncludedInDVIR);
		formData.append("isIncludedInAudit", data.isIncludedInAudit);
		// formData.append("assetCode", data.assetCode);
		formData.append("assetName", data.assetName);
		formData.append("serialNumber", data.serialNumber);
		formData.append("state", data.state);
		formData.append("price", data.price);
		formData.append("division", data.division);
		formData.append("location", data.location);
		formData.append("unit", data.unit);
		formData.append("category", data.category);
		formData.append("dateOfPurchase", data.dateOfPurchase);
		formData.append("condition", data.condition);
		formData.append("vendor", data.vendor)
		formData.append("financialNote", data.financialNote)
		formData.append("generalNote", data.generalNote)
		formData.append("currentValue", data.currentValue)
		formData.append("assetCurrentUnitValue", data.currentMilageHours)
		formData.append("year", data.year)
		formData.append("model", data.model)
		formData.append("make", data.make)

		// for (var pair of formData.entries()) {
		// 	console.log(pair[0] + ', ' + pair[1]);
		// }

		axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/register`, formData, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
				'content-type': 'multipart/form-data'
			}
		}).then((res) => {
			setIsLoading(false)
			history.push("/asset-list")
			showToastMessage(`${res?.data?.message}`)
		}).catch((err) => {
			setIsLoading(false)
			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})

	};
	const handleImages = (e) => {
		console.log(e.target.files);

		for (let i = 0; i < e.target.files.length; i++) {
			showFiles.push(e.target.files[i])
		}
		if (showFiles.length >= 0) {
			setFiles(showFiles)
		}
	}
	const handleRemoveImage = (name) => {

		setFiles(() => files.filter((file) => file.name !== name))

	}
	const showToastMessage = (msg) => {
		toast.success(`${msg}`, {
			position: toast.POSITION.BOTTOM_LEFT,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			closeButton: <i className="fa fa-times" aria-hidden="true"></i>,
			style: {
				background: "white",
				borderRadius: "5px",
				borderLeft: "10px solid green",
				color: "black",
				boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
			},
		});
	};
	const getAllUnits = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/unit/all`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

			setUnits(res?.data?.units)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const getAllAssetConditionTypes = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/assetCondition/all`).then((res) => {
			setAssetCondition(res?.data?.assetConditionTypes)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const getAllCategories = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/category/all`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

			setCategories(res?.data?.categories)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})
	}
	useEffect(() => {
		getAllCategories()
		getAllUnits()
		getAllAssetConditionTypes()
	}, [])
	return (
		<Fragment>
			<ToastContainer
				autoClose={3000}
			/>
			{/* <PageTitle activeMenu="Profile" motherMenu="App" /> */}
			<h4 className="breadcrumb-item active text-primary" style={{ marginBottom: "15px" }}>Add New Asset</h4>
			<div className="row">
				<div className="col-xl-8">
					<div className="card">
						<div className="card-body">
							<div className="profile-tab">
								<div className="custom-tab-1">
									<div className="tab-content">
										<div id="profile-settings" className={`tab-pane fade ${activeToggle === "setting" ? "active show" : ""}`}>
											<div className="pt-3">
												<div className="settings-form">
													<h5 className="breadcrumb-item active text-primary" style={{ marginBottom: "30px" }}>General Information</h5>
													<form onSubmit={handleSubmit(handleUser)} enctype="multipart/form-data">

														<div className="row">
															{/* <div className="form-group mb-3 col-md-6">
																<label className="form-label" >Asset Code</label>
																<input type="text"  {...register("assetCode", { required: true })} placeholder="Asset Code" className="form-control" />
																{errors.assetCode?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div> */}
															<div className="form-group mb-3 col-md-6">
																<label className="form-label" >Serial Number</label>
																<input type="text"  {...register("serialNumber", { required: true })} placeholder="Serial Number" className="form-control" />
																{errors.serialNumber?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>

														</div>
														<div className="row">
															<div className="form-group mb-3 col-md-6">

																<label className="form-label">Asset Name</label>
																<input type="text"  {...register("assetName", { required: true })} placeholder="Asset Name" className="form-control" />
																{errors.assetName?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Category</label>
																<select
																	className="form-control"
																	id="inputState"
																	{...register("category", { required: true })}
																	defaultValue=""

																>
																	<option value="">Choose...</option>
																	{categories?.map((category) => {
																		return (<>
																			<option value={`${category?._id}`}>{category?.categoryName}</option>
																		</>)
																	})}


																</select>
																{errors.category?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>

														</div>
														<div className="row">
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Unit</label>
																<select
																	className="form-control"
																	id="inputState"
																	{...register("unit", { required: true })}
																	defaultValue=""

																>
																	<option value="">Choose...</option>
																	{units?.map((unit) => {
																		return (<>
																			<option value={`${unit?._id}`}>{unit?.name}</option>
																		</>)
																	})}



																</select>
																{errors.unit?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Current (Milage/Hours)</label>
																<input type="text"  {...register("currentMilageHours", { required: true })} placeholder="Current (Milage/Hours)" className="form-control" />
																{errors.currentMilageHours?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>

														</div>

														<div className="row">
															<div className="form-group mb-3 col-md-6">
																<label className="form-label" >Location/Branch</label>
																<input type="text"  {...register("location", { required: true })} className="form-control" placeholder="Location/Branch" />
																{errors.location?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">State</label>
																<input type="text"  {...register("state", { required: true })} placeholder="State" className="form-control" />
																{errors.state?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>


														</div>


														<div className="row">

															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Division</label>
																<input type="text"  {...register("division", { required: true })} placeholder="Division" className="form-control" />
																{errors.division?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Condition</label>
																<select
																	className="form-control"
																	id="inputState"
																	{...register("condition", { required: true })}
																	defaultValue=""

																>
																	<option value="">Choose...</option>
																	{assetCondition?.map((condition) => {
																		return (<>
																			<option value={`${condition?._id}`}>{condition?.assetConditionType}</option>
																		</>)
																	})}



																</select>
																{errors.condition?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
														</div>

														<div className="row">
															<div className="form-group mb-3 col-md-3">
																<label className="form-label" htmlFor="year">Year</label>
																<select id="year" {...register("year", { required: true })} className="form-control" defaultValue="">
																	<option value="">Select year</option>
																	{Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i).map((year) => (
																		<option key={year} value={year}>{year}</option>
																	))}
																</select>
																{errors.year?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>

															<div className="form-group mb-3 col-md-3">
																<label className="form-label">Model</label>
																<input type="text"  {...register("model", { required: true })} placeholder="Model" className="form-control" />
																{errors.model?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Make</label>
																<input type="text"  {...register("make", { required: true })} className="form-control" placeholder="Make" />
																{errors.make?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>

														</div>

														<div className="row">
															<div className="form-group mb-3 col-md-12">
																<label className="form-label" htmlFor="generalNote">Note</label>
																<textarea id="generalNote" rows="4" style={{ height: "auto" }} {...register("generalNote")} placeholder="General Note" className="form-control" />
																{/* {errors.generalNote?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
															</div>
														</div>


														<div className="row">
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Do you want to add this asset in  </label>
																<div className="form-check custom-checkbox mb-3">
																	<input
																		type="checkbox"
																		className="form-check-input"
																		id="customCheckBox1"
																		{...register("isIncludedInDVIR")}
																	/>
																	<label
																		className="form-check-label"
																		htmlFor="customCheckBox1"
																	>
																		DVIR
																	</label>
																</div>
																<div className="form-check custom-checkbox mb-3">
																	<input
																		type="checkbox"
																		className="form-check-input"
																		id="customCheckBox1"
																		{...register("isIncludedInAudit")}
																	/>
																	<label
																		className="form-check-label"
																		htmlFor="customCheckBox1"
																	>
																		AUDIT
																	</label>
																</div>
															</div>
														</div>










														<div className="row">

															<h5 className="breadcrumb-item active text-primary" style={{ marginBottom: "30px", marginTop: "20px" }}>Financial Information</h5>

														</div>
														<div className="row">
															<div className="form-group mb-3 col-md-6">
																<label className="form-label" >Purchase Date</label>
																<input type="date"  {...register("dateOfPurchase", { required: true })} className="form-control" placeholder="Date Of Purchase" />
																{errors.dateOfPurchase?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
															{/* <div className="form-group mb-3 col-md-6">
																<label className="form-label" >Purchased Price</label>
																<input type="number"  {...register("price", { required: true })} className="form-control" placeholder="Purchased Price" />
																{errors.price?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div> */}
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Purchase Price</label>
																<div className="input-group">
																	<span className="input-group-text">$</span>
																	<input
																		type="number"
																		inputMode="numeric"
																		{...register("price", { required: true })}
																		className="form-control"
																		placeholder="Purchase Price"
																	/>
																</div>
																{errors.price?.type === "required" && (
																	<div style={{ color: "red" }}>This field is required</div>
																)}
															</div>

														</div>
														<div className="row">
															<div className="form-group mb-3 col-md-6">
																<label className="form-label" >Vendor</label>
																<input type="text"  {...register("vendor", { required: true })} className="form-control" placeholder="Vendor" />
																{errors.vendor?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Current Value</label>
																<input type="text"  {...register("currentValue", { required: true })} placeholder="Current Value" className="form-control" />
																{errors.currentValue?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
														</div>
														<div className="row">
															<div className="form-group mb-3 col-md-12">
																<label className="form-label" htmlFor="financialNote">Note</label>
																<textarea id="financialNote" rows="4" style={{ height: "auto" }} {...register("financialNote")} placeholder="Financial Note" className="form-control" />
																{/* {errors.financialNote?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
															</div>
														</div>
														<div className="row">
															<div className="form-control col-md-12" style={{ height: "7.5rem", display: "flex", alignItems: "center", gap: "3%", overflowX: "scroll", padding: "1rem" }}>

																{/* <img src={`http://localhost:5000/${category?.image}`} alt="" className="rounded profile-img me-4 mb-3" /> */}


																{files && files?.map((file, index) => {

																	return (<>

																		<div style={{ position: "relative" }}>
																			<img
																				src={file && URL.createObjectURL(file)}
																				alt=""
																				className="rounded profile-img me-4"
																				style={{ width: "65px", height: "65px" }}
																			/>
																			<span onClick={() => handleRemoveImage(file.name)}><i className="far fa-times-circle scale3" style={{ position: "absolute", right: "15px", fontSize: "16px" }}></i></span>
																		</div>
																	</>)
																})}

															</div>


														</div>

														<div className="row">
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Upload Asset Image</label>
																<input type="file" fileName="image" multiple='multiple'  {...register("image", { required: true })} onChange={handleImages} className="form-control" />
																{errors.image?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
														</div>
														<button className="btn btn-primary" type="submit">Add</button>
														<button className="btn btn-primary-outline" style={{ marginLeft: "10px" }} onClick={() => { history.push("/user-list") }}>Cancel</button>
														{isLoading && <BgSpinner />}
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AppProfile;
