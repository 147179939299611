import swal from 'sweetalert';
import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const UPDATE_LOGIN_DATA='[login action] update login'
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const Add_User_Id = '[add id] user id';


export function signupAction(formData,history) {
    return (dispatch) => {
        signUp(formData)
        .then((response) => {
            console.log(response);
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                history,
            );
            dispatch(confirmedSignupAction(response.data));
            history.push('/dashboard');
        })
        .catch((error) => {
            console.log(error);
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}
export function logoutWithoutPush(history) {
    localStorage.removeItem('companyDetails');
    return {
        type: LOGOUT_ACTION,
    };
}
export function logout(history) {
    localStorage.removeItem('companyDetails');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}
export function addUserID(id){
    console.log("calles ffsfef");
    return {
        type: Add_User_Id,
        payload: id,
    };
}
export function loginAction(email, password, history,setIsLoading) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
          setIsLoading(false)
                if(response?.data?.company?.isEmailVerified===false){
                   
                    // swal(response?.data?.message)
                    history.push(`/verify-otp/${response?.data?.company?._id}`)
                    return
                }
                saveTokenInLocalStorage({token:response.data.token,expiresIn:response.data.expiresIn,Id:response.data.company._id});
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn,
                    history,
                );
                dispatch(loginConfirmedAction(response.data));
				history.push('/dashboard');                
            })
            .catch((error) => {
				setIsLoading(false)
                const errorMessage = formatError(error?.response?.data);
                dispatch(loginFailedAction(errorMessage)); 
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}
export const updateCompany = (company) => {
    return {
      type: UPDATE_LOGIN_DATA,
      payload: company
    };
  };
export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
