import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import { Dropdown, Tab, Nav } from "react-bootstrap";
import { useSelector } from 'react-redux';

import axios from 'axios';
import swal from 'sweetalert';
import Spinner from '../../Loding/Sppinner';

const AssetHistory = () => {
	const auth = useSelector(state => state.auth)
	const history = useHistory()
	const { id } = useParams()

	const [assets, setAssets] = useState([])
	const [itemsPerPage, setItemsPerPage] = useState(5)
	const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)
	const [isLoading,setIsLoading]=useState(true)
	const getAllAssets = async () => {
		
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/checkedin/history/${id}`, { headers: { Authorization: `Bearer ${auth.auth.token}` }, params: { page: currentPage }  }).then((res) => {
			
			setAssets(res?.data?.assets)
			setTotalNumberOfRecords(res?.data?.totalCount)
			setIsLoading(false)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	useEffect(() => {
		getAllAssets();
	}, [])
	function dateFormat(date) {
		const month = date.getMonth();
		const day = date.getDate();
		const monthString = month >= 10 ? month : `0${month}`;
		const dayString = day >= 10 ? day : `0${day}`;
		return `${dayString}-${monthString}-${date.getFullYear()}`;
	}
	useEffect(() => {
		getAllAssets()
	}, [currentPage, setCurrentPage])

	const checkMoveable = (page) => {

		const max = (Math.ceil(totalNumberOfRecords / 5))
		if ((page + 1) <= max) {
			return true
		} else {
			return false
		}

	}
	const checkBackward = (page) => {
		console.log(page);
		if (page > 1) {
			return true
		} else {
			return false
		}
	}
	let paggination = Array(Math.ceil(totalNumberOfRecords / itemsPerPage))
		.fill()
		.map((_, i) => i + 1);
	const lowerBound = (currentPage - 1) * itemsPerPage + 1;
	const upperBound = Math.min(currentPage * itemsPerPage, totalNumberOfRecords);


	return (

		<>

			{isLoading?(<Spinner/>):(<Tab.Container defaultActiveKey="All">
				<div className="row">
					<div className="col-xl-12">

						<Tab.Content>
							<Tab.Pane eventKey="All">
								{assets?.length === 0 && <h4>No Asset History Found</h4>}
								<div className="table-responsive">
									<div id="example2_wrapper" className="dataTables_wrapper no-footer">
										<table
											id="example2"
											className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer"
										>
											<thead>
												<tr role="row">
													{/* <th className="sorting_asc bg-none" >
														<div className="form-check  style-1">
															<input type="checkbox" onClick={() => chackboxFun("all")} className="form-check-input" id="checkAll" required="" />
														</div>
													</th> */}
													<th style={{ textAlign: "center" }}>User</th>
													<th style={{ textAlign: "center" }}>Checked-Out<br />{`(Hours/Milage)`}</th>
													<th style={{ textAlign: "center" }}>Checked-Out At</th>
													<th style={{ textAlign: "center" }}>Checked-In<br />{`(Hours/Milage)`}</th>
													<th style={{ textAlign: "center" }}>Checked-In At</th>


												</tr>
											</thead>
											<tbody>
												{assets.map((asset) => {
													return (
														<>
															<tr role="row" className="odd" key={asset._id}>
																{/* <td className="sorting_1">
																	<div className="form-check  style-1">
																		<input type="checkbox" onClick={() => chackboxFun()} className="form-check-input"
																			id="customCheckBox2" required=""
																		/>
																	</div>
																</td> */}
																<td>
																	<div className="media-bx" >
																		<Link to={`/user-detail/${asset?.user?._id}`} className="text-black" style={{ display: "flex", alignItems: "center" }}>
																			<img className="me-3 rounded" src={`http://localhost:5000/${asset?.user?.profile}`} alt="" />
																			<div>

																				<h4 className="mb-0 mt-1">
																					{`${asset?.user?.name?.firstName} ${asset?.user?.name?.lastName}`}
																				</h4>

																			</div>
																		</Link>
																	</div>
																</td>
																<td>
																	<div style={{ textAlign: "center" }}>
																		<h5>{asset?.checkedOutUnitValue}</h5>

																	</div>
																</td>

																<td>
																	<div style={{ textAlign: "center" }}>
																		<h5>{`${dateFormat(new Date(asset?.checkedOutAt))}`}</h5>
																		<span className="fs-14">{new Date(asset?.checkedOutAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>


																	</div>
																</td>
																<td>
																	<div style={{ textAlign: "center" }}>
																		<h5>{asset?.checkedInUnitValue}</h5>

																	</div>
																</td>
																<td>
																	<div style={{ textAlign: "center" }}>
																		<h5>{`${dateFormat(new Date(asset?.checkedInAt))}`}</h5>
																		<span className="fs-14">{new Date(asset?.checkedInAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>

																	</div>
																</td>


															</tr>
														</>
													)
												})}


											</tbody>
										</table>
										<div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
											<div className="dataTables_info">
												Showing {lowerBound} to{" "}
												{upperBound} {" "}
												of {totalNumberOfRecords} entries
											</div>
											<div
												className="dataTables_paginate paging_simple_numbers mb-0"
												id="example2_paginate"
											>
												<Link
													className={`paginate_button previous ${(currentPage === 1) && "disabled"}`}
													onClick={() => {
														if (checkBackward(currentPage)) {
															setCurrentPage((pre) => pre - 1)
														}
													}}

												>
													<i className="fa fa-angle-double-left"></i> Previous
												</Link>
												<span>

													{paggination.map((number, i) => (

														<Link
															key={i}

															className={`paginate_button  ${currentPage === i + 1 ? "current" : ""
																} `}

														>
															{number}
														</Link>
													))}
												</span>
												<Link
													className={`paginate_button next ${(currentPage === ((Math.ceil(totalNumberOfRecords / 5)))) && "disabled"}`}

													onClick={() => {
														if (checkMoveable(currentPage)) {
															setCurrentPage((pre) => pre + 1)
														}
													}
													}
												>
													Next <i className="fa fa-angle-double-right"></i>
												</Link>
											</div>
										</div>
									</div>
								</div>
							</Tab.Pane>

						</Tab.Content>
					</div>
				</div>
			</Tab.Container>)}
		</>
	)
}
export default AssetHistory;