import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Tab, Nav } from "react-bootstrap";
import { useSelector } from 'react-redux';
///Import
import PendingBlog from './Guest/PendingBlog';
import BookedBlog from './Guest/BookedBlog';
import CanceledBlog from './Guest/CanceledBlog';
import RefundBlog from './Guest/RefundBlog';

//Images
import pic1 from '../../../images/avatar/1.jpg';
import pic2 from '../../../images/avatar/2.jpg';
import pic3 from '../../../images/avatar/3.jpg';
import pic4 from '../../../images/avatar/4.jpg';
import pic5 from '../../../images/avatar/5.jpg';
import pic6 from '../../../images/avatar/6.jpg';
import noImage from '../../../images/avatar/No_Image_Available.jpg';

import axios from 'axios';
import swal from 'sweetalert';
import { ToastContainer } from 'react-toastify';
import Spinner from '../Loding/Sppinner';


const DropdownBlog = ({ id }) => {
	const auth = useSelector(state => state.auth)
	const history = useHistory();
	const [seed, setSeed] = useState("")

	const handleDelete = async () => {
		axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/user/company/user/${id}`, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
			}
		}).then((res) => {
			swal(`${res?.data?.message}`)
			setSeed("1")
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})

	}
	useEffect(() => { setSeed("2") }, [seed, setSeed, handleDelete])
	return (
		<>
			<Dropdown className="dropdown">
				<Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu">
					<Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/user-profile-edit/${id}`) }}>Edit</Dropdown.Item>
					<Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/user-detail/${id}`) }}>View</Dropdown.Item>
					<Dropdown.Item className="dropdown-item" onClick={handleDelete}>Delete</Dropdown.Item>

				</Dropdown.Menu>
			</Dropdown>
		</>
	)
}

const GuestList = () => {
	const auth = useSelector(state => state.auth)
	const history = useHistory()
	const [categories, setCategories] = useState([])
	const [itemsPerPage, setItemsPerPage] = useState(10)
	const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0)
	const [currentPage, setCurrentPage] = useState(1);
	const [isLoading,setIsLoading]=useState(true)
	const [IsConfirm,setIsConfirm]=useState(false)
	const getAllCategories = async () => {
		
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/category/all`, { headers: { Authorization: `Bearer ${auth.auth.token}` }, params: { page: currentPage }  }).then((res) => {
			
			setCategories(res?.data?.categories)
			setTotalNumberOfRecords(res?.data?.totalCount)
			setIsLoading(false)
		}).catch((err) => {

			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	useEffect(() => {
		getAllCategories();
	}, [])
	// const handleDelete = async (id) => {
	// 	axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/category/delete/${id}`, {
	// 		headers: {
	// 			Authorization: `Bearer ${auth.auth.token}`,
	// 		}
	// 	}).then((res) => {
	// 		swal(`${res?.data?.message}`)
		
	// 		getAllCategories()
	// 	}).catch((err) => {
		
	// 		swal(`${err?.response?.data?.message}`)
	// 		if(err?.response?.status===401){
	// 			history.push("/login")
	// 			window.location.reload();
	// 		}
	// 	})

	// }
	const handleDeleteConfirm = async (id) => {
		console.log(IsConfirm,"conn");
		console.log("called");
		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/category/delete/${id}`,{isConfirm:true}, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
			}
		}).then((res) => {
			// console.log(auth.auth.token);
			console.log(res?.data,"confir");
			if(res?.data?.isConfirm===true){
				swal(`${res?.data?.message}`)
				getAllCategories()
			}else if(res?.data?.isConfirm===false){
				swal({
					title: `Are you sure?`,
					text: `${res?.data?.message} You won't be able to revert this!`,
					icon: "warning",
					buttons: ["Cancel", "Confirm"],
					dangerMode: true,
				  })
				  .then((result) => {
					console.log(result);
					if(result===null){
						setIsConfirm(false)
					  getAllCategories()
					}
					else if (result===true) {
						console.log("gererere");
						setIsConfirm(true)
					   handleDeleteConfirm(id)
					  
					} 
				  });
			}
			
			
		}).catch((err) => {
			console.log(err?.response?.data);
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})

	}
	const handleDelete = async (id) => {
		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/category/delete/${id}`,{isConfirm:IsConfirm}, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
			}
		}).then((res) => {
			if(res?.data?.isConfirm===true){
				swal(`${res?.data?.message}`)
				getAllCategories()
			}else if(res?.data?.isConfirm===false){
				swal({
					title: `Are you sure?`,
					text: `${res?.data?.message} You won't be able to revert this!`,
					icon: "warning",
					buttons: ["Cancel", "Confirm"],
					dangerMode: true,
				  })
				  .then((result) => {
					console.log(result);
					if(result===null){
						setIsConfirm(false)
					  getAllCategories()
					}
					else if (result===true) {
						console.log("gererere");
						setIsConfirm(true)
					   handleDeleteConfirm(id)
					  
					} 
				
				  });
			}
			
			
		}).catch((err) => {
			console.log("here");
			console.log(err?.response?.data);
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})

	}


	useEffect(() => {
		getAllCategories()
	}, [currentPage, setCurrentPage])

	const checkMoveable = (page) => {

		const max = (Math.ceil(totalNumberOfRecords / 5))
		if ((page + 1) <= max) {
			return true
		} else {
			return false
		}

	}
	const checkBackward = (page) => {
		console.log(page);
		if (page > 1) {
			return true
		} else {
			return false
		}
	}
	let paggination = Array(Math.ceil(totalNumberOfRecords / itemsPerPage))
		.fill()
		.map((_, i) => i + 1);
	const lowerBound = (currentPage - 1) * itemsPerPage + 1;
	const upperBound = Math.min(currentPage * itemsPerPage, totalNumberOfRecords);
	
	return (
		<>
		 <ToastContainer
               autoClose={3000}
            />
			{isLoading?(<Spinner/>):(<Tab.Container defaultActiveKey="All">
				<div className="row">
					<div className="col-xl-12">
						<div className="d-flex mb-4 justify-content-between align-items-center flex-wrap">
							<div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4">
								<Nav as="ul" className="nav nav-tabs">
									<Nav.Item as="li" className="nav-item">
										<Nav.Link className="nav-link" eventKey="All">All Categories</Nav.Link>
									</Nav.Item>
								</Nav>
							</div>
							<div className="table-search">
								<div className="input-group search-area mb-xxl-0 mb-4">
									<input type="text" className="form-control" placeholder="Search here" />
									<span className="input-group-text"><Link to={"#"}><i className="flaticon-381-search-2"></i></Link></span>
								</div>
							</div>
						</div>
						<div className="d-flex mb-4 justify-content-end align-items-center flex-wrap">
							<div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4">
								<Link to={"/add-category"} className="btn btn-primary">Add New</Link>
							</div>
						</div>
						<Tab.Content>

							<Tab.Pane eventKey="All">
								<div className="table-responsive">
									<div id="example2_wrapper" className="dataTables_wrapper no-footer">
										<table
											id="example2"
											className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer"
										>
											<thead>
												<tr role="row">

													<th className="sorting" style={{ textAlign: "center" }}>Category</th>
													<th className="sorting" style={{ textAlign: "center" }}>Description</th>
													<th style={{ textAlign: "center" }}></th>

													<th style={{ textAlign: "center" }}></th>

													<th style={{ textAlign: "center" }}></th>
													<th style={{ textAlign: "center" }}></th>
													<th style={{ textAlign: "center" }}></th>
													<th style={{ textAlign: "center" }}>Actions</th>


													{/* <th className="sorting">Address</th>
													<th className="sorting">Number</th>
													<th className="sorting">Status</th> */}
													{/* <th className="sorting bg-none"></th> */}
												</tr>
											</thead>
											<tbody>
												{categories.map((category) => {

													return (
														<>
															<tr role="row" className="odd" key={category._id}>

																<td>
																	<div className="media-bx" style={{ display: "flex", justifyContent: "center" }}>
																		<img className="me-3 rounded" src={(category?.image)?`${process.env.REACT_APP_BASE_URL}/${category?.image}`:noImage} alt="" />
																		<div>
																			<h4 className="mb-0 mt-1">
																				<Link to={`/category-detail/${category._id}`} className="text-black">{`${category.categoryName}`}</Link>
																			</h4>
																		</div>
																	</div>
																</td>
																<td>
																	<div style={{ textAlign: "center" }}>
																		<h5>{`${category.description.substr(0,50)}`}</h5>

																	</div>
																</td>
																<td>
																	<div>
																		<h5></h5>

																	</div>
																</td>
																<td>
																	<div>
																		<h5></h5>

																	</div>
																</td>
																<td>
																	<div>
																		<h5></h5>

																	</div>
																</td>
																<td>
																	<div>
																		<h5></h5>

																	</div>
																</td>
																<td>
																	<div>
																		<h5></h5>

																	</div>
																</td>
																{/* <td>
																	<div>
																		<h5>{`${category.address.street} ${category.address.city} ${category.address.country}`}</h5>

																	</div>
																</td>
																<td>
																	<div>
																		<h5>{category.number}</h5>

																	</div>
																</td>
																<td><span className={`text-${category.status === "active" ? "success" : "danger"}`}>{category.status}</span></td> */}
																<td>
																	<Dropdown className="dropdown">
																		<Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false" style={{ textAlign: "center" }}>
																			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																				<path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
																				<path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
																				<path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
																			</svg>
																		</Dropdown.Toggle>
																		<Dropdown.Menu className="dropdown-menu">
																			<Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/category-edit/${category._id}`) }}>Edit</Dropdown.Item>
																			<Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/category-detail/${category._id}`) }}>View</Dropdown.Item>
																			<Dropdown.Item className="dropdown-item" onClick={() => handleDelete(category._id)}>Delete</Dropdown.Item>

																		</Dropdown.Menu>
																	</Dropdown>
																</td>
															</tr>
														</>
													)
												})}


											</tbody>
										</table>
										<div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
											<div className="dataTables_info">
												Showing {lowerBound} to{" "}
												{upperBound} {" "}
												of {totalNumberOfRecords} entries
											</div>
											<div
												className="dataTables_paginate paging_simple_numbers mb-0"
												id="example2_paginate"
											>
												<Link
													className={`paginate_button previous ${(currentPage === 1) && "disabled"}`}
													onClick={() => {
														if (checkBackward(currentPage)) {
															setCurrentPage((pre) => pre - 1)
														}
													}}

												>
													<i className="fa fa-angle-double-left"></i> Previous
												</Link>
												<span>

													{paggination.map((number, i) => (

														<Link
															key={i}

															className={`paginate_button  ${currentPage === i + 1 ? "current" : ""
																} `}

														>
															{number}
														</Link>
													))}
												</span>
												<Link
													className={`paginate_button next ${(currentPage === ((Math.ceil(totalNumberOfRecords / 5)))) && "disabled"}`}

													onClick={() => {
														if (checkMoveable(currentPage)) {
															setCurrentPage((pre) => pre + 1)
														}
													}
													}
												>
													Next <i className="fa fa-angle-double-right"></i>
												</Link>
											</div>
										</div>
									</div>
								</div>
							</Tab.Pane>
							{/* <Tab.Pane eventKey="Pending">
								<PendingBlog />	
							</Tab.Pane>
							<Tab.Pane eventKey="Booked">
								<BookedBlog />
							</Tab.Pane>
							<Tab.Pane eventKey="Canceled">
								<CanceledBlog />
							</Tab.Pane>
							<Tab.Pane eventKey="Refund">
								<RefundBlog />
							</Tab.Pane> */}

						</Tab.Content>
					</div>
				</div>
			</Tab.Container>)}
		</>
	)
}
export { DropdownBlog };
export default GuestList;