export const isAuthenticated = (state) => {


    // const tokenDetailsString = localStorage.getItem('companyDetails');
    // if (tokenDetailsString) {
    //     let tokenDetails = JSON.parse(tokenDetailsString);
    //     if (Math.floor((new Date().getTime()) / 1000) > tokenDetails?.expiresIn) {
    //         return false
    //     } else {
    //         return true
    //     }
    // } else {
    //     return false
    // }

    // console.log("tokendsd",state.auth.auth.token);
    if (state.auth.auth.token) return true;
    return false;
};
