import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Tab, Nav } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { Badge } from "react-bootstrap";


import axios from 'axios';
import swal from 'sweetalert';
import { ToastContainer } from 'react-toastify';
import Spinner from '../Loding/Sppinner';



const GuestList = () => {
    const auth = useSelector(state => state.auth)
    const history = useHistory()

    const [assets, setAssets] = useState([])
    const [itemsPerPage, setItemsPerPage] = useState(10)
	const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0)
	const [currentPage, setCurrentPage] = useState(1);
    const [isLoading,setIsLoading]=useState(true)
    const getAllAssets = async () => {
       
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/available/all`, { headers: { Authorization: `Bearer ${auth.auth.token}` } , params: { page: currentPage } }).then((res) => {
            
            setAssets(res?.data?.assets)
            setTotalNumberOfRecords(res?.data?.totalCount)
            setIsLoading(false)
        }).catch((err) => {
            swal(`${err?.response?.data?.message}`);
            if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
        })
    }
    useEffect(() => {
        getAllAssets();
    }, [])

    function dateFormat(date) {
        const month = date.getMonth();
        const day = date.getDate();
        const monthString = month >= 10 ? month : `0${month}`;
        const dayString = day >= 10 ? day : `0${day}`;
        return `${date.getFullYear()}-${monthString}-${dayString}`;
    }

    useEffect(() => {
		getAllAssets()
	}, [currentPage, setCurrentPage])

	const checkMoveable = (page) => {

		const max = (Math.ceil(totalNumberOfRecords / 5))
		if ((page + 1) <= max) {
			return true
		} else {
			return false
		}

	}
	const checkBackward = (page) => {
		console.log(page);
		if (page > 1) {
			return true
		} else {
			return false
		}
	}
	let paggination = Array(Math.ceil(totalNumberOfRecords / itemsPerPage))
		.fill()
		.map((_, i) => i + 1);
	const lowerBound = (currentPage - 1) * itemsPerPage + 1;
	const upperBound = Math.min(currentPage * itemsPerPage, totalNumberOfRecords);

    return (
        <>
         <ToastContainer
               autoClose={3000}
            />
            {isLoading?(<Spinner/>):(<Tab.Container defaultActiveKey="All">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="d-flex mb-4 justify-content-between align-items-center flex-wrap">
                            <div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4">
                                <Nav as="ul" className="nav nav-tabs">
                                    <Nav.Item as="li" className="nav-item">
                                        <Nav.Link className="nav-link" eventKey="All">All Available Assets</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="table-search">
                                <div className="input-group search-area mb-xxl-0 mb-4">
                                    <input type="text" className="form-control" placeholder="Search here" />
                                    <span className="input-group-text"><Link to={"#"}><i className="flaticon-381-search-2"></i></Link></span>
                                </div>
                            </div>
                        </div>

                        <Tab.Content>

                            <Tab.Pane eventKey="All">
                                <div className="table-responsive">
                                    <div id="example2_wrapper" className="dataTables_wrapper no-footer">
                                    <table
											id="example2"
											className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer"
										>
											<thead>
												<tr role="row">
													{/* <th className="sorting_asc bg-none" >
														<div className="form-check  style-1">
															<input type="checkbox" onClick={() => chackboxFun("all")} className="form-check-input" id="checkAll" required="" />
														</div>
													</th> */}
													<th className="sorting" style={{ textAlign: "center" }}>Asset</th>
													<th className="sorting" style={{ textAlign: "center" }}>Category</th>
													<th className="sorting" style={{ textAlign: "center" }}>Make</th>
													<th className="sorting" style={{ textAlign: "center" }}>Model</th>
													<th className="sorting" style={{ textAlign: "center" }}>Location</th>

													<th className="sorting" style={{ textAlign: "center" }}>Status</th>
												

												</tr>
											</thead>
											<tbody>
												{assets.map((asset) => {
													return (
														<>
															<tr role="row" className="odd" key={asset._id}>
																{/* <td className="sorting_1">
																	<div className="form-check  style-1">
																		<input type="checkbox" onClick={() => chackboxFun()} className="form-check-input"
																			id="customCheckBox2" required=""
																		/>
																	</div>
																</td> */}
																<td>
																	<div className="media-bx" >
																		<Link to={`/asset-detail/${asset._id}`} className="text-black" style={{ display: "flex" }}>
																			<img className="me-3 rounded" src={`${process.env.REACT_APP_BASE_URL}/${asset?.images[0]?.url}`} alt="asset image" />
																			<div style={{display:"flex",alignItems:"center"}}>

																				<h4 className="mb-0 mt-1">
																					{`${asset?.assetName}`}
																				</h4>
																				{/* <span className="text-primary">{asset.s}</span> */}
																			</div>
																		</Link>
																	</div>
																</td>
																<td>
																	<Link to={`/category-detail/${asset?.category?._id}`}>
																	<div style={{ textAlign: "center" }}>
																		<h5>{asset?.category?.categoryName}</h5>
																	</div>
																	</Link>
																</td>

																<td>
																	<div style={{ textAlign: "center" }}>
																		<h5>{asset?.make}</h5>

																	</div>
																</td>
																<td>
																	<div style={{ textAlign: "center" }}>
																		<h5>{asset?.model}</h5>

																	</div>
																</td>

																<td>
																	<div style={{ textAlign: "center" }}>
																		<h5>{asset?.location}</h5>

																	</div>
																</td>
																<td style={{ textAlign: "center" }}>
																	<Badge variant={`${asset?.status === "checked-out" ? "danger" : ((asset?.status === "available") ? "success" : "warning")}`}>{asset?.status}</Badge>
																</td>
																
															</tr>
														</>
													)
												})}


											</tbody>
										</table>
                                        {/* <table
                                            id="example2"
                                            className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer"
                                        >
                                            <thead>
                                                <tr role="row">
                                                  
                                                    <th className="sorting" style={{ textAlign: "center" }}>Asset</th>
                                                    <th className="sorting" style={{ textAlign: "center" }}>Serial Number</th>
                                                    <th className="sorting" style={{ textAlign: "center" }}>Unit</th>
                                                    <th className="sorting" style={{ textAlign: "center" }}>Warranty Period</th>

                                                    <th className="sorting" style={{ textAlign: "center" }}>Status</th>
                                                    <th className="sorting" style={{ textAlign: "center" }}>Date Of Purchase</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {assets.map((asset) => {
                                                    return (
                                                        <>
                                                            <tr role="row" className="odd" key={asset._id}>
                                                               
                                                                <td>
                                                                    <div className="media-bx" >
                                                                        <Link to={`/asset-detail/${asset._id}`} className="text-black" style={{ display: "flex" }}>
                                                                            <img className="me-3 rounded" src={`${process.env.REACT_APP_BASE_URL}/${asset?.images[0]?.url}`} alt="" />
                                                                            <div>

                                                                                <h4 className="mb-0 mt-1">
                                                                                    {`${asset?.assetName}`}
                                                                                </h4>
                                                                                <span className="text-primary">{asset.assetCode}</span>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <h5>{asset?.serialNumber}</h5>

                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <h5>{asset?.unit?.name}</h5>

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <h5>{asset?.warrantyPeriod}</h5>

                                                                    </div>
                                                                </td>


                                                                <td style={{ textAlign: "center" }}>
                                                                    <Badge variant={`${asset?.status === "checked-out" ? "danger" : ((asset?.status === "available") ? "success" : "warning")}`}>{asset?.status}</Badge>


                                                                </td>
                                                                <td>
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <h5>{`${dateFormat(new Date(asset?.dateOfPurchase))}`}</h5>
                                                                        <span className="fs-14">{`${new Date(asset?.dateOfPurchase).toLocaleTimeString()}`}</span>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}


                                            </tbody>
                                        </table> */}
                                        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                                            <div className="dataTables_info">
                                                Showing {lowerBound} to{" "}
                                                {upperBound} {" "}
                                                of {totalNumberOfRecords} entries
                                            </div>
                                            <div
                                                className="dataTables_paginate paging_simple_numbers mb-0"
                                                id="example2_paginate"
                                            >
                                                <Link
                                                    className={`paginate_button previous ${(currentPage === 1) && "disabled"}`}
                                                    onClick={() => {
                                                        if (checkBackward(currentPage)) {
                                                            setCurrentPage((pre) => pre - 1)
                                                        }
                                                    }}

                                                >
                                                    <i className="fa fa-angle-double-left"></i> Previous
                                                </Link>
                                                <span>

                                                    {paggination.map((number, i) => (

                                                        <Link
                                                            key={i}

                                                            className={`paginate_button  ${currentPage === i + 1 ? "current" : ""
                                                                } `}

                                                        >
                                                            {number}
                                                        </Link>
                                                    ))}
                                                </span>
                                                <Link
                                                    className={`paginate_button next ${(currentPage === ((Math.ceil(totalNumberOfRecords / 5)))) && "disabled"}`}

                                                    onClick={() => {
                                                        if (checkMoveable(currentPage)) {
                                                            setCurrentPage((pre) => pre + 1)
                                                        }
                                                    }
                                                    }
                                                >
                                                    Next <i className="fa fa-angle-double-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="Pending">
								<PendingBlog />	
							</Tab.Pane>
							<Tab.Pane eventKey="Booked">
								<BookedBlog />
							</Tab.Pane>
							<Tab.Pane eventKey="Canceled">
								<CanceledBlog />
							</Tab.Pane>
							<Tab.Pane eventKey="Refund">
								<RefundBlog />
							</Tab.Pane> */}

                        </Tab.Content>
                    </div>
                </div>
            </Tab.Container>)}
        </>
    )
}

export default GuestList;