import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../Loding/Sppinner";
import BgSpinner from '../BackgroundSpinner/BgSpinner';

const AppProfile = () => {
	const history = useHistory()
	const [activeToggle, setActiveToggle] = useState("setting");
	const [isLoading, setIsLoading] = useState(true)
	const auth = useSelector(state => state.auth)
	const { id } = useParams();
	const [isLoading2, setIsLoading2] = useState(false)


	const getSingleUserDetail = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/user/company/user/${id}`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			
			setUserData(res?.data?.user)
			setFirstName(res?.data?.user?.name?.firstName)
			setLastName(res?.data?.user?.name?.lastName)
			setemailForPasswordRecovery(res?.data?.user?.emailForPasswordRecovery)
			setEmail(res?.data?.user?.email)
			setStreet(res?.data?.user?.address?.street)
			setZip(res?.data?.user?.address?.zip)
			setCity(res?.data?.user?.address?.city)
			setState(res?.data?.user?.address?.state)
			setPassword(res?.data?.user?.password)
			setNumber(res?.data?.user?.number)
			setStatus(res?.data?.user?.status)
			setFile(res?.data?.user?.image)
			setIsLoading(false);
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})
	}
	useEffect(() => { getSingleUserDetail() }, [])

	const [user, setUserData] = useState({})

	const [firstName, setFirstName] = useState(user?.name?.firstName);
	const [lastName, setLastName] = useState(user?.name?.lastName);
	const [email, setEmail] = useState(user?.email);
	const [emailForPasswordRecovery, setemailForPasswordRecovery] = useState(user?.emailForPasswordRecovery);
	const [street, setStreet] = useState(user?.address?.street);
	const [city, setCity] = useState(user?.address?.city);
	const [zip, setZip] = useState(user?.address?.zip);
	const [state, setState] = useState(user?.address?.state);
	const [number, setNumber] = useState(user?.number);
	const [status, setStatus] = useState(user?.status);
	const [password, setPassword] = useState(user?.password);

	const [file, setFile] = useState(user?.image);


	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();



	const handleUser = (data) => {
		setIsLoading2(true)

		data.image = file
		// console.log("newdata", data);
		let formData = new FormData()
		formData.append("firstName", data.firstName);
		formData.append("lastName", data.lastName);
		formData.append("email", data.email);
		formData.append("city", data.city);
		formData.append("street", data.street);
		formData.append("zip", data.zip);
		formData.append("state", data.state);
		formData.append("number", data.number);
		formData.append("status", data.status);
		formData.append("city", data.city);

		formData.append("image", data.image);

		// for (var pair of formData.entries()) {
		// 	console.log(pair[0] + ', ' + pair[1]);
		// }

		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/user/company/user/${id}`, formData, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
				'content-type': 'multipart/form-data'
			}
		}).then((res) => {
			showToastMessage(`${res?.data?.message}`)
			setIsLoading2(false)

			history.push("/user-list")
		}).catch((err) => {
			setIsLoading2(false)

			swal(`${err?.response?.data?.message}`)
			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})
		// dispatch(registerVehicle(data));

		// if (isSuccess) {
		//   reset({
		//     name: '', image: '', description: '', vehicleSeats: '', modelYear: '', perHourRate: '', perDayRate: '', noOfDoors: ''
		//     , vehicleType: '', brand: '', vehicleNumber: '', isAvailable: '', haveAc: '', isAvailableOnlyWithDriver: ''
		//   })

		// }
	};
	const showToastMessage = (msg) => {
		toast.success(`${msg}`, {
			position: toast.POSITION.BOTTOM_LEFT,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			closeButton: <i className="fa fa-times" aria-hidden="true"></i>,
			style: {
				background: "white",
				borderRadius: "5px",
				borderLeft: "10px solid green",
				color: "black",
				boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
			},
		});
	};
	return (
		<Fragment>
			<ToastContainer
				autoClose={3000}
			/>
			{/* <PageTitle activeMenu="Profile" motherMenu="App" /> */}
			{isLoading ? (<Spinner />) : (
				<>
					<h4 className="breadcrumb-item active text-primary">Edit User Details</h4>
					{/* <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={profile}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">Mitchell C. Shay</h4>
                    <p>UX / UI Designer</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">hello@email.com</h4>
                    <p>Email</p>
                  </div>
                  <Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-user-circle text-primary me-2" />
                        View profile
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-users text-primary me-2" />
                        Add to close friends
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-plus text-primary me-2" />
                        Add to group
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-ban text-primary me-2" />
                        Block
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
					<div className="row">
						<div className="col-xl-8">
							<div className="card">
								<div className="card-body">
									<div className="profile-tab">
										<div className="custom-tab-1">
											<div className="tab-content">
												<div id="profile-settings" className={`tab-pane fade ${activeToggle === "setting" ? "active show" : ""}`}>
													<div className="pt-3">
														<div className="settings-form">

															<form onSubmit={handleSubmit(handleUser)} enctype="multipart/form-data">

																<div className="row">
																	<div className="form-group mb-3 col-md-6">
																		<label className="form-label" >First Name</label>
																		<input type="first name"  {...register("firstName")} value={firstName} placeholder="text" onChange={(e) => { setFirstName(e.target.value) }} className="form-control" />
																		{/* {errors.firstName?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																	</div>
																	<div className="form-group mb-3 col-md-6">
																		<label className="form-label">Last Name</label>
																		<input type="last name"  {...register("lastName")} value={lastName} onChange={(e) => { setLastName(e.target.value) }} placeholder="text" className="form-control" />
																		{/* {errors.lastName?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																	</div>
																</div>

																<div className="row">
																	<div className="form-group mb-3 col-md-2">
																		<label className="form-label" >Street</label>
																		<input type="text"  {...register("street")} value={street} onChange={(e) => { setStreet(e.target.value) }} className="form-control" />
																		{/* {errors.street?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																	</div>
																	<div className="form-group mb-3 col-md-5">
																		<label className="form-label">City</label>
																		<input type="text"  {...register("city")} value={city} onChange={(e) => { setCity(e.target.value) }} className="form-control" />
																		{/* {errors.city?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																	</div>
																	<div className="form-group mb-3 col-md-3">
																		<label className="form-label">State</label>
																		<input type="text"  {...register("state")} value={state} onChange={(e) => { setState(e.target.value) }} className="form-control" />
																		{/* {errors.country?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																	</div>
																	<div className="form-group mb-3 col-md-2">
																		<label className="form-label">Zip</label>
																		<input type="text"  {...register("zip",)} value={zip} onChange={(e) => { setZip(e.target.value) }} className="form-control" />
																		{/* {errors.zip?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																	</div>
																	
																</div>
																<div className="row">
																	<div className="form-group mb-3 col-md-6">
																		<label className="form-label" >Email</label>
																		<input type="email"  {...register("email")} value={email} placeholder="Email" onChange={(e) => { setEmail(e.target.value) }} className="form-control" />
																		{/* {errors.email?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																	</div>
																	<div className="form-group mb-3 col-md-6">
																		<label className="form-label">Number</label>
																		<input type="text"  {...register("number")} value={number} onChange={(e) => { setNumber(e.target.value) }} placeholder="Number" className="form-control" />
																		{/* {errors.number?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																	</div>
																</div>


																<div className="row">
																	<div className="form-group mb-3 col-md-6">
																		<label className="form-label" >Password</label>
																		<input type="text"  {...register("password")} value={password} onChange={(e) => { setPassword(e.target.value) }} placeholder="password" className="form-control" />
																		{/* {errors.companyName?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																	</div>
																	<div className="form-group mb-3 col-md-4">
																		<label className="form-label">Status</label>
																		<select
																			className="form-control"
																			id="inputState"
																			{...register("status")}


																		>
																			<option >Choose...</option>
																			<option value="active" selected={(status === "active") ? true : false}>Active</option>
																			<option value="blocked" selected={(status === "blocked" ? true : false)}>Blocked</option>
																			{/* {errors.status?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}

																		</select>
																	</div>
																</div>

																<div className="row">
																	<div className="form-group mb-3 col-md-6">
																		<label className="form-label">Change Profile Picture</label>
																		<input type="file" fileName="image"  {...register("image")} onChange={(e) => { setFile(e.target.files[0]) }} className="form-control" />
																		{errors.image?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
																	</div>
																	<div className="form-group mb-3 col-md-6">
																		<label className="form-label">Email For Password Recovery</label>
																		<input type="email"  {...register("emailForPasswordRecovery")} onChange={(e) => { setemailForPasswordRecovery(e.target.value) }} value={emailForPasswordRecovery} className="form-control" disabled />
																		{/* {errors.image?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																	</div>
																</div>
																<button className="btn btn-primary" type="submit">Update</button>
																<button className="btn btn-primary-outline" style={{ marginLeft: "10px" }} onClick={() => { history.push("/user-list") }}>Cancel</button>
																{isLoading2&&<BgSpinner/>}
															</form>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</Fragment>
	);
};

export default AppProfile;
