import React from 'react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';


    function PrintButton({ imageUrl }) {
        const iframeRef = useRef(null);
      
        const handlePrint = () => {
          const printWindow = iframeRef.current.contentWindow;
          printWindow.document.write(`<img src="${imageUrl}" />`);
          printWindow.document.close();
          printWindow.focus();
          printWindow.print();
        };
      
        return (
          <div>
            <iframe ref={iframeRef} style={{ display: 'none' }} />
            
            <Link  onClick={handlePrint} className="btn btn-dark light btn-block">Print</Link>
          </div>
        );
      }

export default PrintButton;
