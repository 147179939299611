import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../Loding/Sppinner";
import BgSpinner from '../BackgroundSpinner/BgSpinner';

const AppProfile = () => {
	const history = useHistory()
	const [activeToggle, setActiveToggle] = useState("setting");
	const [units, setUnits] = useState([])
	const [categories, setCategories] = useState([])
	const [assetCondition, setAssetCondition] = useState([])
	let showFiles = [];
	const [isLoading2, setIsLoading2] = useState(false)


	const auth = useSelector(state => state.auth)
	const { id } = useParams();
	const getSingleAssetDetail = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/single/${id}`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

			setAssetInfo(res?.data?.asset)
			// setAssetCode(res?.data?.asset?.assetCode)
			setAssetName(res?.data?.asset?.assetName)
			setdivision(res?.data?.asset?.division)
			setPrice(res?.data?.asset?.price)
			setLocation(res?.data?.asset?.location)
			setCategory(res?.data?.asset?.category?._id)
			setUnit(res?.data?.asset?.unit?._id)
			setcondition(res?.data?.asset?.condition?._id)
			setvendor(res?.data?.asset?.vendor)
			setdateOfPurchase(res?.data?.asset?.dateOfPurchase)
			setStatus(res?.data?.asset?.status)
			setFiles(res?.data?.asset?.images)
			setfinancialNote(res?.data?.asset?.financialNote)
			setcurrentValue(res?.data?.asset?.currentValue)
			setassetCurrentUnitValue(res?.data?.asset?.assetCurrentUnitValue)
			setserialNumber(res?.data?.asset?.serialNumber)
			setstate(res?.data?.asset?.state)
			setyear(res?.data?.asset?.year)
			setmake(res?.data?.asset?.make)
			setmodel(res?.data?.asset?.model)
			setgeneralNote(res?.data?.asset?.generalNote)
			setisIncludedInAudit(res?.data?.asset?.isIncludedInAudit)
			setisIncludedInDVIR(res?.data?.asset?.isIncludedInDVIR)

			setIsLoading(false)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const getAllUnits = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/unit/all`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

			setUnits(res?.data?.units)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const getAllCategories = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/category/all`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

			setCategories(res?.data?.categories)

		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const getAllAssetConditionTypes = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/assetCondition/all`).then((res) => {
			setAssetCondition(res?.data?.assetConditionTypes)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})
	}
	useEffect(() => {
		getAllAssetConditionTypes()
		getAllCategories()
		getAllUnits()
		getSingleAssetDetail()
	}, [])

	const [asset, setAssetInfo] = useState({})

	// const [assetCode, setAssetCode] = useState(asset?.assetCode);
	const [assetName, setAssetName] = useState(asset?.assetName);
	const [division, setdivision] = useState(asset?.division);
	const [price, setPrice] = useState(asset?.price);
	const [category, setCategory] = useState(asset?.category?._id);
	const [isLoading, setIsLoading] = useState(true)
	const [unit, setUnit] = useState(asset?.unit?._id);
	const [condition, setcondition] = useState(asset?.condition?._id);
	const [location, setLocation] = useState(asset?.location);
	const [vendor, setvendor] = useState(asset?.vendor);
	const [dateOfPurchase, setdateOfPurchase] = useState(asset?.dateOfPurchase);
	const [financialNote, setfinancialNote] = useState(asset?.financialNote);
	const [currentValue, setcurrentValue] = useState(asset?.currentValue);
	const [assetCurrentUnitValue, setassetCurrentUnitValue] = useState(asset?.assetCurrentUnitValue);
	const [serialNumber, setserialNumber] = useState(asset?.serialNumber);
	const [state, setstate] = useState(asset?.state);
	const [year, setyear] = useState(asset?.year);
	const [make, setmake] = useState(asset?.make);
	const [model, setmodel] = useState(asset?.model);
	const [generalNote, setgeneralNote] = useState(asset?.generalNote);
	const [isIncludedInDVIR, setisIncludedInDVIR] = useState(asset?.isIncludedInDVIR);
	const [isIncludedInAudit, setisIncludedInAudit] = useState(asset?.isIncludedInAudit);

	const [status, setStatus] = useState(asset?.status);


	const [files, setFiles] = useState(asset?.images);


	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();



	const handleUser = (data) => {
		// console.log("files original",files);
		let formData = new FormData()
		setIsLoading2(true)
		if (Object.keys(files).length === 0) {
			setIsLoading2(false);
			return swal("please upload atleast one image")
		}
		if (Object.keys(files).length >4) {
			setIsLoading2(false);
			return swal("You only allowed to upload maximum 4 images")
		}
		files?.map((file) => {

			(file?.url) ? (formData.append("previousImages", JSON.stringify(file))) : (formData.append("image", file));
		})
		formData.append("isIncludedInDVIR", data.isIncludedInDVIR);
		formData.append("isIncludedInAudit", data.isIncludedInAudit);
		formData.append("assetCode", data.assetCode);
		formData.append("assetName", data.assetName);
		formData.append("serialNumber", data.serialNumber);
		formData.append("state", data.state);
		formData.append("price", data.price);
		formData.append("division", data.division);
		formData.append("location", data.location);
		formData.append("unit", data.unit);
		formData.append("category", data.category);
		formData.append("dateOfPurchase", data.dateOfPurchase);
		formData.append("condition", data.condition);
		formData.append("vendor", data.vendor)
		formData.append("financialNote", data.financialNote)
		formData.append("generalNote", data.generalNote)
		formData.append("currentValue", data.currentValue)
		formData.append("assetCurrentUnitValue", data.currentMilageHours)
		formData.append("year", data.year)
		formData.append("model", data.model)
		formData.append("make", data.make)

		for (var pair of formData.entries()) {
			console.log(pair[0] + ', ' + pair[1]);
		}
		// console.log("newdata", formData);
		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/update/${id}`, formData, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
				'content-type': 'multipart/form-data'
			}
		}).then((res) => {
			setIsLoading2(false)
			history.push("/asset-list")
			showToastMessage(`${res?.data?.message}`)
		}).catch((err) => {
			// console.log(err.response);
			swal(`${err?.response?.data?.message}`)
			setIsLoading2(false)
console.log(err?.response);
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})

	};
	const showToastMessage = (msg) => {
		toast.success(`${msg}`, {
			position: toast.POSITION.BOTTOM_LEFT,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			closeButton: <i className="fa fa-times" aria-hidden="true"></i>,
			style: {
				background: "white",
				borderRadius: "5px",
				borderLeft: "10px solid green",
				color: "black",
				boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
			},
		});
	};
	const handleImages = (e) => {
		// console.log(e.target.files);

		// const newItemInput = this.state.newItemInput;
		// const newRadioValue = this.state.selectedValue;
		// const obj = { 'item': newItemInput, 'columnType': newRadioValue };
		// const newArray = this.state.buyItems.slice(); // Create a copy
		// newArray.push(obj); // Push the object
		//this.setState({ buyItems: newArray });
		showFiles = files.slice();

		for (let i = 0; i < e.target.files.length; i++) {
			showFiles.push(e.target.files[i])
		}
		console.log("avx", files.length);
		if (showFiles.length >= 0) {
			setFiles(showFiles)
			console.log("avx", files.length);
		}
	}
	const handleRemoveImage = (index) => {
		// console.log("index",index);
		const updatedFiles = files.slice();
		// console.log("before",updatedFiles);
		delete updatedFiles[index];
		// console.log(updatedFiles,"updated");
		setFiles(updatedFiles)
		console.log("avx", files.length);

	}
	return (
		<Fragment>
			<ToastContainer
				autoClose={3000}
			/>
			{/* <PageTitle activeMenu="Profile" motherMenu="App" /> */}
			{isLoading ? (<Spinner />) : (<>
				<h4 className="breadcrumb-item active text-primary">Edit Asset Details</h4>

				<div className="row">
					<div className="col-xl-8">
						<div className="card">
							<div className="card-body">
								<div className="profile-tab">
									<div className="custom-tab-1">
										<div className="tab-content">
											<div id="profile-settings" className={`tab-pane fade ${activeToggle === "setting" ? "active show" : ""}`}>
												<div className="pt-3">
													<div className="settings-form">
														<h5 className="breadcrumb-item active text-primary" style={{ marginBottom: "30px" }}>General Information</h5>
														<form onSubmit={handleSubmit(handleUser)} enctype="multipart/form-data">

															<div className="row">
																{/* <div className="form-group mb-3 col-md-6">
																	<label className="form-label" >Asset Code</label>
																	<input type="text"  {...register("assetCode")} value={assetCode} className="form-control" />
																	
																</div> */}
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label" >Serial Number</label>
																	<input type="text"  {...register("serialNumber", { required: true })} defaultValue={serialNumber} className="form-control" />
																	{/* {errors.serialNumber?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>
															</div>
															<div className="row">
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">Asset Name</label>
																	<input type="text"  {...register("assetName")} defaultValue={assetName} className="form-control" />
																	{/* {errors.assetName?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>

																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">Category</label>
																	<select
																		className="form-control"
																		id="inputState"
																		{...register("category")}


																	>

																		{categories?.map((cat) => {
																			return (<>
																				<option value={`${cat?._id}`} selected={(cat._id === category) ? true : false}>{cat?.categoryName}</option>
																			</>)
																		})}


																	</select>
																	{/* {errors.category?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>

															</div>
															<div className="row">
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">Unit</label>
																	<select
																		className="form-control"
																		id="inputState"
																		{...register("unit")}


																	>

																		{units?.map((uni) => {
																			return (<>
																				<option value={`${uni?._id}`} selected={(uni._id === unit) ? true : false}>{uni?.name}</option>
																			</>)
																		})}

																		{/* {errors.status?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}

																	</select>
																</div>

																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">Current (Milage/Hours)</label>
																	<input type="text"  {...register("currentMilageHours", { required: true })} defaultValue={assetCurrentUnitValue} className="form-control" />
																	{/* {errors.currentMilageHours?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>
															</div>

															<div className="row">
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label" >Location/Branch</label>
																	<input type="text"  {...register("location")} className="form-control" defaultValue={location} />
																	{/* {errors.location?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">State</label>
																	<input type="text"  {...register("state", { required: true })} defaultValue={state} className="form-control" />
																	{/* {errors.state?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>

															</div>
															<div className="row">

																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">Division</label>
																	<input type="text"  {...register("division", { required: true })} defaultValue={division} className="form-control" />
																	{/* {errors.division?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">Condition</label>
																	<select
																		className="form-control"
																		id="inputState"
																		{...register("condition", { required: true })}>
																		{assetCondition?.map((con) => {
																			return (<>
																				<option value={`${con?._id}`} selected={(con._id === condition) ? true : false}>{con?.assetConditionType}</option>
																			</>)
																		})}
																	</select>
																	{/* {errors.condition?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>
															</div>

															<div className="row">
																<div className="form-group mb-3 col-md-3">
																	<label className="form-label" htmlFor="year">Year</label>
																	<select id="year" {...register("year", { required: true })} className="form-control"  defaultValue={year}>
																		<option value="">Select year</option>
																		{Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i).map((yea) => (
																			<option key={yea} value={yea} >{yea}</option>
																		))}
																	</select>
																	{/* {errors.year?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>

																<div className="form-group mb-3 col-md-3">
																	<label className="form-label">Model</label>
																	<input type="text"  {...register("model", { required: true })} defaultValue={model} className="form-control" />
																	{/* {errors.model?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">Make</label>
																	<input type="text"  {...register("make", { required: true })} className="form-control" defaultValue={make} />
																	{/* {errors.make?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>

															</div>

															<div className="row">
																<div className="form-group mb-3 col-md-12">
																	<label className="form-label" htmlFor="generalNote">Note</label>
																	<textarea id="generalNote" rows="4" style={{ height: "auto" }} {...register("generalNote")} defaultValue={generalNote} className="form-control" />
																	{/* {errors.generalNote?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>
															</div>

															<div className="row">
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">Do you want to add this asset in  </label>
																	<div className="form-check custom-checkbox mb-3">
																		<input
																			type="checkbox"
																			className="form-check-input"
																			id="customCheckBox1"
																			{...register("isIncludedInDVIR")}
																			defaultChecked={isIncludedInDVIR}
																		/>
																		<label
																			className="form-check-label"
																			htmlFor="customCheckBox1"
																		>
																			DVIR
																		</label>
																	</div>
																	<div className="form-check custom-checkbox mb-3">
																		<input
																			type="checkbox"
																			className="form-check-input"
																			id="customCheckBox1"
																			{...register("isIncludedInAudit")}
																			defaultChecked={isIncludedInAudit}
																		/>
																		<label
																			className="form-check-label"
																			htmlFor="customCheckBox1"
																		>
																			AUDIT
																		</label>
																	</div>
																</div>
															</div>


															<div className="row">
																<div className="form-control col-md-12" style={{ height: "7.5rem", display: "flex", alignItems: "center", gap: "3%", overflowX: "scroll", padding: "1rem" }}>

																	{/* <img src={`${process.env.REACT_APP_BASE_URL}/${category?.image}`} alt="" className="rounded profile-img me-4 mb-3" /> */}


																	{files && files?.map((file, index) => {

																		return (<>

																			<div style={{ position: "relative" }}>
																				<img
																					src={file.name ? URL.createObjectURL(file) : `${process.env.REACT_APP_BASE_URL}/${file.url}`}
																					alt=""
																					className="rounded profile-img me-4"
																					style={{ width: "65px", height: "65px" }}
																				/>
																				<span onClick={() => handleRemoveImage(index)}><i className="far fa-times-circle scale3" style={{ position: "absolute", right: "15px", fontSize: "16px" }}></i></span>
																			</div>
																		</>)
																	})}

																</div>


															</div>

															<div className="row">
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">Upload Asset Image</label>
																	<input type="file" fileName="image" multiple='multiple'  {...register("image")} onChange={handleImages} className="form-control" />
																	{errors.image?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
																</div>
															</div>

															<div className="row">

																<h5 className="breadcrumb-item active text-primary" style={{ marginBottom: "30px", marginTop: "20px" }}>Financial Information</h5>

															</div>
															<div className="row">

																<div className="form-group mb-3 col-md-6">
																	<label className="form-label" >Date Of Purchase</label>
																	<input type="date"  {...register("dateOfPurchase")} className="form-control"   defaultValue={new Date(dateOfPurchase).toISOString().substring(0, 10)}/>
																	{/* {errors.dateOfPurchase?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>
																{console.log(dateOfPurchase)}
																{/* <div className="form-group mb-3 col-md-6">
																	<label className="form-label" >Purchased Price</label>
																	<input type="text"  {...register("price", { required: true })} className="form-control" value={price} />
																	{errors.price?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
																</div> */}
																<div className="form-group mb-3 col-md-6">
																<label className="form-label">Purchase Price</label>
																<div className="input-group">
																	<span className="input-group-text">$</span>
																	<input
																		type="number"
																		inputMode="numeric"
																		{...register("price", { required: true })}
																		className="form-control"
																		placeholder="Purchase Price"
																		defaultValue={price}
																	/>
																</div>
																{errors.price?.type === "required" && (
																	<div style={{ color: "red" }}>This field is required</div>
																)}
															</div>
															</div>

															<div className="row">
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label" >Vendor</label>
																	<input type="text"  {...register("vendor", { required: true })} className="form-control" defaultValue={vendor} />
																	{/* {errors.vendor?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">Current Value</label>
																	<input type="text"  {...register("currentValue", { required: true })} defaultValue={currentValue} className="form-control" />
																	{/* {errors.currentValue?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>
															</div>
															<div className="row">
																<div className="form-group mb-3 col-md-12">
																	<label className="form-label" htmlFor="financialNote">Note</label>
																	<textarea id="financialNote" rows="4" style={{ height: "auto" }} {...register("financialNote")} defaultValue={financialNote} className="form-control" />
																	{/* {errors.financialNote?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>
															</div>
															<button className="btn btn-primary" type="submit">Update</button>
															<button className="btn btn-primary-outline" style={{ marginLeft: "10px" }} onClick={() => { history.push("/asset-list") }}>Cancel</button>
															{isLoading2 && <BgSpinner />}

														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>)}
		</Fragment>
	);
};

export default AppProfile;
