import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Tab, Nav } from "react-bootstrap";
import { useSelector } from 'react-redux';


//Images

import axios from 'axios';
import swal from 'sweetalert';
import { Badge } from "react-bootstrap";
import Alert from '../Alert/Alert';
import { toast, ToastContainer } from 'react-toastify';
import Spinner from '../Loding/Sppinner';


const DropdownBlog = ({ id }) => {
    const auth = useSelector(state => state.auth)
    const history = useHistory();
    const [seed, setSeed] = useState("")

    const handleDelete = async () => {
        axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/user/company/user/${id}`, {
            headers: {
                Authorization: `Bearer ${auth.auth.token}`,
            }
        }).then((res) => {
            swal(`${res?.data?.message}`)
            setSeed("1")
        }).catch((err) => {
            swal(`${err?.response?.data?.message}`)
        })

    }
    useEffect(() => { setSeed("2") }, [seed, setSeed, handleDelete])
    return (
        <>
            <Dropdown className="dropdown">
                <Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu">
                    <Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/user-profile-edit/${id}`) }}>Edit</Dropdown.Item>
                    <Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/user-detail/${id}`) }}>View</Dropdown.Item>
                    <Dropdown.Item className="dropdown-item" onClick={handleDelete}>Delete</Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

const InComplete = () => {
    const auth = useSelector(state => state.auth)
    const history = useHistory()
    console.log(auth);
    const [data, setData] = useState([]);
    const [assets, setAssets] = useState([])
	const [itemsPerPage, setItemsPerPage] = useState(10)
	const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0)
	const [currentPage, setCurrentPage] = useState(1);
    const [isLoading,setIsLoading]=useState(true)
    const getAllAssets = async () => {

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/service/all/incomplete`, { headers: { Authorization: `Bearer ${auth.auth.token}` }, params: { page: currentPage }  }).then((res) => {
            setTotalNumberOfRecords(res?.data?.totalCount)
            setAssets(res?.data?.assets)
            setIsLoading(false)
        }).catch((err) => {
            swal(`${err?.response?.data?.message}`);
            swal(`${err?.response?.data?.message}`)
            if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
            // history.push("/page-login")
        })
    }
    useEffect(() => {
        getAllAssets();
    }, [])

    const [alert, setAlert] = useState(null);
    const showAlert = (message, type) => {
        setAlert({
            message,
            type
        })
        setTimeout(() => {
            setAlert(null)
        }, 2000)
    }
    

	useEffect(() => {
		getAllAssets()
	}, [currentPage, setCurrentPage])

	const checkMoveable = (page) => {

		const max = (Math.ceil(totalNumberOfRecords / 5))
		if ((page + 1) <= max) {
			return true
		} else {
			return false
		}

	}
	const checkBackward = (page) => {
		console.log(page);
		if (page > 1) {
			return true
		} else {
			return false
		}
	}
	let paggination = Array(Math.ceil(totalNumberOfRecords / itemsPerPage))
		.fill()
		.map((_, i) => i + 1);
	const lowerBound = (currentPage - 1) * itemsPerPage + 1;
	const upperBound = Math.min(currentPage * itemsPerPage, totalNumberOfRecords);
    const showToastMessage = (msg) => {    
        toast.success(`${msg}`, {
            position: toast.POSITION.BOTTOM_LEFT,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            closeButton: <i className="fa fa-times" aria-hidden="true"></i>,
            style: {
              background: "white",
              borderRadius:"5px",
              borderLeft:"10px solid green",
              color:"black",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            },
          });
    };
    return (
        <>
         <ToastContainer
               autoClose={3000}
            />
           {isLoading?(<Spinner/>):( <Tab.Container defaultActiveKey="All">
                <div className="row">
                    <div className="col-xl-12">
                        <Alert alert={alert} />
                        <div className="d-flex mb-4 justify-content-between align-items-center flex-wrap">
                            <div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4">
                                <Nav as="ul" className="nav nav-tabs">
                                    <Nav.Item as="li" className="nav-item">
                                        <Nav.Link className="nav-link" eventKey="All">Services</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="table-search">
                                <div className="input-group search-area mb-xxl-0 mb-4">
                                    <input type="text" className="form-control" placeholder="Search here" />
                                    <span className="input-group-text"><Link to={"#"}><i className="flaticon-381-search-2"></i></Link></span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="d-flex mb-4 justify-content-end align-items-center flex-wrap">
							<div className="card-tabs mt-3 mt-sm-0 mb-xxl-0 mb-4">
								<Link to={"/add-asset"} className="btn btn-danger btn-block">Add New</Link>
							</div>
						</div> */}
                        <Tab.Content>

                            <Tab.Pane eventKey="All">
                                <div className="table-responsive">
                                    <div id="example2_wrapper" className="dataTables_wrapper no-footer">
                                        <table
                                            id="example2"
                                            className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer"
                                        >
                                            <thead>
                                                <tr role="row">
                                                    {/* <th className="sorting_asc bg-none" >
														<div className="form-check  style-1">
															<input type="checkbox" onClick={() => chackboxFun("all")} className="form-check-input" id="checkAll" required="" />
														</div>
													</th> */}
                                                    <th className="sorting" style={{ textAlign: "center" }}>Asset</th>
                                                    <th className="sorting" style={{ textAlign: "center" }}>Requested By</th>

                                                    <th className="sorting" style={{ textAlign: "center" }}>Service Type</th>
                                                    <th className="sorting" style={{ textAlign: "center" }}>Title</th>
                                                    <th className="sorting" style={{ textAlign: "center" }}>Status</th>
                                                    <th className="sorting" style={{ textAlign: "center" }}>Requested At</th>
                                                    <th style={{ textAlign: "center" }}>Actions</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {assets.map((asset) => {
                                                    return (
                                                        <>
                                                            <tr role="row" className="odd" key={asset._id}>

                                                                <td>
                                                                    <div className="media-bx" >
                                                                        <Link to={`/service-detail/${asset._id}`} className="text-black" style={{ display: "flex", alignItems: "center" }}>
                                                                            <img className="me-3 rounded" src={`${process.env.REACT_APP_BASE_URL}/${asset?.asset?.images[0]?.url}`} alt="" />
                                                                            <div style={{display:"flex",alignItems:"center"}}>

                                                                                <h4 className="mb-0 mt-1">
                                                                                    {`${asset?.asset?.assetName}`}
                                                                                </h4>
                                                                                {/* <span className="text-primary">{asset?.asset?.assetCode}</span> */}

                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                                
                                                                <td>
                                                                    <div className="media-bx" style={{ display: "flex", justifyContent: "center" }}>
                                                                        <img className="me-3 rounded" src={`${process.env.REACT_APP_BASE_URL}/${asset?.user?.profile}`} alt="" />
                                                                        <div>
                                                                            <h4 className="mb-0 mt-1">
                                                                                <Link to={`/service-detail/${asset._id}`} className="text-black">{`${asset?.user?.name?.firstName} ${asset?.user?.name?.lastName}`}</Link>
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                </td>



                                                                <td>
                                                                    <div style={{ textAlign: "center" }}>

                                                                        <h5>{asset?.serviceType?.type}</h5>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <h5>{asset?.title.toString().toLowerCase().replace(/(^|\s)\S/g, L => L.toUpperCase())}</h5>

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div style={{ textAlign: "center" }}>

                                                                    <Badge variant={`${asset?.status === "pending" ? "primary" : ((asset?.status==="completed")?"success":((asset?.status==="in complete")?"warning":((asset?.status==="reject")?"danger":((asset?.status==="in progress")&&"info"))))} `}>{asset?.status}</Badge>

                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <h5>{asset?.date}</h5>
                                                                        <span className="fs-14">{asset?.time}</span>


                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {/* <Dropdown className="dropdown">
        <Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" style={{ textAlign: "center" }} aria-expanded="false">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu">
            <Dropdown.Item className="dropdown-item" onClick={() => {
                axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/service/update/${asset?._id}`, { _id: asset?.asset?._id, status: ((asset?.status === "pending") ? "in progress" : ((asset?.status === "in progress") ? "complete" : ((asset?.status === "in complete") ? "complete" : ((asset?.status === "complete") ? "" : ((asset?.status === "reject") && ""))))) }, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

                    swal(`${res?.data?.message}`)
                    getAllAssets();
                }).catch((err) => {
                    swal(`${err?.response?.data?.message}`)
                })
            }}>
                {((asset?.status === "pending") ? "approve request" : ((asset?.status === "in progress") ? "complete" : ((asset?.status === "in complete") ? "complete" : ((asset?.status === "complete") ? "" : ((asset?.status === "reject") && "")))))}

            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" onClick={() => {
                axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/service/update/${asset?._id}`, { _id: asset?.asset?._id, status: ((asset?.status === "pending") ? "reject" : ((asset?.status === "in progress") && "in complete")) }, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

                    swal(`${res?.data?.message}`)
                    getAllAssets();
                }).catch((err) => {
                    swal(`${err?.response?.data?.message}`)
                })
            }}>
                {((asset?.status === "pending") ? "reject requet" : ((asset?.status === "in progress") ? "in complete" : ((asset?.status === "in complete") ? "" : ((asset?.status === "reject") && ""))))}

            </Dropdown.Item>
            <Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/service-detail/${asset._id}`) }}>View</Dropdown.Item>


        </Dropdown.Menu>
    </Dropdown> */}










                                                                    {(asset?.status === "pending") ? <Dropdown className="dropdown">
                                                                        <Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" style={{ textAlign: "center" }} aria-expanded="false">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu">
                                                                            <Dropdown.Item className="dropdown-item" onClick={() => {
                                                                                axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/service/update/${asset?._id}`, { _id: asset?.asset?._id, status: "in progress" }, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

                                                                                showToastMessage("Asset Status Updated")
                                                                                    getAllAssets();
                                                                                }).catch((err) => {
                                                                                    swal(`${err?.response?.data?.message}`)
                                                                                })
                                                                            }}>
                                                                                approve request

                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item className="dropdown-item" onClick={() => {
                                                                                axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/service/update/${asset?._id}`, { _id: asset?.asset?._id, status: "reject" }, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

                                                                                showToastMessage("Asset Status Updated")
                                                                                    getAllAssets();
                                                                                }).catch((err) => {
                                                                                    swal(`${err?.response?.data?.message}`)
                                                                                })
                                                                            }}>
                                                                                reject request

                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/service-detail/${asset._id}`) }}>View</Dropdown.Item>

                                                                        </Dropdown.Menu>
                                                                    </Dropdown> : ((asset?.status === "in progress") ? <Dropdown className="dropdown">
                                                                        <Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" style={{ textAlign: "center" }} aria-expanded="false">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu">

                                                                            <Dropdown.Item className="dropdown-item" onClick={() => {
                                                                                axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/service/update/${asset?._id}`, { _id: asset?.asset?._id, status: "completed" }, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

                                                                                showToastMessage("Asset Status Updated")
                                                                                    getAllAssets();
                                                                                }).catch((err) => {
                                                                                    swal(`${err?.response?.data?.message}`)
                                                                                })
                                                                            }}>
                                                                                completed

                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item className="dropdown-item" onClick={() => {
                                                                                axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/service/update/${asset?._id}`, { _id: asset?.asset?._id, status: "in complete" }, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

                                                                                showToastMessage("Asset Status Updated")
                                                                                    getAllAssets();
                                                                                }).catch((err) => {
                                                                                    swal(`${err?.response?.data?.message}`)
                                                                                })
                                                                            }}>
                                                                                in complete

                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/service-detail/${asset._id}`) }}>View</Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown> : (( (asset?.status === "complete") || (asset?.status === "reject"))?<Dropdown className="dropdown">
                                                                        <Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" style={{ textAlign: "center" }} aria-expanded="false">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu">
                                                                            <Dropdown.Item className="dropdown-item" onClick={() => { history.push(`/service-detail/${asset._id}`) }}>View</Dropdown.Item>

                                                                        </Dropdown.Menu>
                                                                    </Dropdown>:((asset?.status==="in complete")&&<Dropdown className="dropdown">
                                                                        <Dropdown.Toggle as="div" className="btn-link i-false" data-bs-toggle="dropdown" style={{ textAlign: "center" }} aria-expanded="false">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#262626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu">
                                                                            <Dropdown.Item className="dropdown-item" id="toastr-success-bottom-left" onClick={() => {
                                                                                axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/service/update/${asset?._id}`, { _id: asset?.asset?._id, status: "in progress" }, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {


                                                                                    showToastMessage("Asset Status Updated")
                                                                                    // showAlert(`${res?.data?.message}`, "success")
                                                                                    getAllAssets();
                                                                                }).catch((err) => {
                                                                                    swal(`${err?.response?.data?.message}`)
                                                                                })
                                                                            }}>

                                                                               in progress

                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item className="dropdown-item" onClick={() => {
                                                                                axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/service/update/${asset?._id}`, { _id: asset?.asset?._id, status: "reject" }, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
                                                                                    showToastMessage("Asset Status Updated")
                                                                                    // showAlert(`${res?.data?.message}`, "success")
                                                                                    getAllAssets();
                                                                                }).catch((err) => {
                                                                                    swal(`${err?.response?.data?.message}`)
                                                                                })
                                                                            }}>
                                                                                reject 

                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item className="dropdown-item" onClick={() => {
                                                                                axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/service/update/${asset?._id}`, { _id: asset?.asset?._id, status: "completed" }, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
                                                                                    showToastMessage("Asset Status Updated")
                                                                                    // showAlert(`${res?.data?.message}`, "success")
                                                                                    getAllAssets();
                                                                                }).catch((err) => {
                                                                                    swal(`${err?.response?.data?.message}`)
                                                                                })
                                                                            }}>
                                                                                completed 

                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>)))
                                                                    }
                                                                </td>

                                                            </tr>
                                                        </>
                                                    )
                                                })}


                                            </tbody>
                                        </table>
                                        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
								<div className="dataTables_info">
									Showing {lowerBound} to{" "}
									{upperBound} {" "}
									of {totalNumberOfRecords} entries
								</div>
								<div
									className="dataTables_paginate paging_simple_numbers mb-0"
									id="example2_paginate"
								>
									<Link
										className={`paginate_button previous ${(currentPage === 1) && "disabled"}`}
										onClick={() => {
											if (checkBackward(currentPage)) {
												setCurrentPage((pre) => pre - 1)
											}
										}}

									>
										<i className="fa fa-angle-double-left"></i> Previous
									</Link>
									<span>

										{paggination.map((number, i) => (

											<Link
												key={i}

												className={`paginate_button  ${currentPage === i + 1 ? "current" : ""
													} `}

											>
												{number}
											</Link>
										))}
									</span>
									<Link
										className={`paginate_button next ${(currentPage === ((Math.ceil(totalNumberOfRecords / 5)))) && "disabled"}`}

										onClick={() => {
											if (checkMoveable(currentPage)) {
												setCurrentPage((pre) => pre + 1)
											}
										}
										}
									>
										Next <i className="fa fa-angle-double-right"></i>
									</Link>
								</div>
							</div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="Pending">
								<PendingBlog />	
							</Tab.Pane>
							<Tab.Pane eventKey="Booked">
								<BookedBlog />
							</Tab.Pane>
							<Tab.Pane eventKey="Canceled">
								<CanceledBlog />
							</Tab.Pane>
							<Tab.Pane eventKey="Refund">
								<RefundBlog />
							</Tab.Pane> */}

                        </Tab.Content>
                    </div>
                </div>
            </Tab.Container>)}
        </>
    )
}
export { DropdownBlog };
export default InComplete;