import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ReactApexChart from "react-apexcharts";
///Images
import pic1 from './../../../images/avatar/1.jpg';
import pic2 from './../../../images/avatar/2.jpg';
import pic3 from './../../../images/avatar/3.jpg';
import pic4 from './../../../images/avatar/4.jpg';


//Import
import { ThemeContext } from "../../../context/ThemeContext";
import RadialDount from './Dashboard/RadialDount';
import ReservationChart from './Dashboard/ReservationChart';
import LatestCustomer from './Dashboard/LatestCustomer';
import swal from 'sweetalert';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { DynamicStar } from 'react-dynamic-star';
import { ToastContainer } from 'react-toastify';

const AnalyticsDonut = loadable(() =>
	pMinDelay(import("./Dashboard/AnalyticsDonut"), 1000)
);


const Home = () => {
	const auth = useSelector(state => state.auth)
	const [isLoading,setIsLoading]=useState(true)
	const options = {
		chart: {
			type: 'radialBar',
			height: 150,
			sparkline: {
				enabled: true
			}
		},

		colors: ['var(--primary)'],
		plotOptions: {
			radialBar: {
				hollow: {
					size: '35%',
				},
				dataLabels: {
					show: false,
				}
			},
		},
		labels: [''],

	}
const history=useHistory()



	const graphOptions = {
		chart: {
			height: 400,
			type: 'area',
			toolbar: {
				show: false
			}
		},
		colors: ["#07ed16", "#FF6E5A", "#1362FC"],
		dataLabels: {
			enabled: false
		},
		stroke: {
			width: 6,
			curve: 'smooth',
		},
		legend: {
			show: false
		},
		grid: {
			borderColor: '#EBEBEB',
			strokeDashArray: 6,
		},
		markers: {
			strokeWidth: 6,
			hover: {
				size: 15,
			}
		},
		yaxis: {
			labels: {
				offsetX: -12,
				style: {
					colors: '#787878',
					fontSize: '13px',
					fontFamily: 'Poppins',
					fontWeight: 400

				}
			},
		},
		xaxis: {
			categories: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
			labels: {
				style: {
					colors: '#787878',
					fontSize: '13px',
					fontFamily: 'Poppins',
					fontWeight: 400

				},
			}
		},
		fill: {
			type: "solid",
			opacity: 0.1
		},
		tooltip: {
			x: {
				format: 'dd/MM/yy HH:mm'
			},
		},
	}
	const [totalAssets, setTotalAssets] = useState()
	const [latestAudit, setLatestAudit] = useState([])
	const [latestDVIR, setLatestDVIR] = useState([])

	const [totalCompletedServiceAssets, setTotalCompletedServiceAssets] = useState()
	const [totalInCompletedServiceAssets, setTotalInCompletedServiceAssets] = useState()

	const [totalRejecedServiceAssets, setTotalRejecedServiceAssets] = useState()



	let arr = []
	const [totalAvailableAssets, setTotalAvailableAssets] = useState()
	const [totalshowAssets, seTtotalshowAssets] = useState([8])
	const [totalCheckedOutAssets, setTotalCheckedOutAssets] = useState()
	const [totalCategories, setTotalCategories] = useState()
	const [totalDvir, setTotalDvir] = useState()
	const [totalAudits, setTotalAudits] = useState()
	const [totalPendingServiceRequests, setTotalPendingServiceRequests] = useState()
	const [totalInProgressRequests, setTotalInProgressRequests] = useState()
	const [totalCompletedRequests, setTotalCompletedRequests] = useState()
	const [totalInCompletedRequests, setTotalInCompletedRequests] = useState()
	const [totalRejectedRequests, setTotalRejectedRequests] = useState()

	const getTotalAssets = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/total`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			setTotalAssets(res?.data?.totalAssets)
		
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}

	const getLatestDvir = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/dvir/latest`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

			setLatestDVIR(res?.data?.assets)

		}).catch((err) => {
			
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}

		})
	}

	const getLatestAudits = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/audit/latest`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			setLatestAudit(res?.data?.assets)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const getTotalAvailableAssets = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/asset/total/available`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			setTotalAvailableAssets(res?.data?.totalAvailableAssets)

		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const gettotalCheckedOutAssets = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/checkedout/total`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			setTotalCheckedOutAssets(res?.data?.totalCheckedOutAssets)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}

	// const gettotalCheckedOutAssetsCountByMonth = async () => {
	// 	axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/checkedin/all/checkedout/count/month`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
	// 		setTotalCheckedOutCountbByMonth(res?.data?.assets[0]?.data)
	// 		// Object.values(totalCheckedOutCountbByMonth).map((obj) => {
	// 		// 	checkedOut.push(obj)
	// 		// })
	// 	}).catch((err) => {
	// 		swal(`${err?.response?.data?.message}`);
	// 	})
	// }

	const getTotalCompletedServiceAssets = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/service/all/completed/count/month`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			if (res?.data?.assets?.length === 0) {
				let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
				setTotalCompletedServiceAssets(data)
			} else {
				setTotalCompletedServiceAssets(res?.data?.assets[0]?.data)
			}

		}).catch((err) => {
			
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const getTotalRejectedServiceAssets = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/service/all/rejected/count/month`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			if (res?.data?.assets?.length === 0) {
				let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
				setTotalRejecedServiceAssets(data)
			} else {
				setTotalRejecedServiceAssets(res?.data?.assets[0]?.data)
			}

		}).catch((err) => {
		
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const getTotalInCompletedServiceAssets = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/service/all/incomplete/count/month`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			if (res?.data?.assets?.length === 0) {
				let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
				setTotalInCompletedServiceAssets(data)
			} else {
				setTotalInCompletedServiceAssets(res?.data?.assets[0]?.data)
			}

		}).catch((err) => {
		
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}

	const getTotalCompletedServiceAssetsArray = () => {
		if (totalCompletedServiceAssets) {
			let checkedIn = Object.values(totalCompletedServiceAssets).map((obj) => {
				return obj
			})
			return checkedIn
		}

	}
	const getTotalInCompletedServiceAssetsArray = () => {
		if (totalInCompletedServiceAssets) {
			let checkedIn = Object.values(totalInCompletedServiceAssets).map((obj) => {
				return obj
			})
			return checkedIn
		}

	}
	const getTotalRejectedServiceAssetsArray = () => {
		if (totalRejecedServiceAssets) {
			let checkedIn = Object.values(totalRejecedServiceAssets).map((obj) => {
				return obj
			})
			return checkedIn
		}

	}

	// const CheckedOutArrayData = () => {
	// 	if (totalCheckedOutCountbByMonth) {
	// 		let checkedOut = Object.values(totalCheckedOutCountbByMonth).map((obj) => {
	// 			return obj
	// 		})
	// 		return checkedOut
	// 	}
	// }
	const gettotalCategories = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/category/total`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			setTotalCategories(res?.data?.totalCategories)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}

	const gettotalDvir = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/dvir/total`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			setTotalDvir(res?.data?.totalDvir)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}

	const gettotalAudits = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/audit/total`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			setTotalAudits(res?.data?.totalAudits)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}

	const gettotalPendingServiceRequests = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/service/total/pending`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			setTotalPendingServiceRequests(res?.data?.totalPendingRequests)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}

	const gettotalInProgressRequests = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/service/total/inprogress`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			setTotalInProgressRequests(res?.data?.totalInProgressRequests)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const gettotalCompletedRequests = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/service/total/complete`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			setTotalCompletedRequests(res?.data?.totalCompleteRequests)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const gettotalInCompletedRequests = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/service/total/incomplete`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			setTotalInCompletedRequests(res?.data?.totalCompleteRequests)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const gettotalRejectedRequests = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/service/total/rejected`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			setTotalRejectedRequests(res?.data?.totalCompleteRequests)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}




	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		gettotalCategories()
		getTotalCompletedServiceAssets()
		getTotalRejectedServiceAssets()
		getTotalInCompletedServiceAssets()
		gettotalPendingServiceRequests()
		gettotalDvir()
		getLatestDvir()
		gettotalAudits()
		gettotalInCompletedRequests()
		getTotalAssets()
		getLatestAudits()
		getTotalAvailableAssets()
		gettotalRejectedRequests()
		gettotalInProgressRequests()
		gettotalCheckedOutAssets()
		gettotalCompletedRequests()

		changeBackground({ value: "light", label: "Light" });
	}, []);
	const [value, onChange] = useState(new Date());

	const seriesGraph = [{
		name: 'completed',
		data: getTotalCompletedServiceAssetsArray()
	}, {
		name: 'rejected',
		data: getTotalRejectedServiceAssetsArray()
	},
	{
		name: 'in-complete',
		data: getTotalInCompletedServiceAssetsArray()
	}]

	return (
		<>
		 <ToastContainer
               autoClose={3000}
            />
			<div className="row">
				<div className="col-xl-3 col-sm-6">
					<Link to="/asset-list" >
						<div className="card gradient-1 card-bx">
							<div className="card-body d-flex align-items-center">
								<div className="me-auto text-white">
									<h2 className="text-white">{totalAssets}</h2>
									<span className="fs-18">Total Assets</span>
								</div>
								{/* <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M29.0611 39.4402L13.7104 52.5947C12.9941 53.2089 11.9873 53.3497 11.1271 52.9556C10.2697 52.5614 9.7226 51.7041 9.7226 50.7597C9.7226 50.7597 9.7226 26.8794 9.7226 14.5028C9.7226 9.16424 14.0517 4.83655 19.3904 4.83655H38.7289C44.0704 4.83655 48.3995 9.16424 48.3995 14.5028V50.7597C48.3995 51.7041 47.8495 52.5614 46.9922 52.9556C46.1348 53.3497 45.1252 53.2089 44.4088 52.5947L29.0611 39.4402ZM43.5656 14.5028C43.5656 11.8335 41.3996 9.66841 38.7289 9.66841C33.0207 9.66841 25.1014 9.66841 19.3904 9.66841C16.7196 9.66841 14.5565 11.8335 14.5565 14.5028V45.5056L27.4873 34.4215C28.3926 33.646 29.7266 33.646 30.6319 34.4215L43.5656 45.5056V14.5028Z" fill="white" />
								</svg> */}
								{/* <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" fill="currentcolor" class="bi bi-diagram-2" viewBox="0 0 58 58">
									<path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM3 11.5A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
								</svg> */}
								<i style={{ fontSize: "58px", color: "white" }} class="bi bi-diagram-2"></i>
							</div>
						</div>
					</Link>

				</div>
				<div className="col-xl-3 col-sm-6">
					<Link to="/checkedout-asset-list" >
						<div className="card gradient-2 card-bx">
							<div className="card-body d-flex align-items-center">
								<div className="me-auto text-white">
									<h2 className="text-white">{totalCheckedOutAssets}</h2>
									<span className="fs-18">Checked-Out Assets</span>
								</div>
								<svg width="57" height="46" viewBox="0 0 57 46" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M8.55512 20.7503L11.4641 17.8435C12.3415 16.9638 12.3415 15.5397 11.4641 14.6601C10.5844 13.7827 9.16031 13.7827 8.28289 14.6601L1.53353 21.4094C0.653858 22.2891 0.653858 23.7132 1.53353 24.5929L8.28289 31.3422C9.16031 32.2197 10.5844 32.2197 11.4641 31.3422C12.3415 30.4626 12.3415 29.0385 11.4641 28.1588L8.55512 25.2498H27.8718C29.1137 25.2498 30.1216 24.2419 30.1216 23C30.1216 21.7604 29.1137 20.7503 27.8718 20.7503H8.55512Z" fill="white" />
									<path fillRule="evenodd" clipRule="evenodd" d="M16.5038 31.9992V36.4987C16.5038 41.4708 20.5332 45.4979 25.5029 45.4979H48.0008C52.9728 45.4979 57 41.4708 57 36.4987C57 29.0092 57 16.9931 57 9.50129C57 4.53151 52.9728 0.502136 48.0008 0.502136C41.5687 0.502136 31.9373 0.502136 25.5029 0.502136C20.5332 0.502136 16.5038 4.53151 16.5038 9.50129V14.0009C16.5038 15.2427 17.5117 16.2507 18.7536 16.2507C19.9955 16.2507 21.0034 15.2427 21.0034 14.0009C21.0034 14.0009 21.0034 11.8928 21.0034 9.50129C21.0034 7.01752 23.0192 5.00171 25.5029 5.00171H48.0008C50.4868 5.00171 52.5004 7.01752 52.5004 9.50129V36.4987C52.5004 38.9848 50.4868 40.9983 48.0008 40.9983C41.5687 40.9983 31.9373 40.9983 25.5029 40.9983C23.0192 40.9983 21.0034 38.9848 21.0034 36.4987C21.0034 34.1095 21.0034 31.9992 21.0034 31.9992C21.0034 30.7595 19.9955 29.7494 18.7536 29.7494C17.5117 29.7494 16.5038 30.7595 16.5038 31.9992Z" fill="white" />
								</svg>

							</div>
						</div>
					</Link>

				</div>
				<div className="col-xl-3 col-sm-6">
					<Link to="/category-list">
						<div className="card gradient-3 card-bx">
							<div className="card-body d-flex align-items-center">
								<div className="me-auto text-white">
									<h2 className="text-white">{totalCategories}</h2>
									<span className="fs-18">Total Categories</span>
								</div>
								<i class="bi bi-grid" style={{ fontSize: "58px", color: "white" }}></i>
							</div>
						</div>
					</Link>

				</div>
				<div className="col-xl-3 col-sm-6">
					<Link to="/dvir-list">
						<div className="card gradient-4 card-bx">
							<div className="card-body d-flex align-items-center">
								<div className="me-auto text-white">
									<h2 className="text-white">{totalDvir}</h2>
									<span className="fs-18">Total DVIR</span>
								</div>
								<i style={{ fontSize: "58px", color: "white" }} class="bi bi-truck-flatbed"></i>
							</div>
						</div>
					</Link>

				</div>
			</div>

			<div className="row">
				<div className="col-xl-3 col-sm-6">
					<Link to="/audit-list">
						<div className="card gradient-1 card-bx">
							<div className="card-body d-flex align-items-center">
								<div className="me-auto text-white">
									<h2 className="text-white">{totalAudits}</h2>
									<span className="fs-18">Total Audits</span>
								</div>
								<i style={{ fontSize: "50px", color: "white" }} class="bi bi-file-earmark-text"></i>
							</div>
						</div>
					</Link>

				</div>
				<div className="col-xl-3 col-sm-6">
					<Link to="/service-pending-list">
						<div className="card gradient-2 card-bx">
							<div className="card-body d-flex align-items-center">
								<div className="me-auto text-white">
									<h2 className="text-white">{totalPendingServiceRequests}</h2>
									<span className="fs-18">Pending Service Requests</span>
								</div>
								<i style={{ fontSize: "50px", color: "white" }} class="bi bi-clock-history"></i>

							</div>
						</div>
					</Link>

				</div>
				<div className="col-xl-3 col-sm-6">
					<Link to="/service-inprogress-list">
						<div className="card gradient-3 card-bx">
							<div className="card-body d-flex align-items-center">
								<div className="me-auto text-white">
									<h2 className="text-white">{totalInProgressRequests}</h2>
									<span className="fs-18">In Progress Request</span>
								</div>
								<i style={{ fontSize: "50px", color: "white" }} class="bi bi-hourglass-split"></i>
							</div>
						</div>
					</Link>

				</div>
				<div className="col-xl-3 col-sm-6">
					<Link to="/service-completed-list">
						<div className="card gradient-4 card-bx">
							<div className="card-body d-flex align-items-center">
								<div className="me-auto text-white">
									<h2 className="text-white">{totalCompletedRequests}</h2>
									<span className="fs-18">Completed Request</span>
								</div>
								<i style={{ fontSize: "50px", color: "white" }} class="bi bi-check-circle"></i>
							</div>
						</div>
					</Link>

				</div>
			</div>

			<div className="row">
				{/* <div className="col-xl-3 col-xxl-4">
					<div className="row">
						<div className="col-xl-12 col-sm-6">
							<div className="card text-center">
								<div className="card-body">
									<div id="radialChart" className="radialChart">
										<div id="chart" >

											<ReactApexChart
												options={options}
												series={[]}
												type="radialBar"
												height={150}
											/>
										</div>
									</div>
									<h2>{totalAvailableAssets}</h2>
									<span className="fs-16 text-black">Total Available Assets</span>
								</div>
							</div>
						</div>
						<div className="col-xl-12 col-sm-6">
							<div className="card">
								<div className="card-header border-0 pb-0">
									<h4 className="mb-0">Service Statistics</h4>
								</div>
								<div className="card-body" style={{ padding: "1rem" }}>
									<div className="progress mb-4" style={{ height: "13px" }}>
										<div className="progress-bar gradient-5 progress-animated" style={{ width: "55%", height: "13px" }} >
											<span className="sr-only">45% Complete</span>
										</div>
									</div>
									<div className="progress mb-4" style={{ height: "13px" }}>
										<div className="progress-bar gradient-6 progress-animated" style={{ width: "55%", height: "13px" }}>
											<span className="sr-only">45% Complete</span>
										</div>
									</div>
									<div className="progress default-progress" style={{ height: "13px" }}>
										<div className="progress-bar gradient-7 progress-animated" style={{ width: "55%", height: "13px" }}>
											<span className="sr-only">45% Complete</span>
										</div>
									</div>
									<div className="d-flex mt-4 progress-bar-legend align-items-center justify-content-between">
										<div className="d-flex">
											<span className="marker gradient-5"></span>
											<div>
												<p className="status">Pending</p>
												<span className="result">{totalPendingServiceRequests}</span>
											</div>
										</div>
										<div className="d-flex">
											<span className="marker gradient-6"></span>
											<div>
												<p className="status">In Progress</p>
												<span className="result">{totalInProgressRequests}</span>
											</div>
										</div>
										<div className="d-flex">
											<span className="marker gradient-7"></span>
											<div>
												<p className="status">Completed</p>
												<span className="result">{totalCompletedRequests}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}
				<div className="col-xl-12 col-xxl-12">
					<div className="card">
						<div className="card-header border-0 d-sm-flex d-block">
							<div className="me-auto mb-sm-0 mb-3">
								<h4 className="card-title mb-2">Service Statistics</h4>

							</div>
							<div className="d-flex justify-content-between">
								<div className="d-flex me-5">
									<h3 className="mb-0 me-2">{totalCompletedRequests}</h3>
									<span>Completed</span>
								</div>
								<div className="d-flex me-3">
									<h3 className="mb-0 me-2">{totalRejectedRequests}</h3>
									<span>Rejected</span>
								</div>
								<div className="d-flex me-3">
									<h3 className="mb-0 me-2">{totalInCompletedRequests}</h3>
									<span>In Complete</span>
								</div>
								{/* <Dropdown className="dropdown">
									<Dropdown.Toggle as="div" className="btn-link i-false" >
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
											<path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
											<path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#575757" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
										</svg>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-menu">
										<Dropdown.Item className="dropdown-item">Delete</Dropdown.Item>
										<Dropdown.Item className="dropdown-item">Edit</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown> */}
							</div>
						</div>
						<div className="card-body">
							<div id="reservationChart" className="reservationChart">
								<div id="chart" >
									{(totalCompletedServiceAssets && totalInCompletedServiceAssets && totalRejecedServiceAssets) && <ReactApexChart
										options={graphOptions}
										series={seriesGraph}
										type="area"
										height={400}
									/>}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-6">
					<div className="card">
						<div className="card-body py-0">
							<div className="d-flex justify-content-between mb-3">
								<h4 className="card-title mt-3">Latest AUDIT's</h4>
							</div>
							<div className="row">

								{latestAudit?.map((audit) => {
									return (<>

										<div className="col-xl-6 col-sm-6" key={audit?._id}>
											<Link to={`/audit-detail/${audit._id}`}>
												<div className="dz-image-bx rounded" style={{ flexDirection: "column" }}>
													<div style={{ display: "flex" }}>
														<div className="dz-media  me-3">
															<img className="rounded" src={`${process.env.REACT_APP_BASE_URL}/${audit?.asset?.images[0]?.url}`} alt="" />
														</div>
														<div className="dz-info">
															<h5>{audit?.asset?.assetName}</h5>
															<span className="text-primary">{audit?.submittedDate}</span> <br />
															<span className="fs-14">{`${((audit?.isQrCode) ? "Qr Code Available" : "Qr Code Not Available")}`}</span>
															<div style={{ marginTop: "5px" }}>
															<DynamicStar
																width={20}
																height={20}
																rating={audit?.rating}
																emptyStarColor="transparent"
																totalStars={5}
																outlined={true}
															/>

														</div>
														</div>
														
													</div>

												</div>
											</Link>
										</div>
									</>)

								})}


							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-6">
					<div className="card">
						<div className="card-body py-0">
							<div className="d-flex justify-content-between mb-3">
								<h4 className="card-title mt-3">Latest DVIR</h4>
							</div>
							<div className="row">

								{latestDVIR?.map((dvir) => {

									return (<>
										<div className="col-xl-6 col-sm-6" key={dvir?._id}>
											<Link to={`/dvir-detail/${dvir?._id}`}>
												<div className="dz-image-bx rounded">
													<div className="dz-media  me-3">
														<img className="rounded" src={`${process.env.REACT_APP_BASE_URL}/${dvir?.asset?.images[0]?.url}`} alt="" />
													</div>
													<div className="dz-info">
														<h5>{dvir?.asset?.assetName}</h5>
														<span className="text-primary">{dvir?.date}</span> <br />
														<span className="fs-14">{`${dvir?.vehicleNumber}`}</span>
													</div>
												</div>
											</Link>
										</div>
									</>)

								})}


							</div>
						</div>
					</div>
				</div>


			</div>
		</>
	)
}
export default Home;