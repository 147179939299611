import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import PerfectScrollbar from "react-perfect-scrollbar";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from '../Loding/Sppinner';
import noImage from '../../../images/avatar/No_Image_Available.jpg';

const GuestDetail = () => {
	const auth = useSelector(state => state.auth)
	const [assets, setAssets] = useState([])
	const [currentlyCheckedOutAssets, setCurrentlyCheckedOutAssets] = useState([])
	console.log("auth", auth);
	const { id } = useParams();
	const [user, setUserData] = useState({})
	const [isLoading,setIsLoading]=useState(true)
	const history = useHistory();
	const [itemsPerPage, setItemsPerPage] = useState(5)
	const [IsConfirm,setIsConfirm]=useState(false)
	const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0)
	const [currentPage, setCurrentPage] = useState(1);
	const getSingleUserDetail = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/user/company/user/${id}`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {
			
			setUserData(res.data.user)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			swal(`${err?.response?.data?.message}`)
            if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	// const handleDelete = async () => {
	// 	axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/user/company/user/${id}`, {
	// 		headers: {
	// 			Authorization: `Bearer ${auth.auth.token}`,
	// 		}

	// 	}).then((res) => {
	// 		showToastMessage(`${res?.data?.message}`)
	// 		history.push("/user-list")
	// 	}).catch((err) => {
	// 		swal(`${err?.response?.data?.message}`)
	// 		swal(`${err?.response?.data?.message}`)
    //         if(err?.response?.status===401){
	// 			history.push("/login")
	// 			window.location.reload();
	// 		}
	// 	})

	// }

	const handleDeleteConfirm = async () => {

		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/user/company/delete/${id}`,{isConfirm:true}, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
			}
		}).then((res) => {
			// console.log(auth.auth.token);
			// console.log(res?.data,"confir");
			if(res?.data?.isConfirm===true){
				swal(`${res?.data?.message}`)
				history.push("/user-list")
			}else if(res?.data?.isConfirm===false){
				swal({
					title: `Are you sure?`,
					text: `${res?.data?.message} You won't be able to revert this!`,
					icon: "warning",
					buttons: ["Cancel", "Confirm"],
					dangerMode: true,
				  })
				  .then((result) => {
					console.log(result);
					if(result===null){
						setIsConfirm(false)
						
					}
					else if (result===true) {
						console.log("gererere");
						setIsConfirm(true)
					   handleDeleteConfirm(id)
					  
					} 
				  });
			}
			
			
		}).catch((err) => {
			console.log(err?.response?.data);
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})

	}
	const handleDelete = async () => {
		console.log("isConfirm",IsConfirm);
		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/user/company/delete/${id}`,{isConfirm:IsConfirm}, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
			}
		}).then((res) => {
			console.log(res?.data,"res");
			if(res?.data?.isConfirm===true){
				swal(`${res?.data?.message}`)
				history.push("/user-list")
			}else if(res?.data?.isConfirm===false){
				swal({
					title: `Are you sure?`,
					text: `${res?.data?.message} You won't be able to revert this!`,
					icon: "warning",
					buttons: ["Cancel", "Confirm"],
					dangerMode: true,
				  })
				  .then((result) => {
					console.log(result);
					if(result===null){
						setIsConfirm(false)
						history.push(`/user-detail/${id}`)
					}
					else if (result===true) {
				
						setIsConfirm(true)
					   handleDeleteConfirm(id)
					  
					} 
				
				  });
			}
			
			
		}).catch((err) => {
			
			swal(`${err?.response?.data?.message}`)
			if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})

	}

	const getAllAssets = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/checkedin/history/assets/${id}`, { headers: { Authorization: `Bearer ${auth.auth.token}` }, params: { page: currentPage }  }).then((res) => {
			setTotalNumberOfRecords(res?.data?.totalCount)
			setAssets(res?.data?.assets)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			swal(`${err?.response?.data?.message}`)
            if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const getAllCurrentlyCheckedOutAssets = async () => {
		
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/checkedout/asset/${id}`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

			setCurrentlyCheckedOutAssets(res?.data?.assets)
			setIsLoading(false)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`);
			swal(`${err?.response?.data?.message}`)
            if(err?.response?.status===401){
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const showToastMessage = (msg) => {    
        toast.success(`${msg}`, {
            position: toast.POSITION.BOTTOM_LEFT,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            closeButton: <i className="fa fa-times" aria-hidden="true"></i>,
            style: {
              background: "white",
              borderRadius:"5px",
              borderLeft:"10px solid green",
              color:"black",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            },
          });
    };
	useEffect(() => {
		getSingleUserDetail();
		getAllAssets();
		getAllCurrentlyCheckedOutAssets();
	}, [])

	function dateFormat(date) {
		const month = date.getMonth();
		const day = date.getDate();
		const monthString = month >= 10 ? month : `0${month}`;
		const dayString = day >= 10 ? day : `0${day}`;
		return `${dayString}-${monthString}-${date.getFullYear()}`;
	}

	useEffect(() => {
		getAllAssets()
	}, [currentPage, setCurrentPage])

	const checkMoveable = (page) => {

		const max = (Math.ceil(totalNumberOfRecords / 5))
		if ((page + 1) <= max) {
			return true
		} else {
			return false
		}

	}
	const checkBackward = (page) => {
	
		if (page > 1) {
			return true
		} else {
			return false
		}
	}
	let paggination = Array(Math.ceil(totalNumberOfRecords / itemsPerPage))
		.fill()
		.map((_, i) => i + 1);
	const lowerBound = (currentPage - 1) * itemsPerPage + 1;
	const upperBound = Math.min(currentPage * itemsPerPage, totalNumberOfRecords);

	return (
		<>
			<ToastContainer
                autoClose={3000}
            />
			{isLoading?(<Spinner/>):(<div className="row">
				<div className="col-xl-8 col-xxl-8">
					<div className="card">
						<div className="card-header border-0 pb-4">
							<h4 className="card-title">Currently Checked Out Asset</h4>
						</div>


						<div className="table-responsive">
							<div id="example2_wrapper" className="dataTables_wrapper no-footer" style={{ height: "370px", overflow: "scroll" }}>
								<table

									id="example2"
									className="table card-table default-table display mb-4 dataTablesCard  no-footer"
								>
									<thead>
										<tr role="row">
											{/* <th className="sorting_asc bg-none" >
														<div className="form-check  style-1">
															<input type="checkbox" onClick={() => chackboxFun("all")} className="form-check-input" id="checkAll" required="" />
														</div>
													</th> */}
											<th className="sorting" style={{ textAlign: "center" }}>Asset</th>


											<th className="sorting" style={{ textAlign: "center" }}>Serial Number</th>

											<th className="sorting" style={{ textAlign: "center" }}>Checked Out At</th>
											<th className="sorting" style={{ textAlign: "center" }}>Milage/Hours</th>


										</tr>
									</thead>
									<tbody>
										{currentlyCheckedOutAssets?.map((asset) => {
											return (
												<>
													<tr role="row" className="odd" key={asset._id}>

														<td>
															<div className="media-bx" style={{ textAlign: "center" }}>
																<Link to={`/asset-detail/${asset?.asset?._id}`} className="text-black" style={{ display: "flex" }}>
																	<img className="me-3 rounded" src={`${process.env.REACT_APP_BASE_URL}/${asset?.asset?.images[0]?.url}`} alt="" />
																	<div style={{display:"flex",alignItems:"center"}}>

																		<h4 className="mb-0 mt-1">
																			{`${asset?.asset?.assetName}`}
																		</h4>
																		{/* <span className="text-primary">{asset?.asset?.assetCode}</span> */}
																	</div>
																</Link>
															</div>
														</td>



														<td>
															<div style={{ textAlign: "center" }}>
																<h5>{asset?.asset?.serialNumber}</h5>

															</div>
														</td>


														<td style={{ textAlign: "center" }}>
															<div style={{ textAlign: "center" }}>

																<h5>{`${dateFormat(new Date(asset?.checkedOutAt))}`}</h5>
																<span className="fs-14">{new Date(asset?.checkedOutAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>


															</div>
														</td>
														<td style={{ textAlign: "center" }}>
															<div style={{ textAlign: "center" }}>
																<h5>{asset?.checkedOutUnitValue}</h5>

															</div>
														</td>



													</tr>
												</>
											)
										})}



									</tbody>
								</table>

							</div>
						</div>












					</div>
				</div>
				<div className="col-xl-4 col-xxl-4">
					<div className="card profile-card">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<img src={(user?.profile)?`${process.env.REACT_APP_BASE_URL}/${user?.profile}`:noImage} alt="" className="rounded profile-img me-4" />
								<div>

									<h4 className="mb-0">{`${user?.name?.firstName} ${user?.name?.lastName}`}</h4>
								</div>
							</div>
							<div className="row mt-4 pt-3">
								<div className="col-8">
									<Link to={`/user-profile-edit/${id}`} className="btn btn-dark light btn-block">Edit</Link>
								</div>
								<div className="col-4">
									<Link to={"#"} className="btn btn-danger btn-block" onClick={handleDelete}><i class="bi bi-trash"></i></Link>
								</div>
							</div>
							<ul className="user-info-list">
								<li>
									<i className="fas fa-phone-volume"></i>
									<span>{`+92${user?.number}`}</span>
								</li>
								<li>
									<i className="far fa-envelope"></i>
									<span className="overflow-hidden">{user?.email}</span>
								</li>
								<li>
									<i className="fas fa-map-marker-alt"></i>
									<span>{`${user?.address?.street} ${user?.address?.city} ${user?.address?.zip} ${user?.address?.country}`}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="col-xl-12">
					<div className="d-flex align-items-center rooms flex-wrap">
						<h4 className="me-auto mb-3">User Asset History</h4>
						{/* <div className="mb-3">
							<Link to={"#"} className="btn btn-primary me-3"><i className="fas fa-calendar-times me-2"></i>Date Filter</Link>
							<Link to={"#"} className="btn btn-primary"><i className="far fa-file-word me-2"></i>Generate Report</Link>	
						</div> */}
					</div>
					<div className="table-responsive">
						<div id="history_wrapper" className="dataTables_wrapper no-footer">
							<table
								id="example2"
								className="table card-table display mb-4 dataTablesCard booking-table dataTable no-footer"
							>
								<thead>
									<tr role="row">

										<th style={{ textAlign: "center" }}>Asset</th>
										<th style={{ textAlign: "center" }}>Checked-Out<br />{`(Hours/Milage)`}</th>
										<th style={{ textAlign: "center" }}>Checked-Out At</th>
										<th style={{ textAlign: "center" }}>Checked-In<br />{`(Hours/Milage)`}</th>
										<th style={{ textAlign: "center" }}>Checked-In At</th>
									</tr>
								</thead>
								<tbody>
									{assets?.map((asset) => {
										return (<>
											<tr role="row" className="odd">

												<td>
													<div className="media-bx" >
														<Link to={`/user-detail/${asset?.user?._id}`} className="text-black" style={{ display: "flex", alignItems: "center" }}>
															<img className="me-3 rounded" src={`${process.env.REACT_APP_BASE_URL}/${asset?.asset?.images[0]?.url}`} alt="" />
															<div style={{display:"flex",alignItems:"center"}}>

																<h4 className="mb-0 mt-1">
																	{`${asset?.asset?.assetName}`}
																</h4>
																{/* <span className="text-primary">{asset?.asset?.assetCode}</span> */}
															</div>
														</Link>
													</div>
												</td>
												<td>
													<div style={{ textAlign: "center" }}>
														<span className="fs-16">{asset?.checkedOutUnitValue}</span>
													</div>
												</td>
												<td>
													<div style={{ textAlign: "center" }}>
														<h5>{`${dateFormat(new Date(asset?.checkedOutAt))}`}</h5>
														<span className="fs-14">{new Date(asset?.checkedOutAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>


													</div>
												</td>
												<td>
													<div style={{ textAlign: "center" }}>

														<span className="fs-16">{asset?.checkedInUnitValue}</span>
													</div>
												</td>
												<td>
													<div style={{ textAlign: "center" }}>
														<h5>{`${dateFormat(new Date(asset?.checkedInAt))}`}</h5>
														<span className="fs-14">{new Date(asset?.checkedInAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
													</div>
												</td>

											</tr>

										</>)
									})}

								</tbody>
							</table>
							<div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
								<div className="dataTables_info">
									Showing {lowerBound} to{" "}
									{upperBound} {" "}
									of {totalNumberOfRecords} entries
								</div>
								<div
									className="dataTables_paginate paging_simple_numbers mb-0"
									id="example2_paginate"
								>
									<Link
										className={`paginate_button previous ${(currentPage === 1) && "disabled"}`}
										onClick={() => {
											if (checkBackward(currentPage)) {
												setCurrentPage((pre) => pre - 1)
											}
										}}

									>
										<i className="fa fa-angle-double-left"></i> Previous
									</Link>
									<span>

										{paggination.map((number, i) => (

											<Link
												key={i}

												className={`paginate_button  ${currentPage === i + 1 ? "current" : ""
													} `}

											>
												{number}
											</Link>
										))}
									</span>
									<Link
										className={`paginate_button next ${(currentPage === ((Math.ceil(totalNumberOfRecords / 5)))) && "disabled"}`}

										onClick={() => {
											if (checkMoveable(currentPage)) {
												setCurrentPage((pre) => pre + 1)
											}
										}
										}
									>
										Next <i className="fa fa-angle-double-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>)}
		</>
	)
}
export default GuestDetail;