import React, { Fragment, useEffect, useState } from "react";
import { Button, Dropdown, Modal, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

//** Import Image */
import profile from "../../../../images/avatar/profileAvatar.png"
import profile08 from "../../../../images/profile/8.jpg";
import profile09 from "../../../../images/profile/9.jpg";

import PageTitle from "../../../layouts/PageTitle";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../Loding/Sppinner";
import { updateCompany } from "../../../../store/actions/AuthActions";

const AppProfile = () => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();
	const dispatch = useDispatch()
	const history = useHistory()
	const auth = useSelector(state => state.auth)
	const [activeToggle, setActiveToggle] = useState("aboutMe");
	const [sendMessage, setSendMessage] = useState(false);
	const [postModal, setPostModal] = useState(false);
	const [companyProfile, setCompanyProfile] = useState()
	const [profileUpdateModal, setProfileUpdateModal] = useState(false);
	const [cameraModal, setCameraModal] = useState(false);
	const [linkModal, setLinkModal] = useState(false);
	const [replayModal, setReplayModal] = useState(false);
	const [companyTypes, setCompanyTypes] = useState([])

	const [firstName, setFirstName] = useState(auth?.auth?.company?.name?.firstName)
	const [lastName, setLastName] = useState(auth?.auth?.company?.name?.lastName)
	const [companyName, setCompanyName] = useState(auth?.auth?.company?.companyName)
	const [companyType, setCompanyType] = useState(auth?.auth?.company?.companyType?._id)
	const [city, setCity] = useState(auth?.auth?.company?.city)
	const [state, setState] = useState(auth?.auth?.company?.state)
	const [zip, setZip] = useState(auth?.auth?.company?.zip)
	const [phoneNumber, setPhoneNumber] = useState(auth?.auth?.company?.number)

	const [isLoading, setIsLoading] = useState(true)
	const [businessAddress, setBusinessAddress] = useState(auth?.auth?.company?.businessAddress)
	const showToastMessage = (msg) => {
		toast.success(`${msg}`, {
			position: toast.POSITION.BOTTOM_LEFT,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			closeButton: <i className="fa fa-times" aria-hidden="true"></i>,
			style: {
				background: "white",
				borderRadius: "5px",
				borderLeft: "10px solid green",
				color: "black",
				boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
			},
		});
	};
	const handleUpdatePassword = (data) => {
		console.log(data);
		const updatedData = {
			oldPassword: data.oldPassword,
			newPassword: data.newPassword,
			confirmNewPassword: data.confirmNewPassword
		}

		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/company/me/update/password`, updatedData, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,

			}
		}).then((res) => {
			showToastMessage(`${res?.data?.message}`)
			setActiveToggle("aboutMe")

		}).catch((err) => {
			// console.log(err.response);
			swal(`${err?.response?.data?.message}`)
		})

	};
	const getAllCompanyTypes = async () => {
		axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/companyType/all`, { headers: { Authorization: `Bearer ${auth.auth.token}` } }).then((res) => {

			setCompanyTypes(res?.data?.types)
		}).catch((err) => {
			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})
	}
	const handleUser = (data) => {
		// console.log("files original",files);
		let formData = new FormData()

		// if(!companyProfile){
		// 	return swal("please upload atleast one image")
		// }

		formData.append("companyName", data.companyName);
		formData.append("firstName", data.firstName);
		formData.append("lastName", data.lastName);
		formData.append("companyType", data.companyType);
		formData.append("businessAddress", data.businessAddress);
		formData.append("image", companyProfile);


		// for (var pair of formData.entries()) {
		// 	console.log(pair[0] + ', ' + pair[1]);
		// }
		// console.log("newdata", formData);
		axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/company/me/update`, formData, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
				'content-type': 'multipart/form-data'
			}
		}).then((res) => {

			console.log(auth, "before");
			showToastMessage(`${res?.data?.message}`)
			dispatch(updateCompany(res?.data?.company));
			setProfileUpdateModal(false)
			setIsLoading(false)
			console.log(auth, "after");
		}).catch((err) => {
			// console.log(err.response);
			swal(`${err?.response?.data?.message}`)
		})

	};
	useEffect(() => {
		getAllCompanyTypes()
	}, [])
	return (

		<>
			<ToastContainer
				autoClose={3000}
			/>
			<Fragment>

				<Modal className="fade" show={profileUpdateModal} size="lg">
					<Modal.Header>
						<Modal.Title>Update Profile</Modal.Title>
						<Button
							variant=""
							className="btn-close"
							onClick={() => setProfileUpdateModal(false)}
						>

						</Button>
					</Modal.Header>
					<Modal.Body>
						<div className="settings-form">
							<h4 className="text-primary" style={{ margin: "0px 0px 24px 0px" }}>Personal Information</h4>
							<form onSubmit={handleSubmit(handleUser)} enctype="multipart/form-data">
								<div className="row">
									<div className="form-group mb-3 col-md-6">
										<label className="form-label" >FirstName</label>
										<input type="text"  {...register("firstName")} value={firstName} placeholder="Email" className="form-control" />
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="form-label">LastName</label>
										<input type="text"  {...register("lastName")} value={lastName} placeholder="Password" className="form-control" />
									</div>
								</div>

								<h4 className="text-primary" style={{ margin: "24px 0px" }}>Business Information</h4>
								<div className="row">
									<div className="form-group mb-3 col-md-6">
										<label className="form-label">Company Name</label>
										<input type="text"  {...register("companyName")} value={companyName} placeholder="1234 Main St" className="form-control" />
									</div>

									<div className="form-group mb-3 col-md-6">
										<label className="form-label">Company Type</label>
										<select
											className="form-control"
											id="inputState"
											{...register("companyType", { required: true })}>
											{companyTypes?.map((con) => {
												return (<>
													<option value={`${con?._id}`} selected={(con._id === companyType) ? true : false}>{con?.companyType}</option>
												</>)
											})}
										</select>
										{/* {errors.condition?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
									</div>
								</div>
								<div className="row">
									<div className="form-group mb-3 col-md-6">
										<label className="form-label" >Phone Number</label>
										<input type="text"  {...register("phoneNumber")} value={phoneNumber} className="form-control" />
									</div>

								</div>
								<div className="form-group mb-3">
									<label className="form-label">Business Address</label>
									<input type="text"  {...register("businessAddress")} value={businessAddress} className="form-control" />
								</div>
								<div className="row">
									<div className="form-group mb-3 col-md-6">
										<label className="form-label ">
											City
										</label>
										<input type="text"  {...register("city")} className="form-control" value={city} />
										{/* {errors.city?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
									</div>
									<div className="form-group mb-3 col-md-3" >
										<label className="form-label ">
											State
										</label>
										<input type="text"  {...register("state")}  className="form-control"  value={state}/>
										{/* {errors.state?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="form-label">
										Zip
										</label>
										<input type="text"  {...register("zip")} className="form-control" value={zip}/>
										{/* {errors.zip?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
									</div>

								</div>
								<div className="row">
									<div className="form-group mb-3 col-md-6">

										{/* <img src={`http://localhost:5000/${category?.image}`} alt="" className="rounded profile-img me-4 mb-3" /> */}
										<img
											src={companyProfile
												? URL.createObjectURL(companyProfile)
												: ((auth?.auth?.company?.profile) ? `${process.env.REACT_APP_BASE_URL}/${auth?.auth?.company?.profile}` : profile)}
											alt=""
											className="rounded profile-img me-4 mb-3"
											style={{ width: "100px", height: "100px", objectFit: "cover" }}
										/>
									</div>
									<div className="form-group mb-3 col-md-6">
										<label className="form-label">Change Category Picture</label>
										<input type="file" className="form-control"{...register("image")} onChange={(e) => { setCompanyProfile(e.target.files[0]) }} />

										{/* {errors.image?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
									</div>

								</div>
								<button className="btn btn-primary" style={{ marginTop: "15px" }} type="submit">Update</button>
							</form>
						</div>
					</Modal.Body>
					{/* <Modal.Footer>
					<Button
						onClick={() => setProfileUpdateModal(false)}
						variant="danger light"
					>
						Close
					</Button>
					<Button variant="primary">Save changes</Button>
				</Modal.Footer> */}
				</Modal>

				<div className="row">
					<div className="col-lg-12">
						<div className="profile card card-body px-3 pt-3 pb-0">
							<div className="profile-head">
								<div className="photo-content ">
									<div className="cover-photo rounded"></div>
								</div>
								<div className="profile-info">
									<div className="profile-photo">
										<img
											src={(auth?.auth?.company?.profile) ? `${process.env.REACT_APP_BASE_URL}/${auth?.auth?.company?.profile}` : profile}
											className=" rounded-circle"
											alt="profile"
											style={{ width: "100px", height: "100px", objectFit: "cover" }}
										/>
									</div>
									<div className="profile-details">
										<div className="profile-name px-3 pt-2">
											<h4 className="text-primary mb-0">{`${auth?.auth?.company?.name?.firstName} ${auth?.auth?.company?.name?.lastName}`}</h4>
											<p>Admin</p>
										</div>
										<div className="profile-email px-2 pt-2">
											<h4 className="text-muted mb-0">{auth?.auth?.company?.email}</h4>
											<p>Email</p>
										</div>
										<div className="profile-email px-2 pt-2">
											<h4 className="text-muted mb-0">{`0${auth?.auth?.company?.number}`}</h4>
											<p>Phone Number</p>
										</div>
										{/* <Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-user-circle text-primary me-2" />
                        View profile
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-users text-primary me-2" />
                        Add to close friends
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-plus text-primary me-2" />
                        Add to group
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-ban text-primary me-2" />
                        Block
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					{/* <div className="col-xl-4">
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<div className="profile-statistics">
								<div className="text-center">
									<div className="row">
										<div className="col">
											<h3 className="m-b-0">150</h3><span>Follower</span>
										</div>
										<div className="col">
											<h3 className="m-b-0">140</h3> <span>Place Stay</span>
										</div>
										<div className="col">
											<h3 className="m-b-0">45</h3> <span>Reviews</span>
										</div>
									</div>
									<div className="mt-4">
										<Link	to="/post-details"	className="btn btn-primary mb-1 me-1">Follow</Link>
										<Button as="a" href="#" className="btn btn-primary mb-1 ms-1" onClick={() => setSendMessage(true)}>Send Message</Button>
									</div>
								</div>
							 
								<Modal className="modal fade" show={sendMessage}>
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">Send Message</h5>
											<Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => setSendMessage(false)}>
												
											</Button>
										</div>
										<div className="modal-body">
											<form className="comment-form" onSubmit={(e) => { e.preventDefault(); setSendMessage(false); }}>
												<div className="row">
													<div className="col-lg-6">
														<div className="form-group mb-3">
															<label htmlFor="author" className="text-black font-w600">  Name <span className="required">*</span> </label>
															<input type="text" className="form-control" defaultValue="Author" name="Author" placeholder="Author" />
														</div>
													</div>
													<div className="col-lg-6">
														<div className="form-group mb-3">
															<label htmlFor="email" className="text-black font-w600"> Email <span className="required">*</span></label>
															<input type="text" className="form-control" defaultValue="Email" placeholder="Email" name="Email"/>
														</div>
													</div>
													<div className="col-lg-12">
														<div className="form-group mb-3">
															<label htmlFor="comment" className="text-black font-w600">Comment</label>
															<textarea rows={8} className="form-control" name="comment" placeholder="Comment" defaultValue={""}/>
														</div>
													</div>
													<div className="col-lg-12">
														<div className="form-group mb-3">
															<input type="submit" value="Post Comment" className="submit btn btn-primary" name="submit"/>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</Modal>
							</div>
						</div>
					</div>
				</div>	
				<div className="col-lg-12">
					<div className="card">
						<div className="card-header border-0 pb-0">
							<h5 className="text-primary">Today Highlights</h5>
						</div>	
						<div className="card-body pt-3"	>	
							<div className="profile-blog ">
								<img  src={profile01}  alt="profile" className="img-fluid  mb-4 w-100 " />
								<Link to="/post-details"> <h4>Darwin Creative Agency Theme</h4> </Link>
								<p className="mb-0">
									A small river named Duden flows by their place and supplies
									it with the necessary regelialia. It is a paradisematic
									country, in which roasted parts of sentences fly into your
									mouth.
								</p>
							</div>
						</div>	
					</div>
				</div>
				<div className="col-lg-12">
					<div className="card">
						<div className="card-header border-0 pb-0">
							<h5 className="text-primary ">Interest</h5>
						</div>
						<div className="card-body pt-3">
							<div className="profile-interest ">
								 <SRLWrapper options={options}>
									<div className="row sp4">
										<div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
											<a href={profile02}> <img src={profile02} alt="profileImage" className="img-fluid" /> </a>
										</div>
										<div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
											<a href={profile03}> <img src={profile03} alt="profile" className="img-fluid"/></a>
										</div>
										<div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
											<a href={profile04}><img src={profile04} alt="profile" className="img-fluid" /> </a>
										</div>
										<div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
											{" "}
											<a href={profile02}><img src={profile02} alt="profile" className="img-fluid" /> </a>
										</div>
										<div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
											<a href={profile03} className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col" >
												<img src={profile03} alt="profile"	className="img-fluid"/>	
											</a>
										</div>
										<div className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1">
											<a href={profile04}	className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col">
												<img  src={profile04} alt="profile"	className="img-fluid"/>
											</a>
										</div>
									</div>
								</SRLWrapper>
							</div>
						</div>	
					</div>
				</div>	
				<div className="col-lg-12">
					<div className="card">
						<div className="card-header border-0 pb-0">
							<h5 className="text-primary">Our Latest News</h5>
						</div>	
						<div className="card-body pt-3">
							<div className="profile-news">
							  <div className="media pt-3 pb-3">
								<img src={profile05} alt="" className="me-3 rounded" width={75}/>
								<div className="media-body">
									<h5 className="m-b-5">
										<Link to="/post-details" className="text-black">
											Collection of textile samples
										</Link>
									</h5>
									<p className="mb-0">I shared this on my fb wall a few months back, and I thought. </p>
								</div>
							  </div>
							  <div className="media pt-3 pb-3">
								<img src={profile06} alt=""  className="me-3 rounded" width={75}/>
								<div className="media-body">
									<h5 className="m-b-5">
										<Link to="/post-details" className="text-black">
										Collection of textile samples
										</Link>
									</h5>
									<p className="mb-0">
										I shared this on my fb wall a few months back, and I
										thought.
									</p>
								</div>
							  </div>
							  <div className="media pt-3 ">
								<img src={profile07} alt="" className="me-3 rounded" width={75} />
								<div className="media-body">
									<h5 className="m-b-5">
										<Link to="/post-details" className="text-black">
											Collection of textile samples
										</Link>
									</h5>
									<p className="mb-0">
										I shared this on my fb wall a few months back, and I thought.
									</p>
								</div>
							  </div>
							</div>
						</div>	
					</div>
				</div>	
			</div>	
		</div>	 */}
					<div className="col-xl-12">
						<div className="card">
							<div className="card-body">
								<div className="profile-tab">
									<div className="custom-tab-1">
										<ul className="nav nav-tabs">
											{/* <li className="nav-item" onClick={() => setActiveToggle("posts")}>
												<Link to="#my-posts" data-toggle="tab" className={`nav-link ${activeToggle === "posts" ? "active show" : ""}`}>Posts</Link>
											</li> */}
											<li className="nav-item" onClick={() => setActiveToggle("aboutMe")}>
												<Link to="#about-me" data-toggle="tab" className={`nav-link ${activeToggle === "aboutMe" ? "active show" : ""}`}>About Me</Link>
											</li>
											<li className="nav-item">
												<Link to="#profile-settings" data-toggle="tab" onClick={() => setActiveToggle("setting")} className={`nav-link ${activeToggle === "setting" ? "active show" : ""}`}>Update Password</Link>
											</li>
										</ul>
										<div className="tab-content">
											<div id="my-posts" className={`tab-pane fade ${activeToggle === "posts" ? "active show" : ""}`} >
												<div className="my-post-content pt-3">
													<div className="post-input">
														<textarea name="textarea" id="textarea" cols={30} rows={5} className="form-control bg-transparent" placeholder="Please type what you want...." defaultValue={""} />
														<Link to="/app-profile" className="btn btn-primary light px-3 me-1" data-toggle="modal" data-target="#linkModal" onClick={() => setLinkModal(true)}>
															<i className="fa fa-link m-0" />{" "}
														</Link>

														<Modal show={linkModal} onHide={() => setLinkModal(false)} className="modal fade post-input" id="linkModal" aria-hidden="true">
															<div className="modal-content">
																<div className="modal-header">
																	<h5 className="modal-title">Social Links</h5>
																	<button type="button" className="btn-close" data-dismiss="modal" onClick={() => setLinkModal(false)}>
																	</button>
																</div>
																<div className="modal-body">
																	<Link className="btn-social me-1 facebook" to="/app-profile"><i className="fa fa-facebook" /></Link>
																	<Link className="btn-social me-1 google-plus" to="/app-profile"> <i className="fa fa-google-plus" /></Link>
																	<Link className="btn-social me-1 linkedin" to="/app-profile"><i className="fa fa-linkedin" /></Link>
																	<Link className="btn-social me-1 instagram" to="/app-profile"> <i className="fa fa-instagram" /></Link>
																	<Link className="btn-social me-1 twitter" to="/app-profile"><i className="fa fa-twitter" /></Link>
																	<Link className="btn-social me-1 youtube" to="/app-profile"><i className="fa fa-youtube" /></Link>
																	<Link className="btn-social whatsapp" to="/app-profile"><i className="fa fa-whatsapp" /></Link>
																</div>
															</div>
														</Modal>
														<Link to="/app-profile" className="btn btn-primary light px-3 me-1" data-toggle="modal" data-target="#cameraModal" onClick={() => setCameraModal(true)}>
															<i className="fa fa-camera m-0" />{" "}
														</Link>

														<Modal show={cameraModal} onHide={() => setCameraModal(false)} className="modal fade" id="cameraModal">
															<div className="modal-content">
																<div className="modal-header">
																	<h5 className="modal-title">Upload images</h5>
																	<button type="button" className="btn-close" data-dismiss="modal" onClick={() => setCameraModal(false)}>
																	</button>
																</div>
																<div className="modal-body">
																	<div className="input-group mb-3">
																		<span className="input-group-text">Upload</span>
																		<div className="form-file">
																			<input type="file" className="form-file-input form-control" />
																		</div>
																	</div>
																</div>
															</div>
														</Modal>
														<Link to="/app-profile" className="btn btn-primary ms-1" data-toggle="modal" data-target="#postModal" onClick={() => setPostModal(true)}>Post</Link>

														<Modal show={postModal} onHide={() => setPostModal(false)} className="modal fade" id="postModal">
															<div className="modal-content">
																<div className="modal-header">
																	<h5 className="modal-title">Post</h5>
																	<button type="button" className="btn-close" data-dismiss="modal" onClick={() => setPostModal(false)} >
																	</button>
																</div>
																<div className="modal-body">
																	<textarea name="textarea" id="textarea" cols={30} rows={5} className="form-control mb-2 bg-transparent" placeholder="Please type what you want...." defaultValue={""} />
																	<Link className="btn btn-primary btn-rounded mt-1" to="/app-profile">Post</Link>
																</div>
															</div>
														</Modal>
													</div>

													<div className="profile-uoloaded-post border-bottom-1 pb-5">
														<img src={profile08} alt="" className="img-fluid w-100 rounded" />
														<Link className="post-title" to="/post-details">
															<h3 className="text-black">Collection of textile samples lay spread</h3>
														</Link>
														<p>
															A wonderful serenity has take possession of my entire soul like these sweet morning of spare which enjoy whole heart.A wonderful serenity has take
															possession of my entire soul like these sweet morning of spare which enjoy whole heart.
														</p>
														<button className="btn btn-primary me-2">
															<span className="me-2"> <i className="fa fa-heart" /> </span>Like
														</button>
														<button className="btn btn-secondary" onClick={() => setReplayModal(true)}>
															<span className="me-2"> <i className="fa fa-reply" /></span>Reply
														</button>
													</div>
													<div className="profile-uoloaded-post border-bottom-1 pb-5">
														<img src={profile09} alt="" className="img-fluid w-100 rounded" />
														<Link className="post-title" to="/post-details">
															<h3 className="text-black">Collection of textile samples lay spread</h3>
														</Link>
														<p>
															A wonderful serenity has take possession of my
															entire soul like these sweet morning of spare which
															enjoy whole heart.A wonderful serenity has take
															possession of my entire soul like these sweet
															morning of spare which enjoy whole heart.
														</p>
														<button className="btn btn-primary me-2">
															<span className="me-2"> <i className="fa fa-heart" /> </span>Like
														</button>
														<button className="btn btn-secondary" onClick={() => setReplayModal(true)}>
															<span className="me-2">  <i className="fa fa-reply" /></span>Reply
														</button>
													</div>
													<div className="profile-uoloaded-post pb-3">
														<img src={profile08} alt="" className="img-fluid  w-100 rounded" />
														<Link className="post-title" to="/post-details">
															<h3 className="text-black">Collection of textile samples lay spread</h3>
														</Link>
														<p>
															A wonderful serenity has take possession of my
															entire soul like these sweet morning of spare which
															enjoy whole heart.A wonderful serenity has take
															possession of my entire soul like these sweet
															morning of spare which enjoy whole heart.
														</p>
														<button className="btn btn-primary me-2">
															<span className="me-2"><i className="fa fa-heart" /></span>Like
														</button>
														<button className="btn btn-secondary" onClick={() => setReplayModal(true)}>
															<span className="me-2"> <i className="fa fa-reply" /></span>Reply
														</button>
													</div>

													<Modal show={replayModal} onHide={() => setReplayModal(false)} className="modal fade" id="replyModal">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title">Post Reply</h5>
																<button type="button" className="btn-close" data-dismiss="modal" onClick={() => setReplayModal(false)}></button>
															</div>
															<div className="modal-body">
																<form>
																	<textarea className="form-control" rows="4">Message</textarea>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="btn btn-danger light" data-dismiss="modal" onClick={() => setReplayModal(false)}>Close</button>
																<button type="button" className="btn btn-primary">Reply</button>
															</div>
														</div>
													</Modal>
												</div>
											</div>
											<div id="about-me" className={`tab-pane fade ${activeToggle === "aboutMe" ? "active show" : ""}`}>
												{/* <div className="profile-about-me">
								<div className="pt-4 border-bottom-1 pb-3">
									<h4 className="text-primary">About Me</h4>
									<p className="mb-2">
										A wonderful serenity has taken possession of my
										entire soul, like these sweet mornings of spring
										which I enjoy with my whole heart. I am alone, and
										feel the charm of existence was created for the
										bliss of souls like mine.I am so happy, my dear
										friend, so absorbed in the exquisite sense of mere
										tranquil existence, that I neglect my talents.
									</p>
									<p>
										A collection of textile samples lay spread out on
										the table - Samsa was a travelling salesman - and
										above it there hung a picture that he had recently
										cut out of an illustrated magazine and housed in a
										nice, gilded frame.
									</p>
								</div>
							</div>
							<div className="profile-skills mb-5">
								<h4 className="text-primary mb-2">Skills</h4>
								<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1"> Admin</Link>
								<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1" > Dashboard</Link>
								<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Photoshop</Link>
								<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Bootstrap</Link>
								<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Responsive</Link>
								<Link to="/app-profile" className="btn btn-primary light btn-xs mb-1 me-1">Crypto</Link>
							</div>
							<div className="profile-lang  mb-5">
								<h4 className="text-primary mb-2">Language</h4>
								<Link to="/app-profile" className="text-muted pe-3 f-s-16">
									<i className="flag-icon flag-icon-us" />English
								</Link>
								<Link to="/app-profile" className="text-muted pe-3 f-s-16">
									<i className="flag-icon flag-icon-fr" />French
								</Link>
								<Link to="/app-profile" className="text-muted pe-3 f-s-16">
									<i className="flag-icon flag-icon-bd" />Bangla
								</Link>
							</div> */}
												<div className="profile-personal-info" style={{ marginTop: "40px" }}>
													<h4 className="text-primary mb-4">
														Personal Information
													</h4>
													<div className="row mb-2">
														<div className="col-3">
															<h5 className="f-w-500">Company Id<span className="pull-right">:</span></h5>
														</div>
														<div className="col-9">
															<span>{auth?.auth?.company?.companyId}</span>
														</div>
													</div>
													<div className="row mb-2">
														<div className="col-3">
															<h5 className="f-w-500"> Name<span className="pull-right">:</span></h5>
														</div>
														<div className="col-9">
															<span>{`${auth?.auth?.company?.name?.firstName} ${auth?.auth?.company?.name?.lastName}`}</span>
														</div>
													</div>
													<div className="row mb-2">
														<div className="col-3">
															<h5 className="f-w-500">Email<span className="pull-right">:</span></h5>
														</div>
														<div className="col-9">
															<span>{auth?.auth?.company?.email}</span>
														</div>
													</div>
													<div className="row mb-2">
														<div className="col-3">
															<h5 className="f-w-500">  Phone Number<span className="pull-right">:</span></h5>
														</div>
														<div className="col-9">
															<span>{`0${auth?.auth?.company?.number}`}</span>
														</div>
													</div>
													<h4 className="text-primary mb-4">
														Business Information
													</h4>
													<div className="row mb-2">
														<div className="col-3">
															<h5 className="f-w-500">Company Name<span className="pull-right">:</span></h5>
														</div>
														<div className="col-9">
															<span>{auth?.auth?.company?.companyName}</span>
														</div>
													</div>
													<div className="row mb-2">
														<div className="col-3">
															<h5 className="f-w-500">Company Type<span className="pull-right">:</span></h5>
														</div>
														<div className="col-9">
															{console.log(auth?.auth?.company)}
															<span>{auth?.auth?.company?.companyType?.companyType}</span>
														</div>
													</div>
													<div className="row mb-2">
														<div className="col-3">
															<h5 className="f-w-500">  Business Address<span className="pull-right">:</span></h5>
														</div>
														<div className="col-9">
															<span>{auth?.auth?.company?.businessAddress}</span>
														</div>
													</div>

													<div className="row mb-2">
														<div className="col-3">
															<h5 className="f-w-500">  City<span className="pull-right">:</span></h5>
														</div>
														<div className="col-9">
															<span>{auth?.auth?.company?.city}</span>
														</div>
													</div>
													<div className="row mb-2">
														<div className="col-3">
															<h5 className="f-w-500">  State<span className="pull-right">:</span></h5>
														</div>
														<div className="col-9">
															<span>{auth?.auth?.company?.state}</span>
														</div>
													</div>
													<div className="row mb-2">
														<div className="col-3">
															<h5 className="f-w-500">  Zip<span className="pull-right">:</span></h5>
														</div>
														<div className="col-9">
															<span>{auth?.auth?.company?.zip}</span>
														</div>
													</div>
													
													<div className="row mt-4 pt-3">
														<div className="col-3">

															<Button
																variant="primary"
																className="btn btn-primary"
																onClick={() => setProfileUpdateModal(true)}
															>
																Edit
															</Button>
														</div>

														{/* <div className="col-3">
														<Link to={"#"} className="btn btn-danger btn-block" onClick={handleDelete}><i className="far fa-times-circle scale3"></i></Link>
													</div> */}
													</div>
												</div>
											</div>
											<div id="profile-settings" className={`tab-pane fade ${activeToggle === "setting" ? "active show" : ""}`}>
												<div className="pt-3">
													<div className="settings-form">
														<h4 className="text-primary">Account Setting</h4>
														{/* <form onSubmit={(e) => e.preventDefault()}>
														<div className="row">
															<div className="form-group mb-3 col-md-6">
																<label className="form-label" >Email</label>
																<input type="email" placeholder="Email" className="form-control" />
															</div>
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Password</label>
																<input type="password" placeholder="Password" className="form-control" />
															</div>
														</div>
														<div className="form-group mb-3">
															<label className="form-label">Address</label>
															<input type="text" placeholder="1234 Main St" className="form-control" />
														</div>
														<div className="form-group mb-3">
															<label className="form-label">Address 2</label>
															<input type="text" placeholder="Apartment, studio, or floor" className="form-control" />
														</div>
														<div className="row">
															<div className="form-group mb-3 col-md-6">
																<label className="form-label" >City</label>
																<input type="text" className="form-control" />
															</div>
															<div className="form-group mb-3 col-md-4">
																<label className="form-label">State</label>
																<select
																	className="form-control"
																	id="inputState"
																	defaultValue="option-1"
																>
																	<option value="option-1">Choose...</option>
																	<option value="option-2">Option 1</option>
																	<option value="option-3">Option 2</option>
																	<option value="option-4">Option 3</option>
																</select>
															</div>
															<div className="form-group mb-3 col-md-2">
																<label className="form-label">Zip</label>
																<input type="text" className="form-control" />
															</div>
														</div>
														<div className="form-group mb-3">
															<div className="form-check custom-checkbox">
																<input
																	type="checkbox"
																	className="form-check-input"
																	id="gridCheck"
																/>
																<label
																	className="form-check-label"
																	htmlFor="gridCheck"
																>
																	Check me out
																</label>
															</div>
														</div>
														<button className="btn btn-primary" type="submit">Sign in</button>
													</form> */}
														<form onSubmit={handleSubmit(handleUpdatePassword)} enctype="multipart/form-data">

															<div className="form-group mb-3">
																<label className="form-label" >Current Password</label>
																<input type="text"  {...register("oldPassword")} placeholder="Current Password" className="form-control" />
															</div>
															<div className="row">
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">Confirm Password</label>
																	<input type="text"  {...register("confirmNewPassword")} placeholder="Confirm New" className="form-control" />
																</div>
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">New Password</label>
																	<input type="text"  {...register("newPassword")} placeholder="New Password" className="form-control" />
																</div>
															</div>



															<button className="btn btn-primary" style={{ marginTop: "15px" }} type="submit">Update</button>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		</>
	);
};

export default AppProfile;
