import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import BgSpinner from '../BackgroundSpinner/BgSpinner';


const AppProfile = () => {
	const history = useHistory()
	const [activeToggle, setActiveToggle] = useState("setting");
	const auth = useSelector(state => state.auth)
	const [file, setFile] = useState("");

	const [isLoading, setIsLoading] = useState(false)

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();



	const handleUser = (data) => {
		// console.log("data", data);
		setIsLoading(true)
		if (data?.description?.length >= 230) {
			return swal("Category description not exceed from 230 words")
		}

		let formData = new FormData()
		formData.append("categoryName", data.categoryName);
		formData.append("description", data.description);


		if (file) {
			data.image = file
			formData.append("image", data.image);
		}

		// for (var pair of formData.entries()) {
		// 	console.log(pair[0] + ', ' + pair[1]);
		// }

		axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/category/register`, formData, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
				'content-type': 'multipart/form-data'
			}
		}).then((res) => {
			showToastMessage(`${res?.data?.message}`)
			setIsLoading(false)
			history.push("/category-list")
		}).catch((err) => {
			setIsLoading(false)

			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})

	};
	const showToastMessage = (msg) => {
		toast.success(`${msg}`, {
			position: toast.POSITION.BOTTOM_LEFT,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			closeButton: <i className="fa fa-times" aria-hidden="true"></i>,
			style: {
				background: "white",
				borderRadius: "5px",
				borderLeft: "10px solid green",
				color: "black",
				boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
			},
		});
	};
	return (
		<Fragment>
			<ToastContainer
				autoClose={3000}
			/>
			{/* <PageTitle activeMenu="Profile" motherMenu="App" /> */}
			<h4 className="breadcrumb-item active text-primary">Add New User</h4>
			<div className="row">
				<div className="col-xl-8">
					<div className="card">
						<div className="card-body">
							<div className="profile-tab">
								<div className="custom-tab-1">
									<div className="tab-content">
										<div id="profile-settings" className={`tab-pane fade ${activeToggle === "setting" ? "active show" : ""}`}>
											<div className="pt-3">
												<div className="settings-form">

													<form onSubmit={handleSubmit(handleUser)} enctype="multipart/form-data">

														<div className="row">
															<div className="row">
																<div className="form-group mb-3 col-md-6">
																	{console.log(file)}
																	{/* <img src={`${process.env.REACT_APP_BASE_URL}/${category?.image}`} alt="" className="rounded profile-img me-4 mb-3" /> */}
																	<img
																		src={file
																			? URL.createObjectURL(file)
																			: "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"}
																		alt=""
																		className="rounded profile-img me-4 mb-3"
																		style={{ width: "120px" }}
																	/>
																</div>
																<div className="form-group mb-3 col-md-6">
																	<label className="form-label">Change Category Picture</label>
																	<input type="file" fileName="image"  {...register("image")} onChange={(e) => { setFile(e.target.files[0]) }} className="form-control" />
																	{/* {errors.image?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
																</div>

															</div>

															<div className="form-group mb-3 col-md-6">
																<label className="form-label" >Category Name</label>
																<input type="first name"  {...register("categoryName", { required: true })} className="form-control" />
																{errors.categoryName?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Category Description</label>
																<input type="last name"  {...register("description", { required: true })} className="form-control" />
																{errors.description?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
														</div>






														<div className="row">

															{/* <div className="form-group mb-3 col-md-6">
																<label className="form-label">Email For Password Recovery</label>
																<input type="email"  {...register("emailForPasswordRecovery")} placeholder="Email For Password Recovery" className="form-control" />
																{errors.image?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div> */}
														</div>
														<button className="btn btn-primary" type="submit">Add</button>
														<button className="btn btn-primary-outline" style={{ marginLeft: "10px" }} onClick={() => { history.push("/category-list") }}>Cancel</button>
														{isLoading && <BgSpinner />}

													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AppProfile;
