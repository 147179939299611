import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import { Row, Col, Card, Accordion } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";

const FaqsList = () => {
  const [faqs, setFaqs] = useState([]);
  const history=useHistory()
  const [activeBordered, setActiveBordered] = useState(0);
  const [activeWithoutSpace, setActiveWithoutSpace] = useState(0);
  const [activeWithoutSpaceBordered, setActiveWithoutSpaceBordered] = useState(
    0
  );
  const [
    activeIndicatorInLeftPosition,
    setActiveIndicatorInLeftPosition,
  ] = useState(0);
  const [activeAccordionWithIcon, setActiveAccordionWithIcon] = useState(0);
  const [activeAccordionHeaderBg, setActiveAccordionHeaderBg] = useState(0);
  const [activeAccordionSolidBg, setActiveAccordionSolidBg] = useState(0);
  const [isLoading,setIsLoading]=useState(true)
  const [
    activeAccordionActiveHeader,
    setActiveAccordionActiveHeader,
  ] = useState(0);
  const [
    activeAccordionHeaderShadow,
    setActiveAccordionHeaderShadow,
  ] = useState(0);
  const [
    activeaccordionRoundedStylish,
    setActiveaccordionRoundedStylish,
  ] = useState(0);
  const [activeAccordionGradient, setActiveAccordionGradient] = useState(0);

  const defaultAccordion = [
    {
      title: "Accordion Header One",
      text:
        "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.",
      bg: "primary",
    },
    {
      title: "Accordion Header Two",
      text:
        "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.",

      bg: "info",
    },
    {
      title: "Accordion Header Three",
      text:
        "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.",

      bg: "success",
    },
  ];
  const getAllFaqs = async () => {
		
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/faqs/all`).then((res) => {
    
    setFaqs(res?.data?.faqs)
    }).catch((err) => {
        
        swal(`${err?.response?.data?.message}`);
      
        if(err?.response?.status===401){
    history.push("/login")
    window.location.reload();
  }
    })
}
useEffect(()=>{getAllFaqs()},[])
  return (
    <Fragment>
      {/* <PageTitle
        activeMenu="Accordion"
        motherMenu="Bootstrap"
        pageContent="Accordion"
      /> */}
      <Row>
        
        <Col xl="12">
          <Card className="transparent-card">
            <Card.Header className="d-block">
              {/* <Card.Title>Accordion header shadow</Card.Title>
              <Card.Text className="m-0 subtitle">
                Add <code>accordion-header-shadow</code> and{" "}
                <code>accordion-rounded</code> class with <code>accordion</code>
              </Card.Text> */}
            </Card.Header>
            <Card.Body>
              <Accordion
                className="accordion accordion-header-shadow accordion-rounded"
                defaultActiveKey="0"
              >
                {faqs.map((d, i) => (
                  <div className="accordion-item" key={i}>
                    <Accordion.Toggle
                      as={Card.Text}
                      eventKey={`${i}`}
                      className={`accordion-header ${
                        activeAccordionHeaderShadow === i ? "" : "collapsed"
                      } accordion-header--primary`}
                      onClick={() =>
                        setActiveAccordionHeaderShadow(
                          activeAccordionHeaderShadow === i ? -1 : i
                        )
                      }
                    >
                      <span className="accordion-header-icon"></span>
                      <span className="accordion-header-text" style={{fontSize:"1.1rem",fontWeight:"600",fontFamily:"sans-serif",wordSpacing:"4px"}}>{d.question}</span>
                      <span className="accordion-header-indicator "></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      eventKey={`${i}`}
                      className="accordion__body"
                    >
                      <div className="accordion-body-text" style={{fontSize:"1rem",fontFamily:"sans-serif",wordSpacing:"3px"}}>{d.answer}</div>
                    </Accordion.Collapse>
                  </div>
                ))}
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
       
      </Row>{" "}
    </Fragment>
  );
};

export default FaqsList;
