import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import BgSpinner from '../BackgroundSpinner/BgSpinner';


const AppProfile = () => {
	const history = useHistory()
	const [activeToggle, setActiveToggle] = useState("setting");
	const auth = useSelector(state => state.auth)
	const [file, setFile] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [isLoading,setIsLoading]=useState(false)

	const togglePassword = () => {
		setShowPassword((prevState) => !prevState);
	  };

	const {
		register,
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = useForm();



	const handleUser = (data) => {

		setIsLoading(true)

		let formData = new FormData()
		formData.append("firstName", data.firstName);
		formData.append("lastName", data.lastName);
		formData.append("email", data.email);
		formData.append("city", data.city);
		formData.append("street", data.street);
		formData.append("zip", data.zip);
		formData.append("state", data.state);
		formData.append("number", data.number);
		formData.append("password", data.password);

		if (file) {
			data.image = file
			formData.append("image", data.image);
		}



		// for (var pair of formData.entries()) {
		// 	console.log(pair[0] + ', ' + pair[1]);
		// }

		axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/user/register`, formData, {
			headers: {
				Authorization: `Bearer ${auth.auth.token}`,
				'content-type': 'multipart/form-data'
			}
		}).then((res) => {
			
			showToastMessage(`${res?.data?.message}`)
			setIsLoading(false)
			history.push("/user-list")
		}).catch((err) => {
			
			setIsLoading(false)
			swal(`${err?.response?.data?.message}`)
			if (err?.response?.status === 401) {
				history.push("/login")
				window.location.reload();
			}
		})

	};
	const showToastMessage = (msg) => {
		toast.success(`${msg}`, {
			position: toast.POSITION.BOTTOM_LEFT,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			closeButton: <i className="fa fa-times" aria-hidden="true"></i>,
			style: {
				background: "white",
				borderRadius: "5px",
				borderLeft: "10px solid green",
				color: "black",
				boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
			},
		});
	};
	return (
		<Fragment>

			{/* <PageTitle activeMenu="Profile" motherMenu="App" /> */}
			<ToastContainer
				autoClose={3000}
			/>
			<h4 className="breadcrumb-item active text-primary">Add New User</h4>
			<div className="row">
				<div className="col-xl-8">
					<div className="card">
						<div className="card-body">
							<div className="profile-tab">
								<div className="custom-tab-1">
									<div className="tab-content">
										<div id="profile-settings" className={`tab-pane fade ${activeToggle === "setting" ? "active show" : ""}`}>
											<div className="pt-3">
												<div className="settings-form">

													<form onSubmit={handleSubmit(handleUser)} enctype="multipart/form-data">

														<div className="row">
															<div className="form-group mb-3 col-md-6">
																<label className="form-label" >First Name</label>
																<input type="first name"  {...register("firstName", { required: true })} placeholder="John" className="form-control" />
																{errors.firstName?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Last Name</label>
																<input type="last name"  {...register("lastName", { required: true })} placeholder="Smith" className="form-control" />
																{errors.lastName?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
														</div>

														<div className="row">
															<div className="form-group mb-3 col-md-2">
																<label className="form-label" >Street</label>
																<input type="text"  {...register("street", { required: true })} className="form-control" placeholder="123 Main St" />
																{errors.street?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
															<div className="form-group mb-3 col-md-5">
																<label className="form-label">City</label>
																<input type="text"  {...register("city", { required: true })} placeholder="New York" className="form-control" />
																{errors.city?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
															<div className="form-group mb-3 col-md-3">
																<label className="form-label">State</label>
																<input type="text"  {...register("state", { required: true })} className="form-control" placeholder="State" />
																{errors.state?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
															<div className="form-group mb-3 col-md-2">
																<label className="form-label">Zip</label>
																<input type="text"  {...register("zip", { required: true })} className="form-control" placeholder="12345" />
																{errors.zip?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>

														</div>
														<div className="row">
															<div className="form-group mb-3 col-md-6">
																<label className="form-label" >Email</label>
																<input type="email"  {...register("email", { required: true })} className="form-control" placeholder="johnsmith@example.com" />
																{errors.email?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Phone Number</label>
																<input type="text"  {...register("number", { required: true })} placeholder="(555) 555-5555" className="form-control" />
																{errors.number?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div>
														</div>


														<div className="row">
															{/* <div className="form-group mb-3 col-md-6">
																<label className="form-label" >Password</label>
																<input type="text"  {...register("password", { required: true })} placeholder="Must have atleast 8 characters" className="form-control" />
																{errors.password?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div> */}



															<div className="form-group mb-3 col-md-6">
																<label className="mb-1">
																	<strong>Password</strong>
																</label>
																<div style={{ position: "relative" }}>
																	<input
																		type={showPassword ? "text" : "password"}
																		className="form-control"
																		{...register("password", { required: true })}
																		placeholder="Must have atleast 8 characters"
																	/>



																	<span style={{ position: "absolute", top: "10px", right: "20px" }} onClick={togglePassword}>{showPassword ? <i class="bi bi-eye" style={{ fontSize: "24px" }}></i> : <i class="bi bi-eye-slash" style={{ fontSize: "24px" }}></i>}</span>

																</div>
																{errors.password?.type === "required" && (
																	<div style={{ color: "red" }}>This field is required</div>
																)}
															</div>


															{/* <div className="form-group mb-3 col-md-6">
																<label className="form-label">Upload Profile Picture</label>
																<input type="file" fileName="image"      {...register("image", {
																	required: watch("city") && watch("street") && watch("zip") && watch("country"),
																})} onChange={(e) => { setFile(e.target.files[0]) }} className="form-control" />
																{errors.image?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div> */}
															<div className="form-group mb-3 col-md-6">
																<label className="form-label">Upload Profile Picture</label>
																<input type="file" fileName="image" {...register("image")} onChange={(e) => { setFile(e.target.files[0]) }} className="form-control" />

																{/* <input type="file" fileName="image" {...register("image", {
																	required: !watch("city") || !watch("street") || !watch("zip") || !watch("country")
																})} onChange={(e) => { setFile(e.target.files[0]) }} className="form-control" />
																{(!watch("city") || !watch("street") || !watch("zip") || !watch("country")) && errors.image?.type === "required" && <div style={{ color: "red" }}>This field is required</div>} */}
															</div>


														</div>

														<div className="row">

															{/* <div className="form-group mb-3 col-md-6">
																<label className="form-label">Email For Password Recovery</label>
																<input type="email"  {...register("emailForPasswordRecovery")} placeholder="Email For Password Recovery" className="form-control" />
																{errors.image?.type === "required" && <div style={{ color: "red" }}>This field is required</div>}
															</div> */}
														</div>
														<button className="btn btn-primary" type="submit">Add</button>
														<button className="btn btn-primary-outline" style={{ marginLeft: "10px" }} onClick={() => { history.push("/user-list") }}>Cancel</button>
														{isLoading&&<BgSpinner/>}
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AppProfile;








