import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logout,
    logoutWithoutPush
} from '../store/actions/AuthActions';

export function signUp(formData) {
    //axios call
    return axios.post(
        `http://localhost:5000/api/v1/company/register`,
        formData,
        {
            'content-type': 'multipart/form-data'
        }
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
    };
    return axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/company/login`,
        postData,
    );
}

export function getUserDetail(id, token) {

    return axios.post(
        `http://localhost:5000/api/v1/company/me/verify`, { id, token }, { headers: { Authorization: `Bearer ${token}` } }
    );
}
export function formatError(errorResponse) {

    swal(`${errorResponse?.message}`);
    // switch (errorResponse.message) {
    //     case 'EMAIL_EXISTS':
    //         //return 'Email already exists';
    //         swal("Oops", "Email already exists", "error");
    //         break;
    //     case 'EMAIL_NOT_FOUND':
    //         //return 'Email not found';
    //        swal("Oops", "Email not found", "error",{ button: "Try Again!",});
    //        break;
    //     case 'INVALID_PASSWORD':
    //         //return 'Invalid Password';
    //         swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
    //         break;
    //     case 'USER_DISABLED':
    //         return 'User Disabled';

    //     default:
    //         return '';
    // }
}

export function saveTokenInLocalStorage(tokenDetails) {
    // tokenDetails.expireDate = new Date(
    //     new Date().getTime() + tokenDetails.expiresIn * 1000,
    // );
    localStorage.setItem('companyDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, history) {

    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {

    const tokenDetailsString = localStorage.getItem('companyDetails');
    console.log(tokenDetailsString, "token");
    const currentPage = window.location.pathname;

    console.log(currentPage, "curretm page");
    let tokenDetails = '';
    if (!tokenDetailsString) {
        if (currentPage === '/admin/page-register' || currentPage === '/admin/forgot-password' || currentPage.includes('/admin/verify-otp/') || currentPage.includes('/admin/reset-password/')) {
            console.log("hell");
            dispatch(logoutWithoutPush(history))
            return
        } else {
            dispatch(logout(history));
            return;
        }


    }

    tokenDetails = JSON.parse(tokenDetailsString);

    let expireDate = tokenDetails?.expiresIn;
    let todaysDate = Math.floor((new Date().getTime()) / 1000);
    if (Math.floor((new Date().getTime()) / 1000) > tokenDetails?.expiresIn) {
        dispatch(logout(history));
        return;
    }
    // console.log("token detail",tokenDetails);
    if (Math.floor((new Date().getTime()) / 1000) < tokenDetails?.expiresIn) {
        getUserDetail(tokenDetails?.Id, tokenDetails?.token).then((res) => {

            dispatch(loginConfirmedAction(res?.data));
            // const timer = expireDate - todaysDate;
            // runLogoutTimer(dispatch, timer, history);
        }).catch((err) => {

            dispatch(logout(history));
            return;
        })
    }
    // dispatch(loginConfirmedAction(tokenDetails));

    // const timer = expireDate.getTime() - todaysDate.getTime();
    //  runLogoutTimer(dispatch, timer, history);







    // const tokenDetailsString = localStorage.getItem('companyDetails');
    // console.log("tokenDetailsString", tokenDetailsString);
    // let tokenDetails = '';
    // if (!tokenDetailsString) {
    //     dispatch(logout(history));
    //     return;
    // }
    // tokenDetails = JSON.parse(tokenDetailsString);

    // if (Math.floor((new Date()).getTime() / 1000) >= tokenDetails.expiresIn) {

    //     dispatch(logout(history));

    //     return;
    // }
    // if (tokenDetailsString) {
    //     let expireDate = tokenDetails.expiresIn;
    //     let todaysDate = new Date();
    //     console.log("tokenndetain", tokenDetails);
    //     getUserDetail(tokenDetails.Id, tokenDetails.token).then((res) => {
    //         console.log(res?.data, "get single")
    //         dispatch(loginConfirmedAction(res?.data));
    //         const timer = expireDate - (todaysDate.getTime() / 1000);
    //         runLogoutTimer(dispatch, timer, history);
    //     }).catch((err) => {
    //         console.log(err?.response);
    //         dispatch(logout(history));
    //         return;
    //     })
    // }
}
