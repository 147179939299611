import {
    Add_User_Id,
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
    UPDATE_LOGIN_DATA
} from '../actions/AuthActions';

const initialState = {
    auth: {
        token: '',
        expiresIn: '',
        company: '',
        message: '',
        success: ''
    },
    userID:'',
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function AuthReducer(state = initialState, action) {
    if (action.type === SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Signup Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === Add_User_Id) {

        return {
            ...state,
            userID: action.payload,
           
        };
    }
    if (action.type === LOGIN_CONFIRMED_ACTION) {
      
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Login Successfully Completed',
            showLoading: false,
            
        };
        
    }
    if (action.type === UPDATE_LOGIN_DATA) {
      
        
        return {
            ...state,
            auth: {
              ...state.auth,
              company: action.payload
            }
          };
        
    }

    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
                token: '',
                expiresIn: '',
                company: '',
                message: '',
                success: ''
            },
        };
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


